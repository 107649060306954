import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../../contexts/Account';
import { slideUpdateCompany } from '../../../services/company_services';
import LoadingLogoSkeleton from '../../../components/SkeletonLoading/loadingLogoSkeleton';

const UpdateGoalModal = ({ open, onClose }) => {
  const { accountDetails, asLoggedUserDetails, editAccountInformation } =
    useAccount();
  const [inputValue, setInputValue] = useState(
    accountDetails.currentGoal || ''
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 0 && value.length < 30) {
      setError('The input must be at least 30 characters long.');
    } else {
      setError('');
    }
  };

  async function openCurrentChallengeEdit() {
    setIsLoading(true);
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        DISPLAY_NAME: accountDetails.displayName || '',
        COMPANY_NAME: accountDetails.displayName || '',
        COMPANY_STORY: accountDetails.storyAndMission || '',
        COMPANY_INDUSTRY: accountDetails.industryTagIds,
        COMPANY_DESIRED_INDUSTRY: accountDetails.desiredIndustriesTagIds,
        COMPANY_SKILLS: accountDetails.desiredSkillsTagIds,
        COMPANY_BENEFITS: accountDetails.benefits.map((e) => JSON.stringify(e)),
        COMPANY_WEBSITE: accountDetails.website || '',
        COMPANY_SIZE: accountDetails.companySize || '',
        CURRENT_GOAL: inputValue || '',
        BRAND_FREEMIUM: !!accountDetails.brandFreemium,
      };
      await slideUpdateCompany(data);
      let accountDetailsModifiedValues = {
        displayName: accountDetails.displayName,
        storyAndMission: accountDetails.storyAndMission,
        industryTagIds: accountDetails.industryTagIds,
        desiredSkillsTagIds: accountDetails.desiredSkillsTagIds,
        desiredIndustriesTagIds: accountDetails.desiredIndustriesTagIds,
        benefits: accountDetails.benefits,
        companySize: accountDetails.companySize,
        website: accountDetails.website,
        brandFreemium: accountDetails.brandFreemium,
        currentGoal: inputValue,
      };

      editAccountInformation(accountDetailsModifiedValues);
    } catch (error) {
      console.error('Error updating goal:', error);
    } finally {
      setIsLoading(false);
      setInputValue('');
      onClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={{ xxs: true, xs: false, sm: false }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: { xxs: 0, xs: '10px', sm: '10px' },
          padding: '0px',
          width: '100%',
          height: { xxs: '100%', xs: 'auto', sm: 'auto' },
          maxWidth: { xxs: '100%', xs: '550px', sm: '550px' },
          margin: { xxs: 0, xs: 'auto', sm: 'auto' },
          // alignItems: { xxs: 'center', xs: 'flex-start' },
          // justifyContent: { xxs: 'center', xs: 'normal' },
        },
      }}
    >
      <Box sx={{ position: 'relative', padding: '20px' }}>
        <DialogTitle>
          <Typography
            sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '26px' }}
          >
            Update your current challenge or goal
          </Typography>

          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8, color: '#aaa' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="i.e. Help me get fundraising for my company. Help my team reach our Q4 goals. Give me feedback on my personal side-project or career."
            multiline
            rows={7}
            fullWidth
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            sx={{
              mt: 0,
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                fontSize: '14px',
                color: '#333',
                height: '176px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd',
              },
            }}
          />
          <Box
            sx={{
              height: '20px',
              mt: 1,
            }}
          >
            {error && (
              <Typography
                sx={{
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {error}
              </Typography>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={openCurrentChallengeEdit}
            fullWidth
            disabled={isLoading || inputValue.length < 30}
            sx={{
              textTransform: 'none',
              bgcolor: isLoading ? '#cccccc' : '#2B71F6',
              borderRadius: '8px',
              boxShadow: 'none',
              width: '100%',
              height: 42,
              fontSize: 14,
              fontWeight: '600',
              mt: 1,
              '&:hover': {
                bgcolor: isLoading ? '#cccccc' : '#2B71F6',
              },
            }}
          >
            {isLoading ? <LoadingLogoSkeleton /> : 'Save'}
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default UpdateGoalModal;
