import GeneralTile from "../../advisorComponents/GeneralTile";
import Invitations from "./Invitations";
import MyBoards from "./MyBoards";
import calendar_check from "../../advisorComponents/assets/calendar_check.svg";
import books from "../../advisorComponents/assets/books.svg";
import schedule from "../../advisorComponents/assets/schedule.svg";
import { Box, useMediaQuery } from "@mui/material";
import eventBus, { eventBusValues } from "../../eventBus";
import { useAccount } from "../../contexts/Account";
import React, { useEffect, useRef, useState } from "react";
import LeadershipFrame from "../../advisorComponents/LeadershipFrame";


function BoardsAndInvitations() {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const [showDetails, setShowDetails] = useState(false);
  const [showLeadershipFrame, setShowLeadershipFrame] = useState(false);

  const detailsRef = useRef(null);

  useEffect(() => {
    if (showDetails) {
      detailsRef.current.scrollIntoView({ behavior: 'smooth', block: "nearest" });
    }
  }, [showDetails]);

  function openUserDrawer() {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: false,
    });
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: isXS ? 'column' : 'row',
        justifyContent: 'space-between',
        columnGap: '20px',
        rowGap: '20px',
        marginBottom: '20px',
      }}>

        <GeneralTile
          ids={
            { tileID: "my-professional-brand-tile-id", buttonID: "my-professional-brand-button-id" }
          }
          title="My Professional Brand"
          onClick={openUserDrawer}
          description="View or update your profile so you can be contacted for the types of advisory boards and professional opportunities you're interested in."
          image={calendar_check}
        />
        <GeneralTile
          ids={
            { tileID: "my-advisory-boards-tile-id", buttonID: "my-advisory-boards-button-id" }
          }
          title="My Advisory Boards"
          onClick={() => setShowDetails(!showDetails)}
          description="View your current advisory boards, share your insights, attend board meetings, and/or review joining new advisory boards."
          image={books}
        />

        {(showDetails && isXS) ?
          <Box ref={detailsRef}>
            <Invitations />
            <MyBoards />
          </Box> : null}

        <GeneralTile
          ids={
            { tileID: "my-thought-leadership-tile-id", buttonID: "my-thought-leadership-button-id" }
          }
          title="My Thought Leadership"
          onClick={() => setShowLeadershipFrame(true)}
          description="Grow your audience and professional brand by sharing thought leadership with recommendations from your professional brand manager at AdvisoryCloud."
          image={schedule}

        />
      </Box>

      {(showDetails && !isXS) ?
        <Box ref={detailsRef}>
          <Invitations />
          <MyBoards />
        </Box> : null}

      <LeadershipFrame showLeadershipFrame={showLeadershipFrame} setShowLeadershipFrame={setShowLeadershipFrame} />
    </>
  );
}



export default BoardsAndInvitations;