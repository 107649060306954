import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Chip,
  Fab,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CustomAutocomplete from '../../CustomAutocompleteMobile';
import { sendFSAdvisorsSearched } from '../../../services/FullStory';

const useStyles = ({ isSM, hidePosition }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: hidePosition ? 'none' : 'auto',
    maxWidth: '100%',
    backgroundColor: 'white',
    marginTop: isSM ? '10px' : '20px',
  },
  keywordsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '54px',
    // maxWidth: '1200px',
  },
  keywordsInput: {
    display: 'flex',
    flex: 'auto',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E6EDFF',
      },
      '&:hover fieldset': {
        borderColor: '#E6EDFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0F78FD',
      },
      '& input::placeholder': {
        color: '#74879A',
        opacity: 1,
      },
    },
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    marginTop: isSM ? '0px' : '20px',
    // maxWidth: '1200px',
  },
  autocomplete: {
    marginRight: '10px',
    width: isSM ? '100%' : '33%',
    height: '44px',
    borderRadius: '10px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E6EDFF',
      },
      '&:hover fieldset': {
        borderColor: '#E6EDFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0F78FD',
      },
      '& input::placeholder': {
        color: '#74879A',
        opacity: 1,
      },
    },
  },
});

function AdvisorFilter(props) {
  const { accountDetails, asLoggedUserDetails, tags } = useAccount();
  const {
    hidePosition,
    onSearchButtonClicked,
    hidePeerFilter,
    searchP2PBoards,
    setSearchP2PBoards,
    showInvitedFilter,
    showSavedFilter,
    scrollToInput,
  } = props;
  const location = useLocation();
  const theme = useTheme();
  const isSM = useMediaQuery((theme) => {
    return location.pathname.includes('/board-directory')
      ? theme.breakpoints.down([1272])
      : theme.breakpoints.down('sm');
  });
  const isMobile = useMediaQuery(theme.breakpoints.down(900));
  const classes = useStyles({ isSM, hidePosition });
  const [keywords, setKeywords] = useState('');
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isTagSelectedSkills, setIsTagSelectedSkills] = useState(false);
  const [isTagSelectedIndustry, setIsTagSelectedIndustry] = useState(false);
  const [isTagSelectedInvitation, setIsTagSelectedInvitation] = useState(false);
  const [invitedFilter, setInvitedFilter] = useState(false);
  const [savedFilter, setSavedFilter] = useState({
    label: 'All Saved Advisors',
    value: 'allSaved',
  });
  const [showMore, setShowMore] = useState(false);

  const timeoutRef = useRef();
  const fsTimeoutRef = useRef();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (fsTimeoutRef.current) {
      clearTimeout(fsTimeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onSearchButtonClicked({
        keywords,
        skills,
        industries,
        position: '',
        invitedFilter: invitedFilter ? invitedFilter.value : '',
        savedFilter: savedFilter ? savedFilter.value : '',
      });
    }, 1000);

    fsTimeoutRef.current = setTimeout(() => {
      sendFSAdvisorsSearched({
        accountDetails,
        asLoggedUserDetails,
        keywords: keywords,
        skills: skills.map((skill) => skill.label).join(','),
        industries: industries.map((industry) => industry.label).join(','),
        invitations: invitedFilter ? invitedFilter.label : '',
      });
    }, 10000);
  }, [keywords, skills, industries, invitedFilter, savedFilter]);

  const handleRemoveAllSkills = () => {
    setSkills([]);
    setIsTagSelectedSkills(false);
  };

  const handleRemoveAllIndustries = () => {
    setIndustries([]);
    setIsTagSelectedIndustry(false);
  };

  const handleRemoveSkill = (skillToRemove) => {
    const newSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(newSkills);
    setIsTagSelectedSkills(newSkills.length > 0);
  };

  const handleRemoveIndustry = (industryToRemove) => {
    const newIndustries = industries.filter(
      (industry) => industry !== industryToRemove
    );
    setIndustries(newIndustries);
    setIsTagSelectedIndustry(newIndustries.length > 0);
  };

  function peerFilterClicked(value) {
    onSearchButtonClicked({
      keywords,
      skills,
      position: '',
      industries,
      setReverseP2P: true,
      invitedFilter: invitedFilter ? invitedFilter.value : '',
    });
    setSearchP2PBoards(value);
  }

  function peerFilterComponent(small) {
    if (hidePeerFilter) return null;
    return (
      <div
        style={{
          marginLeft: small ? '5%' : 'auto',
          marginTop: small ? '-5px' : '0',
          whiteSpace: 'nowrap',
        }}
      >
        <Fab
          variant="extended"
          size="small"
          color="secondary"
          role="none"
          style={{
            height: '18px',
            marginRight: '4px',
            color: '#1976d2',
            fontSize: '9px',
            fontWeight: 'bold',
            paddingTop: '2px',
            boxShadow: 'none',
            backgroundColor: '#E7EDFD',
          }}
        >
          New
        </Fab>
        <Typography style={{ display: 'inline' }}>
          Show Peer Advisory Boards
        </Typography>
        <Switch
          style={{ display: 'inline' }}
          color="primary"
          checked={searchP2PBoards}
          onChange={(e, value) => peerFilterClicked(value)}
        />
      </div>
    );
  }

  return (
    <form style={classes.container}>
      <div
        className="searchBox"
        style={classes.keywordsContainer}
        id="b2bKeywordSearch"
      >
        <TextField
          sx={classes.keywordsInput}
          id="search-advisor-keyword-input"
          data-testid="search-advisor-keyword-input"
          data-cy="search-advisor-keyword-input"
          placeholder="Search by Keywords"
          value={keywords}
          onChange={(evt) => setKeywords(evt.target.value)}
          InputProps={{
            style: {
              height: isSM ? '44px' : '54px',
              borderRadius: '15px',
            },
            startAdornment: (
              <InputAdornment
                style={{ marginRight: 10, fontSize: '20px' }}
                position="start"
              >
                <SearchOutlined htmlColor="#74879A" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div style={classes.filtersContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // maxWidth: '1250px',
          }}
        >
          {isSM ? (
            <Box
              sx={{
                marginBottom: '-20px',
                marginTop: { xxs: '-10px', sm: '0px' },
              }}
            >
              <CustomAutocomplete
                items={tags.skills}
                selectedItemsProp={skills}
                placeholder="Skills"
                onItemsChange={setSkills}
                onSearch={onSearchButtonClicked}
                scrollToInput={scrollToInput}
              />
              <CustomAutocomplete
                items={tags.industries}
                selectedItemsProp={industries}
                placeholder="Industry"
                onItemsChange={setIndustries}
                onSearch={onSearchButtonClicked}
                scrollToInput={scrollToInput}
              />
              {showInvitedFilter ? (
                <Autocomplete
                  sx={{
                    ...classes.autocomplete,
                    marginTop: { xxs: '10px', sm: '0px' },
                  }}
                  id="b2bInvitedFilter"
                  data-testid="search-advisor-invited-autocomplete"
                  data-cy="search-advisor-invited-autocomplete"
                  onChange={(e, value) => {
                    setInvitedFilter(value);
                    setIsTagSelectedInvitation(value ? true : false);
                  }}
                  options={[
                    { label: 'Invited', value: 'invitedAdvisors' },
                    { label: 'Accepted', value: 'acceptedAdvisors' },
                  ]}
                  freeSolo
                  getOptionLabel={(option) => option.label || ''}
                  value={invitedFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '10px',
                          height: '44px',
                        },
                      }}
                      size="small"
                      placeholder={
                        isTagSelectedInvitation ? '' : 'Filter by Invitation'
                      }
                      variant="outlined"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      <Chip
                        variant="filled"
                        label={option.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: '0',
                          padding: '0',
                        }}
                      />;
                    })
                  }
                />
              ) : null}
              {showSavedFilter ? (
                <Autocomplete
                  sx={{
                    ...classes.autocomplete,
                    marginTop: { xxs: '10px', sm: '0px' },
                  }}
                  id="b2bSavedFilter"
                  data-testid="search-advisor-saved-autocomplete"
                  data-cy="search-advisor-saved-autocomplete"
                  onChange={(e, value) => {
                    setSavedFilter((prevValue) => ({
                      ...prevValue,
                      ...value,
                    }));
                  }}
                  options={[
                    { label: 'All Saved Advisors', value: 'allSaved' },
                    { label: 'My Saved Advisors', value: 'mySaved' },
                  ]}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  freeSolo
                  getOptionLabel={(option) => option.label || ''}
                  value={savedFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '10px',
                          height: '44px',
                        },
                      }}
                      size="small"
                      placeholder="Filter by Invitation"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      <Chip
                        variant="filled"
                        label={option.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: '0',
                          padding: '0',
                        }}
                      />;
                    })
                  }
                />
              ) : null}
              {peerFilterComponent()}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xxs: 'column', sm: 'row' },
                }}
              >
                <Autocomplete
                  sx={{
                    ...classes.autocomplete,
                    marginBottom: '10px',
                  }}
                  id="b2bSkillsFilter"
                  data-testid="search-advisor-skills-autocomplete"
                  data-cy="search-advisor-skills-autocomplete"
                  onChange={(e, value) => {
                    const filteredValue = value.filter((v) => v.label);
                    setSkills(filteredValue);
                    setIsTagSelectedSkills(filteredValue.length > 0);
                  }}
                  options={tags.skills || []}
                  disableCloseOnSelect
                  //freeSolo
                  getOptionLabel={(options) => options.label}
                  multiple
                  value={skills}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '10px',
                          height: '44px',
                        },
                      }}
                      size="small"
                      placeholder={isTagSelectedSkills ? '' : 'Filter Skills'}
                      variant="outlined"
                    />
                  )}
                  renderTags={() =>
                    isTagSelectedSkills ? (
                      <Chip
                        label={`(${skills.length}) skills selected `}
                        onDelete={handleRemoveAllSkills}
                        sx={{
                          margin: '0',
                          padding: '0',
                          backgroundColor: '#F5F7FA',
                        }}
                      />
                    ) : null
                  }
                  componentsProps={{
                    clearIndicator: {
                      style: {
                        display: 'none',
                      },
                    },
                  }}
                />

                <Autocomplete
                  sx={classes.autocomplete}
                  id="b2bIndustryFilter"
                  data-testid="search-advisor-industry-autocomplete"
                  data-cy="search-advisor-industry-autocomplete"
                  onChange={(e, value) => {
                    const filteredValue = value.filter((v) => v.label);
                    setIndustries(filteredValue);
                    setIsTagSelectedIndustry(filteredValue.length > 0);
                  }}
                  getOptionLabel={(options) => options.label}
                  disableCloseOnSelect
                  // freeSolo
                  options={tags.industries || []}
                  multiple
                  value={industries}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '10px',
                          height: '44px',
                        },
                      }}
                      size="small"
                      placeholder={
                        isTagSelectedIndustry ? '' : 'Filter Industry'
                      }
                      variant="outlined"
                    />
                  )}
                  renderTags={() =>
                    isTagSelectedIndustry ? (
                      <Chip
                        label={`(${industries.length}) industries selected `}
                        onDelete={handleRemoveAllIndustries}
                        sx={{
                          margin: '0',
                          padding: '0',
                          backgroundColor: '#D7F1FD',
                        }}
                      />
                    ) : null
                  }
                  componentsProps={{
                    clearIndicator: {
                      style: {
                        display: 'none',
                      },
                    },
                  }}
                />
                {showInvitedFilter ? (
                  <Autocomplete
                    sx={{
                      ...classes.autocomplete,
                      marginRight: '0px',
                      marginTop: { xxs: '10px', sm: '0px' },
                    }}
                    id="b2bInvitedFilter"
                    data-testid="search-advisor-invited-autocomplete"
                    data-cy="search-advisor-invited-autocomplete"
                    onChange={(e, value) => {
                      setInvitedFilter(value);
                      setIsTagSelectedInvitation(value ? true : false);
                    }}
                    options={[
                      { label: 'Invited', value: 'invitedAdvisors' },
                      { label: 'Accepted', value: 'acceptedAdvisors' },
                    ]}
                    //freeSolo
                    getOptionLabel={(option) => option.label || ''}
                    value={invitedFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: '10px',
                            height: '44px',
                          },
                        }}
                        size="small"
                        placeholder={
                          isTagSelectedInvitation ? '' : 'Filter by Invitation'
                        }
                        variant="outlined"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        <Chip
                          variant="filled"
                          label={option.label}
                          {...getTagProps({ index })}
                          style={{
                            margin: '0',
                            padding: '0',
                          }}
                        />;
                      })
                    }
                  />
                ) : null}
                {showSavedFilter ? (
                  <FormControl
                    sx={{
                      ...classes.autocomplete,
                      marginTop: { xxs: '10px', sm: '0px' },
                      marginRight: '0px',
                    }}
                  >
                    <Select
                      labelId="b2bSavedFilter-label"
                      id="b2bSavedFilter"
                      data-testid="search-advisor-saved-select"
                      data-cy="search-advisor-saved-select"
                      value={savedFilter.value}
                      sx={{
                        height: '44px',
                        borderRadius: '10px',
                        color: '#74879A',
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSavedFilter((prevValue) => ({
                          ...prevValue,
                          value,
                        }));
                      }}
                      inputProps={{
                        style: {
                          borderRadius: '50px',
                        },
                      }}
                    >
                      <MenuItem value="allSaved">All Saved Advisors</MenuItem>
                      <MenuItem value="mySaved">My Saved Advisors</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
                {peerFilterComponent()}
              </Box>
            </>
          )}

          <Box
            mt={2}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxHeight: !showMore ? '80px' : '100%',
              // maxWidth: '1200px',
              overflow: 'hidden',
              transition: 'max-height 0.5s ease-in-out',
              marginTop: { xxs: '30px', sm: '0px' },
              marginBottom: { xxs: '10px', sm: '0px' },
            }}
          >
            {skills.map((option, index) => (
              <Chip
                onDelete={() => handleRemoveSkill(option)}
                key={index}
                label={option.label}
                sx={{ margin: '4px', backgroundColor: '#F5F7FA' }}
              />
            ))}

            {industries.map((option, index) => (
              <Chip
                onDelete={() => handleRemoveIndustry(option)}
                key={index}
                label={option.label}
                sx={{ margin: '4px', backgroundColor: '#D7F1FD' }}
              />
            ))}
          </Box>
          {isMobile ? (
            <>
              {(skills.length > 3 ||
                industries.length > 3 ||
                skills.length + industries.length > 3) && (
                <Box
                  sx={{
                    display: 'flex',
                    // maxWidth: '1200px',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#E6EDFF',
                      height: '1px',
                      width: '100%',
                    }}
                  />
                  <Typography
                    onClick={() => setShowMore(!showMore)}
                    sx={{
                      display: 'flex',
                      marginLeft: '20px',
                      marginRight: '20px',
                      color: '#74879A',
                      cursor: 'pointer',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                    }}
                  >
                    {showMore ? 'Less' : 'More'}
                    <ExpandCircleDownOutlinedIcon
                      sx={{
                        transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform .5s',
                        marginLeft: '5px',
                      }}
                    />
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: '#E6EDFF',
                      height: '1px',
                      width: '100%',
                    }}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              {(skills.length > 7 ||
                industries.length > 7 ||
                skills.length + industries.length > 13) && (
                <Box
                  sx={{
                    display: 'flex',
                    // maxWidth: '1200px',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#E6EDFF',
                      height: '1px',
                      width: '100%',
                    }}
                  />
                  <Typography
                    onClick={() => setShowMore(!showMore)}
                    sx={{
                      display: 'flex',
                      marginLeft: '20px',
                      marginRight: '20px',
                      color: '#74879A',
                      cursor: 'pointer',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                    }}
                  >
                    {showMore ? 'Less' : 'More'}
                    <ExpandCircleDownOutlinedIcon
                      sx={{
                        transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform .5s',
                        marginLeft: '5px',
                      }}
                    />
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: '#E6EDFF',
                      height: '1px',
                      width: '100%',
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </div>
    </form>
  );
}

export default AdvisorFilter;
