import { Box, Typography, Avatar, Button, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAccount } from "../../../contexts/Account";
import BoardMeetingPopUp from "../../../components/Post/BoardMeetingPopUp";
import VerticalElipsiesMenu from "../../../components/Post/Card/PostCardHeader/RightHandVerticalElipsies";
import BoardMeetingVideo from "./BoardMeetingVideo";

const useStyles = () => ({
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    borderRadius: '8px',
    padding: "0px 10px",
  },
  subContainerStackHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 2
  }
});


function LeftSideBoardmeetingPost({ post }) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { isImpersonated } = useAccount();
  const [isSticky, setIsSticky] = useState(false);
  const [openBoardMeetingPost, setOpenBoardMeetingPost] = useState(false);

  const parentContainerRef = useRef(null);

  let localeHour = new Date(post?.meetingTime);
  const today = new Date();

  const [time, setTime] = useState(
    localeHour > today
      ? 'future'
      : differenceInHours(today, localeHour) < 1
        ? 'live'
        : 'past'
  );

  useEffect(() => {
    if (time == 'future' && differenceInHours(localeHour, today) < 2) {
      const interval = setInterval(() => {
        const today = new Date();
        setTime(
          localeHour > today
            ? 'future'
            : differenceInHours(today, localeHour) < 1
              ? 'live'
              : 'past'
        );
      }, 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    localeHour = new Date(post?.meetingTime);
  }, [post]);

  function differenceInHours(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return diff;
  }

  function getPostTimeStatus() {
    if (time === 'future') {
      return 'Upcoming';
    } else if (time === 'live') {
      return 'Live';
    }
    return 'Replay';
  }

  function pinPost(postId, companyId, isPinned) {
    pinPostCall({
      COMPANY_ID: companyId,
      POST_ID: postId,
      UN_PIN: isPinned,
    })
      .then((response) => {
        setShowSuccesssPinned(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Box ref={parentContainerRef} sx={{
        height: isSticky ? '20%' : '100%',
        width: isSM ? "unset" : '70%',
        marginInline: isSM ? "15px" : 'unset',
        marginBlock: isSM ? "15px" : 'unset',
        backgroundColor: isSticky ? 'unset' : "#FFF",
        marginBottom: isSticky ? "0px" : 'unset',
        ...classes.subContainer,
      }}>
        {/* Header */}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2 }}>
          <Box sx={classes.subContainerStackHeader}>
            <Avatar alt='company-contact-image' src={post.postCompanyContact?.imageURL} sx={{ borderRadius: "30%" }} />
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: 16,
              fontWeight: 600,
              color: "#001030",
              lineHeight: "18px",
            }}>{post.postCompanyContact?.companyName}</Typography>
          </Box>
          <Box sx={classes.subContainerStackHeader}>
            <Button color='primary' variant='contained' sx={{
              fontFamily: 'Poppins',
              textTransform: 'none',
              borderRadius: 20,
              padding: '5px 20px',
              boxShadow: 'none',
              pointerEvents: 'none',
              cursor: 'default',
              backgroundColor: time === 'future' ? '#2B71F6' : time === 'live' ? '#F92C2C' : '#2CA0A3',
            }}>
              {getPostTimeStatus()}
            </Button>
            {isXS ? null : <Button variant='contained' sx={{
              fontFamily: 'Poppins',
              textTransform: 'none',
              borderRadius: 20,
              padding: '5px 20px',
              boxShadow: 'none',
              pointerEvents: 'none',
              cursor: 'default',
              color: "#2B71F6",
              backgroundColor: "white",
              border: '1px solid #2B71F6',
            }}>
              Board meeting
            </Button>}
            {isImpersonated && <VerticalElipsiesMenu
              editClick={() => setOpenBoardMeetingPost(true)}
              pinClick={() => { pinPost(post.id, post.companyId, post.isPinned) }}
              isPinned={false}
              type={"boardMeeting"}
            />}

          </Box>
        </Box>
        {/*  Body */}
        <BoardMeetingVideo
          postData={post}
          timeLive={time}
          isSingleBoardMeetingPost={true}
          boardMeetingLocked={false}
          renderVideoGate={false}
          parentContainerRef={parentContainerRef}
          setIsSticky={setIsSticky}
        />
      </Box>
      <BoardMeetingPopUp
        open={openBoardMeetingPost}
        postData={post}
        closeAction={() => setOpenBoardMeetingPost(false)}
      />
    </>
  )
}

export default LeftSideBoardmeetingPost;