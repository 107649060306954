import React, { useEffect, useState, useMemo } from 'react';
import {
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import ACSnackbar from './../../ACSnackbar';
import CloseIcon from '@mui/icons-material/Close';
import { StarOutline, Star } from '@mui/icons-material';
import InviteToJoinConfirmationModal from './../../Company/InviteToJoinConfirmationModal';
import BoardPosition from './components/boardPosition';
import ButtonGroupBar from './components/buttonGroupBar';
import ButtonGroupBarOwnProfile from './components/buttonGroupBarOwnProfile';
import ChipList from './components/chipList';
import AdvisorExperience from './components/advisorExperience';
import EditAdvisorProfile from './EditAdvisorProfile';
import { isNil, getAdvisorImagePath } from './../../../services/utils';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';
import { inviteToJoin } from './../../../services/company_services';
import { getPublicAdvisorRecurlyPlan } from './../../../services/advisor_services';
import { AccountType, SnackbarType } from './../../../services/utils/types';
import { getPublicTags } from './../../../services/Backend/account';
import StarSymbol from './components/star-symbol.svg';
import useCanMessage from './../../../hooks/useMessagePermission';
import useSavedAdvisor from './../../../hooks/useSavedAdvisor';
import { sendFSEventInvitedAdvisor } from '../../../services/FullStory';
import ProfileItemEmptyState from '../../v2/EmptyState/ProfileItemEmptyState';

const useStyles = ({ isSM, isXS }) => ({
  iframeContainer: {
    position: 'relative',
    paddingBottom: '50%',
    height: '750px',
    width: '755px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },

  // avatar, name and header
  mainInformationContainer: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: isSM ? 'column' : 'row',
  },
  avatarContainer: {
    alignItems: 'flex-start',
    display: isSM ? 'flex' : 'inherit',
    justifyContent: isSM ? 'center' : 'flex-start',
  },
  advisorInfoContainer: {
    position: 'relative',
    marginLeft: '7px',
    marginTop: '23px',
    textAlign: isSM ? 'center' : 'left',
    width: '100%',
  },
  advisorNameText: {
    marginLeft: isSM ? '0px' : '17px',
    display: 'inline',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '24px',
    letterSpacing: -0.43,
  },
  advisorHeadline: {
    marginLeft: '20px',
    marginTop: '43px',
    marginRight: isSM ? '0px' : '100px',
  },
  advisorHeadlineText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: -0.43,
    color: '#646D7A',
    whiteSpace: 'pre-wrap',
  },
  // Biography
  subtitle: {
    margin: '30px',
    marginBottom: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: -0.43,
    color: '#232B35',
  },
  subContainer: {
    margin: '30px',
  },
  biographyText: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: -0.43,
    color: '#232B35',
    paddingTop: '17px',
  },
  closeButtonContainer: {
    position: 'absolute',
    left: '95%',
    top: '10px',
    zIndex: 100,
  },
  // Board
  boardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  // Right
  infoContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    marginTop: '20px',
    marginLeft: isSM ? '0px' : '33px',
    width: isXS ? '92%' : null,
  },
  infoContainerRight: {
    width: isSM ? '100%' : '30%',
  },
  infoContainerLeft: {
    width: isSM ? '100%' : '70%',
  },
  dialogContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  actionsButton: {
    textTransform: 'none',
    height: '40px',
    borderRadius: '50px',
    width: '142px',
    margin: '5px',
    flexGrow: 1,
    border: '2px solid #0F78FD',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#3171F6',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '2px solid #0F78FD',
    },
  },
  icon: {
    fontSize: '26px',
  },
  starIconFilled: {
    color: '#FFC400',
    fontSize: '26px',
  },
  getYourAccountBar: {
    height: '73px',
    paddingTop: '27px',
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #EFF0F6',
  },
  hadlineText: {
    fontWeight: 500,
    fontSize: '16px',
    fontStyle: 'normal',
    color: '#646D7A',
    letterSpacing: -0.43,
  },
  skeletonStar: {
    color: '#F0F0F0',
    fontSize: '26px',
  },
  starButton: {
    position: 'relative',
    left: '32px',
    bottom: '10px',
    zIndex: 3,
  },
});

function CompanyAdvisorProfile(props) {
  const {
    accountDetails,
    asLoggedUserDetails,
    tags,
    setTagsFromPublicEndpoint,
    isFreeTrial,
    isFreemium,
    conversations,
    type,
    currentPlan,
  } = useAccount();
  const {
    displayName,
    givenName,
    surName,
    advisorId,
    biography,
    headline,
    skillTagIds,
    industryTagIds,
    interests,
    publicProfileUrl,
    boardCompanies,
    experiences,
    title,
    favoriteSkillsIds,
    favoriteIndustriesIds,
    publicProfileView,
    meetingRate,
    clickSource,
  } = props.isOwnProfile ? accountDetails : props;
  const advisor = props.isOwnProfile ? accountDetails : props;
  const isFocus = props.isFocus;
  const openInEdit = props.openInEdit;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const id = props.isOwnProfile ? accountDetails.id : props.id;
  const [snackMessage, setSnackMessage] = useState('');
  const [meetUrl, setMeetUrl] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [openStep1IFrame, setOpenStep1IFrame] = useState(false);
  const [snackbarWarning, setSnackbarWarning] = useState(false);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [invitationNote, setInvitationNote] = useState('');

  const [showInviteToJoinConfirmation, setShowInviteToJoinConfirmation] =
    useState(false);
  const boardAdvisors = accountDetails ? accountDetails.boardAdvisors : [];
  const invitedAdvisors = accountDetails ? accountDetails.invitedAdvisors : [];
  const isOnBoard =
    (boardAdvisors && boardAdvisors.some((ba) => ba.id == id)) ||
    (invitedAdvisors && invitedAdvisors.some((ba) => ba.id == id));
  const meetingsEnabled = currentPlan ? currentPlan.meetingsEnabled : false;
  const [editValues, setEditValues] = useState({
    givenName,
    surName,
    biography,
    headline,
    skillTagIds,
    industryTagIds,
    interests,
    experiences: experiences ? experiences.sort(sortExperience) : [],
    favoriteSkillsIds,
    favoriteIndustriesIds,
    meetingRate,
    meetingsEnabled: meetingsEnabled,
  });
  const canMessage = useCanMessage(id, AccountType.ADVISOR);
  const [isSaved, saveAdvisor] = useSavedAdvisor(advisorId);

  const showInactiveAdvisorBar = useMemo(() => {
    if (!['reply', 'response'].includes(clickSource)) return false;
    if (type === AccountType.ADVISOR) return false;
    if (!accountDetails || !accountDetails.boardAdvisors) return false;
    const relation = accountDetails.boardAdvisors.find(
      (advisor) => advisor.id === id
    );
    if (!relation) return true;
    if (relation.advisorStatus === 'inactive') return true;
    return false;
  }, [clickSource, accountDetails, id]);

  const advisorsRoutes = [
    '/boardroom',
    '/benefits',
    '/questions',
    '/updates',
    '/answers',
    '/my-answers',
  ];
  const fontFamily = 'Poppins';

  function itsOwnProfile() {
    if (accountDetails && advisor) {
      return accountDetails.id === advisor.id;
    }
  }

  function mapSkills(skills) {
    if (!isNil(skills) && tags.skills) {
      const filteredArray = tags.skills.filter((element) =>
        skills.includes(String(element.id))
      );
      const mapSkillTag = filteredArray.map((element) => {
        return {
          id: element.id,
          label: element.label,
        };
      });
      if (props.highlights && props.highlights.skilltags) {
        const boldWords = extractBoldWords(props.highlights.skilltags);
        const highlightedArray = wrapBoldWords(mapSkillTag, boldWords);
        return highlightedArray;
      }
      return mapSkillTag;
    }
    return [];
  }

  const extractBoldWords = (htmlString) => {
    const boldWords = new Set();
    const regex = /<b>(.*?)<\/b>/g;
    let match;
    while ((match = regex.exec(htmlString)) !== null) {
      boldWords.add(match[1]);
    }
    return Array.from(boldWords);
  };

  const wrapBoldWords = (array, boldWords) => {
    return array.map((item) => {
      let newItem = item;
      let isHighlighted = false;
      boldWords.forEach((word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        if (regex.test(newItem.label)) {
          isHighlighted = true;
        }
      });
      return { ...newItem, isHighlighted };
    });
  };

  function getHighlight(param) {
    if (props.highlights && props.highlights[param]) {
      const boldWords = extractBoldWords(props.highlights[param]);
      let newLabel = props[param] ? props[param] : '';
      boldWords.forEach((word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        newLabel = newLabel.replace(
          regex,
          `<span style="background-color: #FEF8D1;">${word}</span>`
        );
      });
      return newLabel;
    }
    return props[param];
  }

  function mapIndustries(industries) {
    if (!isNil(industries) && tags.industries) {
      const filteredArray = tags.industries.filter((element) =>
        industries.includes(String(element.id))
      );
      const mapIndustryTag = filteredArray.map((element) => {
        return {
          id: element.id,
          label: element.label,
        };
      });
      if (props.highlights && props.highlights.industrytags) {
        const boldWords = extractBoldWords(props.highlights.industrytags);
        const highlightedArray = wrapBoldWords(mapIndustryTag, boldWords);
        return highlightedArray;
      }
      return mapIndustryTag;
    }
    return [];
  }

  function closeEditing(success) {
    if (success) {
      setIsEditing(false);
      setSnackMessage(t('PROFILE-UPDATED-MESSAGE'));
      setSnackbarSuccess(true);
    }
    setIsSaving(false);
  }

  function sendFSEventOnInvite({ outcome, inviteId }) {
    sendFSEventInvitedAdvisor({
      advisorId,
      asLoggedUserDetails,
      outcome,
      inviteId: inviteId || '',
      source: 'profile',
      invitationNote: invitationNote,
      invitationIncludedNote: invitationNote !== '' ? true : false,
    });
  }

  function inviteToJoinCall(advisorId) {
    inviteToJoin({
      ADVISOR_ID: advisorId,
      COMPANY_ID: accountDetails.id,
      USER_ID: asLoggedUserDetails.userId,
      INVITATION_NOTE: invitationNote,
    })
      .then(({ data }) => {
        setSnackMessage(`${displayName} has been invited to your board`);
        setSnackbarSuccess(true);
        sendFSEventOnInvite({
          outcome: 'invited',
          inviteId: data.inviteAdvisorToJoin.id,
        });
      })
      .catch((e) => {
        const err = JSON.parse(e.errors[0].message);
        setSnackMessage(err.error);
        setSnackbarWarning(true);
        sendFSEventOnInvite({
          outcome: 'error',
        });
      });
  }

  useEffect(() => {
    let isMounted = true;
    if (publicProfileView) {
      if (!tags.industries || !tags.skills) {
        getPublicTags().then((publicTags) => {
          const { data } = publicTags;
          const currentTags = {
            industries: data.getTags.industriesData,
            positions: data.getTags.positionsData,
            skills: data.getTags.skillsData,
          };
          setTagsFromPublicEndpoint(currentTags);
        });
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      eventBus.dispatch(eventBusValues.triggerCloseBlocker, true);
    } else {
      eventBus.dispatch(eventBusValues.triggerCloseBlocker, false);
    }

    setEditValues({
      givenName,
      surName,
      biography,
      headline,
      skillTagIds,
      industryTagIds,
      interests,
      experiences: experiences ? experiences.sort(sortExperience) : [],
      favoriteSkillsIds,
      favoriteIndustriesIds,
      meetingRate,
      meetingsEnabled: meetingsEnabled,
    });
  }, [isEditing]);

  useEffect(() => {
    setIsEditing(openInEdit);
  }, [openInEdit]);

  useEffect(() => {
    if (advisor && advisor.recurlyPlanCode) {
      getPublicAdvisorRecurlyPlan({
        recurlyPlanCode: advisor.recurlyPlanCode,
      })
        .then((result) => {
          const planCode = result.data.getRecurlyPlan;
          if (planCode.meetingsEnabled) {
            setMeetUrl(
              `https://register.advisorycloud.com/connect-with-me/a.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}&hourly_rate=${advisor.meetingRate}`
            );
          } else {
            setMeetUrl(
              `https://register.advisorycloud.com/connect-with-me/b.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [advisor]);

  useEffect(() => {
    eventBus.on(eventBusValues.drawerTryToCloseOnEdit, () => {
      setOpenExitDialog(true);
    });
    eventBus.on(eventBusValues.onInviteToJoinCompanyProfile, () => {
      setShowInviteToJoinConfirmation(true);
    });

    return () => {
      eventBus.remove(eventBusValues.drawerTryToCloseOnEdit);
      eventBus.remove(eventBusValues.onInviteToJoinCompanyProfile);
    };
  }, []);

  function handleOpenInNewIconClick() {
    window.open(
      `${window.location.origin}/profile/${publicProfileUrl}`,
      '_blank'
    );
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(
      `${window.location.origin}/advisors/search?advisor_profile=${publicProfileUrl}`
    );
    setSnackMessage(t('PROFILE-CLIPBOARD-MESSAGE'));
    setSnackbarSuccess(true);
  }

  function copyPublicToClipboard() {
    navigator.clipboard.writeText(
      `${window.location.origin}/profile/${publicProfileUrl}`
    );
    setSnackMessage(t('PROFILE-CLIPBOARD-MESSAGE'));
    setSnackbarSuccess(true);
  }

  function sortExperience(a, b) {
    if (a.endDate === 'Present' && b.endDate !== 'Present') return -1;
    if (a.endDate !== 'Present' && b.endDate === 'Present') return 1;
    if (Number(a.startDate) < Number(b.startDate)) return 1;
    if (Number(a.startDate) > Number(b.startDate)) return -1;
    return 0;
  }

  function starIcon() {
    if (isSaved === null) return <Star sx={classes.skeletonStar} />;
    if (isSaved) return <Star sx={classes.starIconFilled} />;
    return <StarOutline sx={classes.icon} />;
  }

  function conversationClick() {
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) => conversation.conversationMemberAccountId === id
      );
      if (found) {
        const currentConversationId = found.conversationId;
        const queryParams = new URLSearchParams(window.location.search);
        const conversationIdParam = queryParams.get('conversationId');
        if (conversationIdParam !== currentConversationId) {
          history.push(`/messages?conversationId=${currentConversationId}`);
        }
        eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: id,
          senderName: advisor.displayName,
        });
      }
    } else {
      eventBus.dispatch(
        type === AccountType.COMPANY
          ? eventBusValues.triggerCompanyUpgradeAction
          : eventBusValues.triggerAdvisorUpgradeAction,
        {
          action: () => {
            eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
              senderId: id,
              senderName: advisor.displayName,
            });
          },
        }
      );
    }
  }

  function handleCloseDialog() {
    setOpenMeetIFrame(false);
  }

  function tootlTipContent() {
    if (publicProfileView)
      return (
        <CustomTooltip
          title="Featured Advisors have priority search
                listings and may participate in our featured
                advisor interviews and podcasts."
          arrow
          placement="left"
        >
          <img
            src={StarSymbol}
            alt="star-symbol"
            style={{
              position: 'absolute',
              left: '10px',
              top: '20px',
              zIndex: 3,
              width: '31px',
              height: '31px',
              cursor: 'default',
            }}
          />
        </CustomTooltip>
      );

    if (accountDetails) {
      if (!itsOwnProfile() && accountDetails.companyName === 'null')
        return (
          <>
            {type === 'advisor' && isFreemium ? (
              <></>
            ) : (
              <CustomTooltip
                title="Featured Advisors have priority search
                    listings and may participate in our featured
                    advisor interviews and podcasts."
                arrow
                placement="left"
              >
                <img
                  src={StarSymbol}
                  alt="star-symbol"
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '20px',
                    zIndex: 3,
                    width: '31px',
                    height: '31px',
                    cursor: !accountDetails.featuredAdvisor
                      ? 'pointer'
                      : 'default',
                  }}
                  onClick={() => {
                    if (!accountDetails.featuredAdvisor) {
                      eventBus.dispatch(
                        eventBusValues.triggerUpgradeFeaturedAdvisorAction,
                        {
                          fromBanner: true,
                        }
                      );
                    }
                  }}
                />
              </CustomTooltip>
            )}
          </>
        );

      return (
        <>
          {type === 'advisor' && isFreemium ? (
            <></>
          ) : (
            <img
              src={StarSymbol}
              alt="star-symbol"
              style={{
                position: 'absolute',
                left: '-5px',
                top: '45px',
                zIndex: 3,
                width: '31px',
                height: '31px',
                cursor: !accountDetails.featuredAdvisor ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (!accountDetails.featuredAdvisor) {
                  eventBus.dispatch(
                    eventBusValues.triggerUpgradeFeaturedAdvisorAction,
                    {
                      fromBanner: true,
                    }
                  );
                }
              }}
            />
          )}
        </>
      );
    }
    return null;
  }

  const HighlightedText = ({ text }) => {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
      '&:before': {
        border: '1px solid #E6EDFF',
        boxShadow: '0px 1px 10px 0px #E6EDFE',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#232B35',
      padding: '10px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      border: '1px solid #E6EDFF',
      boxShadow: '0px 1px 10px 0px #E6EDFE',
      fontSize: 12,
    },
  }));

  return (
    <>
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={handleCloseDialog}
      >
        <div style={classes.closeButtonContainer}>
          <CloseIcon onClick={() => setOpenMeetIFrame(false)} />
        </div>
        <div style={classes.iframeContainer}>
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
      <Dialog
        open={openStep1IFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={() => setOpenStep1IFrame(false)}
      >
        <div style={classes.iframeContainer}>
          <iframe
            src="https://register.advisorycloud.com/directory/b2b-step-1/"
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
      <Dialog
        open={openExitDialog}
        sx={classes.dialogContainer}
        maxWidth="sm"
        onClose={() => {
          setOpenExitDialog(false);
        }}
      >
        <div style={classes.dialogContainer}>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              {t('ADVISOR-CLOSE-SAVING-WARNING')}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                setOpenExitDialog(false);
                setIsEditing(false);
                eventBus.dispatch(eventBusValues.triggerCloseBlocker, false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              {t('ADVISOR-DISCARD-CHANGES')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                setOpenExitDialog(false);
                setIsSaving(true);
                eventBus.dispatch(eventBusValues.triggerCloseBlocker, false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              {t('ADVISOR-SAVE-CHANGES')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <div>
        {showInactiveAdvisorBar && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#7F00FF',
              width: '100%',
              height: '50px',
              cursor: 'pointer',
            }}
            onClick={conversationClick}
          >
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              This advisor is no longer active on your advisory board. If you'd
              still like to get in touch with them, click here.
            </Typography>
          </Box>
        )}
        {publicProfileView && (
          <div style={classes.getYourAccountBar}>
            <Typography sx={classes.hadlineText}>
              Interested in becoming an advisor?
              <a
                style={{ marginLeft: '3px' }}
                href="https://advisorycloud.com/join-an-advisory-board?source=publicprofile"
              >
                {' Get your free profile >'}
              </a>
            </Typography>
          </div>
        )}
        {!props.isOwnProfile ? (
          <ButtonGroupBar
            isAdvisorRoutes={advisorsRoutes.some((route) =>
              location.pathname.includes(route)
            )}
            isPublicProfileView={publicProfileView}
            isOnBoard={isOnBoard}
            backAction={() => {
              eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
            }}
            onInvite={() => {
              if (isFreeTrial) {
                eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
              } else {
                eventBus.dispatch(eventBusValues.onInviteToJoinCompanyProfile);
              }
            }}
            onShareProfile={copyToClipboard}
            onSharePublic={copyPublicToClipboard}
            onConversation={conversationClick}
            onShare={handleOpenInNewIconClick}
            advisor={advisor}
            showInactiveAdvisorBar={showInactiveAdvisorBar}
          />
        ) : (
          <ButtonGroupBarOwnProfile
            setSuccessSnackbar={setSnackbarSuccess}
            setSuccessMessage={setSnackMessage}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            backAction={() => {
              eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
            }}
          />
        )}
        {!isEditing ? (
          <>
            <div style={classes.mainInformationContainer}>
              <div
                style={{
                  ...classes.avatarContainer,
                  marginLeft: isSM
                    ? type === AccountType.ADVISOR
                      ? '0px'
                      : '-30px'
                    : type === AccountType.ADVISOR || publicProfileView
                    ? '65px'
                    : '20px',
                }}
              >
                {!advisorsRoutes.some((route) =>
                  location.pathname.includes(route)
                ) &&
                !publicProfileView &&
                type === AccountType.COMPANY ? (
                  <IconButton
                    sx={classes.starButton}
                    onClick={saveAdvisor}
                    id="starButton"
                  >
                    {starIcon()}
                  </IconButton>
                ) : null}
                {advisor.featuredAdvisor ? (
                  <div style={{ display: 'flex', position: 'relative' }}>
                    {tootlTipContent()}
                    {type === 'advisor' && isFreemium ? (
                      <>
                        <Avatar
                          src={getAdvisorImagePath(advisor)}
                          sx={{
                            width: '200px',
                            height: '200px',
                            zIndex: 1,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {publicProfileView ? (
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '15px',
                              height: '15px',
                              backgroundColor: '#ffffff',
                              left: '18px',
                              top: '28px',
                              zIndex: 2,
                              clipPath:
                                'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '17px',
                              height: '17px',
                              backgroundColor: '#ffffff',
                              left: '2px',
                              top: '52px',
                              zIndex: 2,
                              clipPath:
                                'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'inline-block',
                            width: '208px',
                            height: '208px',
                            borderRadius: '50%',
                            background:
                              'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                            padding: '4px',
                          }}
                        >
                          <Avatar
                            src={getAdvisorImagePath(advisor)}
                            sx={{
                              width: '200px',
                              height: '200px',
                              zIndex: 1,
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </div>
                ) : (
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <Avatar
                      src={getAdvisorImagePath(advisor)}
                      sx={{
                        width: '200px',
                        height: '200px',
                        zIndex: 1,
                      }}
                    />
                  </div>
                )}
              </div>
              <div style={classes.advisorInfoContainer}>
                <Typography sx={classes.advisorNameText}>
                  {displayName}
                </Typography>
                <div style={classes.advisorHeadline}>
                  <Typography sx={classes.advisorHeadlineText}>
                    {title}
                  </Typography>
                  {headline && (
                    <Typography variant="body1" sx={classes.biographyText}>
                      <HighlightedText text={getHighlight('headline')} />
                    </Typography>
                  )}
                  {!headline && props.isOwnProfile && (
                    <ProfileItemEmptyState
                      subtitle="Your headline will appear here"
                      items={[
                        {
                          width: isXS ? '93%' : '80%',
                        },
                        {
                          width: isXS ? '93%' : '80%',
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
            <Box
              sx={{
                marginLeft: {
                  xxs: '0px',
                  sm: '33px',
                },
              }}
            >
              {skillTagIds && skillTagIds.length ? (
                <div style={{ margin: '30px 60px 30px 30px' }}>
                  <Typography sx={classes.subtitle}>
                    Skills and Expertise
                  </Typography>
                  <ChipList
                    canBeStar
                    starList={favoriteSkillsIds}
                    items={mapSkills(skillTagIds)}
                    maxRows={2}
                  />
                </div>
              ) : null}
              {(!skillTagIds || skillTagIds.length === 0) &&
                props.isOwnProfile && (
                  <ProfileItemEmptyState
                    mainBoxStyle={{ marginTop: '30px' }}
                    subtitle="Your top skills will display here"
                    items={[
                      {
                        tagItems: ['22%', '13%', '13%', '17%', '15%'],
                        width: '80%',
                      },
                    ]}
                  />
                )}
            </Box>

            <div style={classes.infoContainer}>
              <div style={classes.infoContainerLeft}>
                {/* First column */}
                {biography ? (
                  <div style={classes.subContainer}>
                    <Typography sx={classes.subtitle}>
                      {t('ADVISOR-BIOGRAPHY-LABEL')}
                    </Typography>
                    <Typography variant="body1" sx={classes.biographyText}>
                      <HighlightedText text={getHighlight('biography')} />
                    </Typography>
                  </div>
                ) : null}
                {!biography && props.isOwnProfile && (
                  <ProfileItemEmptyState
                    title="Biography"
                    subtitle="Your biography will appear here once you've added one"
                    mainBoxStyle={classes.subContainer}
                    items={['95%', '95%', '95%', '95%'].map((width) => ({
                      width,
                    }))}
                  />
                )}
                {experiences && experiences.length ? (
                  <div style={classes.subContainer}>
                    <Typography sx={classes.subtitle}>
                      {t('ADVISOR-ACCOUNT-EXPERIENCE-LABEL')}
                    </Typography>
                    <AdvisorExperience
                      experienceArray={experiences.sort(sortExperience)}
                      experienceHighlight={
                        props.highlights && props.highlights['experience']
                          ? props.highlights['experience']
                          : ''
                      }
                    />
                  </div>
                ) : null}
                {(!experiences || experiences.length === 0) &&
                  props.isOwnProfile && (
                    <ProfileItemEmptyState
                      title="Experience"
                      subtitle="Your experience will appear here once you've added some"
                      mainBoxStyle={classes.subContainer}
                      items={[
                        '60%',
                        '15%',
                        '95%',
                        '95%',
                        '95%',
                        '95%',
                        '0%',
                        '60%',
                        '15%',
                        '95%',
                        '95%',
                        '95%',
                        '95%',
                        '0%',
                        '60%',
                        '15%',
                        '95%',
                        '95%',
                        '95%',
                        '95%',
                      ].map((width) => ({ width }))}
                    />
                  )}
              </div>
              <div style={classes.infoContainerRight}>
                {/* Second column */}
                {boardCompanies && boardCompanies.length ? (
                  <div style={classes.subContainer}>
                    <Typography sx={classes.subtitle}>
                      Board Positions
                    </Typography>
                    <div style={classes.boardContainer}>
                      {boardCompanies.map((companyId) => (
                        <BoardPosition
                          key={companyId}
                          companyId={companyId}
                          advisorId={id}
                          publicProfile={!accountDetails}
                          fontFamily={fontFamily}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {(!boardCompanies || boardCompanies.length === 0) &&
                  props.isOwnProfile && (
                    <ProfileItemEmptyState
                      title="Advisory Boards"
                      subtitle="Boards you join display here"
                      mainBoxStyle={{ ...classes.subContainer, width: '80%' }}
                      items={[
                        {
                          imageItem: true,
                          width: '50%',
                        },
                        {
                          imageItem: true,
                          width: '50%',
                        },
                        {
                          imageItem: true,
                          width: '50%',
                        },
                      ]}
                    />
                  )}

                {interests && interests.length ? (
                  <div style={classes.subContainer}>
                    <Typography sx={classes.subtitle}>
                      {t('ADVISOR-ACCOUNT-INTEREST-LABEL')}
                    </Typography>
                    <ChipList
                      column
                      dontShowMore
                      items={interests.map((element) => {
                        const mapInterstedTag = {
                          id: element,
                          label: element,
                        };
                        return mapInterstedTag;
                      })}
                    />
                  </div>
                ) : null}

                {(!interests || interests.length === 0) &&
                  props.isOwnProfile && (
                    <ProfileItemEmptyState
                      mainBoxStyle={{ ...classes.subContainer, width: '80%' }}
                      title="Interested in"
                      subtitle="Your interests display here"
                      items={[
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                      ]}
                    />
                  )}

                {industryTagIds && industryTagIds.length ? (
                  <div style={classes.subContainer}>
                    <Typography sx={classes.subtitle}>
                      {t('ADVISOR-ACCOUNT-INDUSTRY-LABEL')}
                    </Typography>
                    <ChipList
                      canBeStar
                      column
                      dontShowMore
                      items={mapIndustries(industryTagIds)}
                      starList={favoriteIndustriesIds}
                    />
                  </div>
                ) : null}

                {(!industryTagIds || industryTagIds.length === 0) &&
                  props.isOwnProfile && (
                    <ProfileItemEmptyState
                      mainBoxStyle={{ ...classes.subContainer, width: '80%' }}
                      title="Industries"
                      subtitle="Your industries display here"
                      items={[
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                        {
                          tagItems: ['100%'],
                          width: '50%',
                        },
                      ]}
                    />
                  )}
              </div>
            </div>

            <InviteToJoinConfirmationModal
              isVisible={showInviteToJoinConfirmation}
              advisor={advisor}
              onConfirm={() => {
                inviteToJoinCall(id ? id : advisorId);
                setShowInviteToJoinConfirmation(false);
              }}
              onClose={() => {
                setShowInviteToJoinConfirmation(false);
                sendFSEventOnInvite({ outcome: 'cancelled' });
              }}
              setInvitationNote={setInvitationNote}
            />
            <ACSnackbar
              open={snackbarSuccess}
              text={snackMessage ? t(snackMessage) : ''}
              severity={SnackbarType.SUCCESS}
              onClose={() => setSnackbarSuccess(false)}
              autoHideDuration={6000}
              sx={{ marginTop: isFreemium ? '50px' : '0px' }}
            />
            <ACSnackbar
              open={snackbarWarning}
              text={snackMessage ? t(snackMessage) : ''}
              severity={SnackbarType.WARNING}
              onClose={() => setSnackbarWarning(false)}
              autoHideDuration={6000}
              sx={{ marginTop: isFreemium ? '50px' : '0px' }}
            />
          </>
        ) : (
          <div>
            <EditAdvisorProfile
              advisorId={id}
              values={editValues}
              isSaving={isSaving}
              closeEditing={closeEditing}
              isFocus={isFocus}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CompanyAdvisorProfile;
