import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Drawer, MenuItem } from '@mui/material';
import { ReactComponent as PostButton } from './post_button_icon.svg';
import { useAccount } from './../../../contexts/Account';
import IframeEmbedModal from './../../IframeEmbedModal';
import CustomIcon from './../../../icons/customIcon';
import ACButton from './../../ACButton';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';
import eventBus, { eventBusValues } from '../../../eventBus';

const useStyles = ({ isMessages }) => ({
  iconStyle: {
    width: '27px',
    height: '27px',
    margin: 0,
    padding: 0,
  },
  button: {
    position: 'fixed',
    zIndex: 1000,
    bottom: isMessages ? '80px' : '20px',
    right: '20px',
    height: '64px',
    width: '64px',
    borderRadius: '32px',
    fontSize: '24px',
  },
  menu: {
    width: '100%',
    background: '#FFF',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
  menuTitle: {
    color: '#232B35',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.75px',
  },
  menuSubtitle: {
    color: '#646D7A',
    fontFeatureSettings: 'clig off, liga off',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
  },
  menuItemContent: {
    borderTop: '1px solid #EFF0F6',
  },
  menuItemIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '2px',
  },
});

function CompanyPostButtonMobile(props) {
  const { onClickPost, buttonColor, loading, isDisabled } = props;
  const { accountDetails, currentUserInfo } = useAccount();

  const isMessages = location.pathname.includes('messages');
  const classes = useStyles({ isMessages });
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleClose() {
    setOpenMenu(false);
  }

  return (
    <>
      <ACButton
        color={buttonColor}
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          zIndex: 1000,
          bottom: isMessages ? '80px' : '20px',
          right: '20px',
          padding: '0px 20px',
          borderRadius: '32px',
          // fontSize: '21px',
        }}
        onClick={() =>
          eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
            company: accountDetails,
          })
        }
        loading={loading ? 1 : 0}
        disabled={isDisabled}
        id="b2bNavBarFeedbackButton"
      >
        + Invite
      </ACButton>

      <Drawer
        id="simple-menu"
        anchor="bottom"
        sx={{ '& .MuiDrawer-paper': { ...classes.menu }, width: '100%' }}
        open={openMenu}
        onClose={handleClose}
      >
        <MenuItem key="placeholder" style={{ display: 'none' }} />
        <MenuItem
          id="postActionButton"
          onClick={() => {
            onClickPost();
            handleClose();
          }}
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="post"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '19px',
                  paddingLeft: '4px',
                  marginRight: '10px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>Post</Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Post something to your
              {accountDetails.userSegment === 'managed_advisory_board'
                ? ' boardroom'
                : ' AdvisoryCloud'}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          id="insightsActionButton"
          onClick={() => {
            history.push('/insights');
            handleClose();
          }}
          style={{
            ...classes.menuItemContent,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="insights"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '25px',
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>
                Get custom insights
              </Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Get feedback from custom audiences
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          id="meetWithAdvisorsActionButton"
          onClick={handleOpenModal}
          style={{
            ...classes.menuItemContent,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="calendar-meeting"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '22px',
                  marginRight: '10px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>
                Meet with advisors
              </Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Meet with advisors 1:1 or in a group
            </Typography>
          </div>
        </MenuItem>
      </Drawer>
      <IframeEmbedModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        iframeEmbedLink={`https://register.advisorycloud.com/get-feedback/meet-with-advisors/a.html?first_name=${accountDetails.userData.givenName}&last_name=${accountDetails.userData.surName}&plancode=${accountDetails.recurlyPlanCode}&cognito_id=${accountDetails.userData.id}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.userData.email}&company_name=${accountDetails.companyName}`}
        backgroundColor="rgba(245,247,250,1)"
      />
    </>
  );
}

export default CompanyPostButtonMobile;
