import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Link, Typography, useMediaQuery } from "@mui/material";
import { AccountType } from "../../../services/utils/types";
import { useAccount } from "../../../contexts/Account";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { getAdvisorQueryParams } from "../../../utils/queryParams";
import { getCompanyInfoBoardroomMenu } from "../../../services/company_services";
import { getModeratorImagePath } from "../../../services/utils";

import StakeholderCard from "./StakeholderCard";
import UpsellDialog from "../../v2/Boardroom/UpsellDialog";
import dateFormat from "../../../utils/dateFormat";
import calendarcheck from "../../../icons/calendar-check.svg";
import crown from "../../../icons/crown.svg";
import './textEditor.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PostBoardroomIdea from "../../../components/Advisor/PostBoardroomIdea/PostBoardroomIdea";


const useStyles = () => ({
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: "20px",
    backgroundColor: "#FFF",
    borderRadius: '8px',
    padding: "0px 10px",
  },
  subContainerStackHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 2
  }
});

function RightSideBoardmeetingPost({ post }) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [tilesWrapped, setTilesWrapped] = useState(false);
  const stakeholderTilesRef = useRef(null);

  const { type } = useAccount();
  const { currentPlan, accountDetails, isFreeTrial, isFreemium } = useAccount();
  const [specialOffersDialogOpen, setSpecialOffersDialogOpen] = useState(false);
  const [company, setCompany] = useState({});
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    getCompanyInfoBoardroomMenu({ companyId: post.companyId }).then((response) => {
      setCompany(response.data.getCompany);
    }).catch((error) => {
      console.error('Error fetching company at board meeting post', error);
    });
  }, [])

  useEffect(() => {
    if (post.body) {
      try {
        const contentState = convertFromRaw(JSON.parse(post.body));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (err) {
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [post]);

  function formatBody(text, linkAtTheEnd) {
    if (editorState && editorState.getCurrentContent().hasText()) {
      return (
        <Editor
          editorState={editorState}
          toolbarHidden
          readOnly
          editorStyle={{
            marginTop: '-15px',
            color: '#232B35',
          }}
        />
      );
    }

    const textArray = text.split(' ');
    const elementsArray = [];
    let wordBucket = [];
    textArray.forEach((word, index) => {
      if (
        word.startsWith('https://') ||
        word.startsWith('http://') ||
        word.includes(`\n`)
      ) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(' ')}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        if (!word.includes(`\n`)) {
          elementsArray.push(
            <Link
              key={`${index}l1`}
              href={word}
              target="_blank"
              onMouseOver={() => setMouseOverLink(true)}
              onMouseLeave={() => setMouseOverLink(false)}
            >
              {word}
            </Link>
          );
          elementsArray.push(<span key={`${index}s3`}> </span>);
        } else {
          let separatedWord = word.split(`\n`);
          separatedWord.forEach((piece, brIndex) => {
            if (piece.startsWith('https://') || piece.startsWith('http://')) {
              elementsArray.push(
                <Link
                  key={`${brIndex}link${index}`}
                  href={piece}
                  target="_blank"
                  onMouseOver={() => setMouseOverLink(true)}
                  onMouseLeave={() => setMouseOverLink(false)}
                >
                  {piece}
                </Link>
              );
            } else if (piece !== '') {
              elementsArray.push(
                <span key={`${index}piece${brIndex}`}>{piece}</span>
              );
            }
            if (brIndex !== separatedWord.length - 1) {
              elementsArray.push(<br key={`${brIndex}br${index}`} />);
            } else {
              elementsArray.push(<span key={`${index}sp${brIndex}`}> </span>);
            }
          });
        }

        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(' ')}</span>);
    if (linkAtTheEnd) {
      elementsArray.push(<span key={'extra03'}> </span>);
      elementsArray.push(
        <Link
          key={'extra04'}
          href={linkAtTheEnd.targetUrl}
          target={linkAtTheEnd.newTab ? '_blank' : '_self'}
          onMouseOver={() => setMouseOverLink(true)}
          onMouseLeave={() => setMouseOverLink(false)}
        >
          {linkAtTheEnd.text}
        </Link>
      );
    }
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  // Resize observer for stakeholder tiles. If it wraps, change the direction to column
  useEffect(() => {
    if (!stakeholderTilesRef.current) return;
  
    const observer = new ResizeObserver(([entry]) => {
      requestAnimationFrame(() => {
        const width = entry.contentRect.width;
        setTilesWrapped(width < 500);
      });
    });
  
    observer.observe(stakeholderTilesRef.current);
  
    return () => observer.disconnect();
  }, []);
  
  return (
    <>
      <UpsellDialog
        open={specialOffersDialogOpen}
        onClose={() => { setSpecialOffersDialogOpen(false) }}
        finalUrl={`${currentPlan.specialOffersUrl}?${getAdvisorQueryParams({
          accountDetails,
          isFreeTrial,
          isFreemium,
        })}`}
      />

      <Box sx={{
        height: "90vh",
        width: isSM ? "unset" : '40%',
        marginInline: isSM ? "15px" : "unset",
        marginBlock: isSM ? "15px" : "unset",
        ...classes.subContainer
      }}>
        <Typography sx={{
          marginTop: 2,
          fontFamily: 'Poppins',
          fontSize: 24,
          fontWeight: 600,
          lineHeight: "31px",
          color: "#001030",
        }}
        >
          {post.postTitle}
        </Typography>

        <CalendarCard post={post} />

        <Box ref={stakeholderTilesRef} sx={{
          display: 'flex',
          flexDirection: tilesWrapped ? 'column' : 'row',
          justifyContent: 'space-evenly',
          marginBottom: isSM ? 2 : 0,
          rowGap: 2,
          columnGap: "20px",
          width: tilesWrapped ? '100%' : 'unset',
        }}>
          <StakeholderCard name={post.postCompanyContact?.name} title={post.postCompanyContact?.title} imageURL={post.postCompanyContact?.companyContactImage} key={"Stakeholder-card-companycontact"} />
          <StakeholderCard name={post.moderatorName} title={post.moderatorTitle} imageURL={getModeratorImagePath(post.moderatorAvatar)} key={"Stakeholder-card-moderator"} />
        </Box>

        {type === AccountType.ADVISOR ?
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: "12px" }}>

            <PostBoardroomIdea company={company} />
            {currentPlan.specialOffersUrl ? <Button
              onClick={() => { setSpecialOffersDialogOpen(true) }}
              variant='contained'
              color="primary"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: 14,
                textTransform: 'none',
                borderRadius: '8px',
                padding: '5px 20px',
                boxShadow: 'none',
                color: "#605600",
                backgroundColor: "#FFF7B2",
                "&:hover": {
                  backgroundColor: "#FFF7B2",
                }
              }}>
              <img src={crown} style={{ marginRight: 5 }} alt="crown" />
              Upgrade to VIP
            </Button> : null}
          </Box> : null}

        <Divider />
        <Box style={{
          width: '100%',
          height: "inherit",
          whiteSpace: 'break-spaces',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          marginBottom: 5,
          overflowY: "auto",
        }}>
          {formatBody(post.body, post.linkAtTheEnd)}
        </Box>


      </Box>
    </>
  );
}

function CalendarCard({ post }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '10px',
        columnGap: "10px",
        backgroundColor: '#F4F8FF',
        borderRadius: '8px',
      }}
    >
      <img src={calendarcheck} alt="calendar-check" />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
        <Typography sx={{
          fontFamily: 'Poppins',
          fontSize: 16,
          fontWeight: 400,
          color: "#1F5ED7",
          lineHeight: "24px",
        }}>
          {dateFormat(post.meetingTime, "mmmm dS, yyyy @ h:MM TT")}
        </Typography>
        <Typography sx={{
          fontFamily: 'Poppins',
          fontSize: 12,
          fontWeight: 400,
          color: "#4D586F",
          lineHeight: "18px",
        }}>{post.meetingTimeZone}
        </Typography>
      </Box>
    </Box>
  )
}



export default RightSideBoardmeetingPost;