export const companyAllowedRoutes = [
  '/dashboard',
  '/account',
  '/board-meetings',
  '/updates',
  '/update',
  '/updates/archived',
  '/update/:postId?',
  '/updates/:postId?',
  '/update/:postId?/edit',
  '/questions',
  '/question',
  '/questions/archived',
  '/post/:postId?',
  '/question/:postId?',
  '/questions/:postId?',
  '/question/:postId?/edit',
  '/insight',
  '/insight/:postId?',
  '/insight/:postId?/edit',
  '/meeting',
  '/meeting/:postId?',
  '/meeting/:postId?/edit',
  '/breakthroughs',
  '/advisors',
  '/advisors/search',
  '/advisors/invited',
  '/advisors/saved',
  '/advisors/top_matches',
  '/advisors/:publicProfileUrl',
  '/advisor_ideas',
  '/messages',
  '/company/onboarding/terms-of-service',
  '/company/onboarding/step1',
  '/company/onboarding/step2',
  '/company/onboarding/call',
  '/company/onboarding',
  '/company/new/step1',
  '/company/new/step2',
  '/company/new/step3',
  '/company/new/step4',
  '/company/new/step5',
  '/setup-step-1',
  '/setup-step-2',
  '/setup-step-3',
  '/setup-step-4',
  '/setup-step-5',
  '/company/freeaccountonboarding/call',
  '/company-member/onboarding/profile',
  '/company-member/onboarding/terms-of-service',
  '/onboarding/call/upgrade',
  '/company-member/welcome',
  '/insights',
  '/upgrade/b2b_managed_advisory_board',
  '/upgrade/b2b_general',
  '/upgrade/b2b_ac_plus',
  '/upgrade/b2b_ft',
  '/skeleton/loading/test',
];
