import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useAccount } from '../contexts/Account';
import Dashboard from '../scenes/AdvisorDashboardV2';
import IndividualPost from '../scenes/IndividualPost';
import SearchCompanies from '../scenes/SearchCompanies';
import AdvisorAccount from '../scenes/AdvisorAccount';
import TopBar from './Topbar';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import Treatment from './../components/Treatment';
import { featureFlags } from './../splitSetup';
import BoardroomAdvisorView from './Advisor/Boardroom';
import AdvisoryBoards from '../scenes/v2/AdvisoryBoards';
import Boardroom from '../scenes/v2/Boardroom';
import PersonalAdvisoryBoards from '../scenes/v2/PersonalAdvisoryBoards';

const useStyles = () => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '0px',
    backgroundColor: '#f4f8ff',
    overscrollBehavior: 'contain',
  },
  impersonationBanner: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  contentContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 'calc(100vh - 50px)',
  },
  mainViewContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
  },
});

function Layout() {
  const { isImpersonated, currentUserInfo } = useAccount();
  const classes = useStyles();
  const topBarRef = useRef(null);
  const containerRef = useRef(null);
  const [retry, setRetry] = React.useState(true);

  function getContentMarginTop() {
    try {
      // dynamically set the margin top of the body container to the height of the top bar
      const topBarNode = ReactDOM.findDOMNode(topBarRef.current);
      const bodyContainerNode = ReactDOM.findDOMNode(containerRef.current);
      const topBarRect = topBarNode.children[0].getBoundingClientRect();
      bodyContainerNode.style.paddingTop = `${topBarRect.height}px`;
    } catch (error) {
      console.log(error);
      // retry after 20ms
      if (retry) {
        setRetry(false);
        setTimeout(() => {
          getContentMarginTop();
        }, 20);
      }
    }
  }

  useEffect(() => {
    getContentMarginTop();
  }, [topBarRef, containerRef]);

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        window._startY = event.touches[0].clientY;
      }
    };

    const handleTouchMove = (event) => {
      const startY = window._startY || 0;
      const currentY = event.touches[0].clientY;

      if (window.scrollY === 0 && currentY > startY) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchstart', handleTouchStart, {
      passive: true,
    });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <Treatment
      userId={currentUserInfo.username}
      splitNames={featureFlags.master_redesign}
      updateOnSdkTimedout
      evaluatedComponent={
        <div style={classes.baseContainer}>
          <Box ref={topBarRef}>
            <TopBar />
          </Box>
          {isImpersonated && (
            <div style={classes.impersonationBanner}>
              {/* Aquí podrías poner el componente de la banner de impersonation si existe */}
            </div>
          )}
          <div style={classes.contentContainer} ref={containerRef}>
            <div style={classes.mainViewContainer}>
              <Box sx={classes.viewContainer}>
                <Switch>
                  <Route path="/v2/dashboard" component={Dashboard} />
                  <Route path="/v2/post/:postId?" component={IndividualPost} />
                  <Route path="/v2/account" component={AdvisorAccount} />
                  <Route
                    path="/v2/:companySlug/boardroom"
                    render={() => <Boardroom />}
                  />
                  <Route path="/v2/account" component={AdvisorAccount} />
                  <Route
                    path="/v2/personal-advisory-boards"
                    component={PersonalAdvisoryBoards}
                  />

                  <Route
                    path="/v2/board-directory"
                    component={AdvisoryBoards}
                  />
                  <Route path="/peer-directory" component={AdvisoryBoards} />
                  <Route path="/messages" component={Dashboard} />
                </Switch>
              </Box>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Layout;
