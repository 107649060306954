import React, { useEffect, useState } from 'react';
import {
  Typography,
  useMediaQuery,
  Paper,
  Switch,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { NotificationsOutlined } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import { useTranslation } from 'react-i18next';
import { mutationUpdateAdvisorEmailPreferences } from './../../../services/advisor_services';
import { useAccount } from './../../../contexts/Account';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '25px',
    paddingLeft: isSM ? '15px' : '38px',
    paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '38px',
    marginBottom: '45px',
    border: 'solid 1px #F0F1F3',
  },
  sectionTitle: {
    marginLeft: isSM ? '10px' : '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  switchContainer: {
    marginLeft: isSM ? '-20%' : '',
    height: isSM ? '50px' : 'inherit',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notificationsTitle: {
    fontWeight: 'bold',
  },
  notificationsTextContainer: {
    wordWrap: 'break-word',
    marginLeft: isSM ? '-20%' : '',
  },
  longText: {
    whiteSpace: 'pre-wrap',
  },
  iconContainer: {
    width: isSM ? '15%' : '7%',
    paddingTop: '4px',
  },
  contentContainer: {
    width: isSM ? '85%' : '93%',
    marginLeft: '4px',
    paddingTop: '4px',
  },
  dialogContainer: {
    borderRadius: '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogText: {
    color: 'black',
  },
});

function AdvisorNotificationsSettings() {
  const [formValues, setFormValues] = useState({});
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSuccessSnackbarVisible, setIsSuccessSnackbarVisible] =
    useState(false);
  const [disableSwitchs, setDisableSwitchs] = useState(false);
  const { accountDetails, isFreemium } = useAccount();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [allEmails, setAllEmails] = useState(
    Object.values(formValues).some((v) => !v) ||
      Object.keys(formValues).length === 0
  );

  const toggleTextValues = {
    blockConversation: 'ADVISOR-NOTIFICATIONS-MESSAGES',
    blockNewBoardMeetingPost: 'ADVISOR-NOTIFICATIONS-BOARD-MEETINGS-POST',
  };
  let toggleValues = [
    'blockConversation',
    'blockNewBoardMeetingPost',
  ];

  useEffect(() => {
    const defaultEmailObject = {};
    if (accountDetails.emailPreferences) {
      toggleValues.forEach((value) => {
        defaultEmailObject[value] =
          accountDetails.emailPreferences[value] || false;
      });
      setFormValues(defaultEmailObject);
    }
  }, []);

  function changedSwitch(checked, formKey) {
    setDisableSwitchs(true);
    if (formKey === 'all') {
      setAllEmails(checked);
      let newFormValues = { ...formValues };
      toggleValues.forEach((value) => {
        newFormValues[value] = !checked;
      });
      setFormValues(newFormValues);
    } else {
      setFormValues({
        ...formValues,
        [formKey]: !checked,
      });
      if (!allEmails) {
        setAllEmails(
          Object.values({ ...formValues, [formKey]: !checked }).some((v) => !v)
        );
      }
    }

    mutationUpdateAdvisorEmailPreferences({
      ATTRIBUTE: formKey,
      ADVISOR_ID: accountDetails.id,
      VALUE: !checked,
    })
      .then(() => {
        setIsSuccessSnackbarVisible(true);
      })
      .catch(() => {
        setFormValues({
          ...formValues,
          [formKey]: checked,
        });
        setIsErrorSnackbarVisible(true);
      })
      .finally(() => {
        setDisableSwitchs(false);
      });
  }

  return (
    <>
      <ACSnackbar
        id="notifications-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={t('COMPANY-MEMBERS-GENERIC-ERROR')}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        id="notifications-success-snackbar"
        onClose={() => setIsSuccessSnackbarVisible(false)}
        open={isSuccessSnackbarVisible}
        text={t('COMPANY-NOTIFICATIONS-SUCCESS')}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <>
        <Paper elevation={0} sx={classes.card}>
          <div style={classes.container}>
            <div style={classes.iconContainer}>
              <NotificationsOutlined icconname="key-outlined" />
            </div>
            <div style={classes.contentContainer}>
              <Typography variant="body1" sx={classes.notificationsTitle}>
                {t('COMPANY-NOTIFICATIONS-PREFERENCES-TITLE')}
              </Typography>

              <div style={classes.notificationsTextContainer}>
                <pre style={classes.longText}>
                  <Typography variant="body1">
                    {t('ADVISOR-NOTIFICATIONS-INFO-TEXT')}
                  </Typography>
                </pre>
              </div>
              <div style={classes.switchContainer}>
                <Typography
                  variant="body1"
                  sx={classes.sectionTitle}
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  Opt-in to email notifications from AdvisoryCloud
                </Typography>
                <Switch
                  checked={allEmails}
                  onChange={(event, checked) => {
                    if (allEmails) {
                      setOpenConfirmationDialog(true);
                    } else {
                      changedSwitch(checked, 'all');
                    }
                  }}
                  color="primary"
                  disabled={disableSwitchs}
                />
              </div>
              {toggleValues.map((value) => {
                return (
                  <div style={classes.switchContainer} key={value}>
                    <Typography
                      variant="body1"
                      sx={classes.sectionTitle}
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {t(toggleTextValues[value])}
                    </Typography>
                    <Switch
                      checked={!formValues[value]}
                      onChange={(event, checked) =>
                        changedSwitch(checked, value)
                      }
                      color="primary"
                      disabled={disableSwitchs}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
      </>
      <Dialog
        maxWidth="xs"
        open={openConfirmationDialog}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <div>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              By disabling this option, you may not receive critical account
              notifications from AdvisoryCloud. We recommend keeping this
              setting on during the duration of your membership
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              onClick={() => {
                changedSwitch(false, 'all');
                setOpenConfirmationDialog(false);
              }}
              color="primary"
              variant="outlined"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Opt-out
            </Button>
            <Button
              onClick={() => setOpenConfirmationDialog(false)}
              color="primary"
              variant="contained"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default AdvisorNotificationsSettings;
