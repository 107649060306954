import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import { useAccount } from '../../contexts/Account';
import SinglePost from '../../components/Post/SinglePost';
import {
  getSinglePostCall,
  likePostCall,
} from '../../services/company_services';
import { AccountType, PostType } from '../../services/utils/types';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';
import LeftSideBoardmeetingPost from './components/LeftSideBoardmeetingPost';
import RightSideBoardmeetingPost from './components/RightSideBoardmeetingPost';

const useStyles = () => ({
  container: {
    width: '100%',
  },
});

function IndividualPost() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();

  const [post, setPost] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const { accountDetails, asLoggedUserDetails, type, isFreemium, currentUserInfo } =
    useAccount();
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams('/question/:postId');
  let contact = { id: undefined };

  if (type === AccountType.COMPANY) {
    contact = accountDetails.contacts.find(
      (c) => c.id === asLoggedUserDetails.userId
    );
  }

  useEffect(() => {
    getSinglePostCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
      POST_ID: params.postId,
    })
      .then(function (resp) {
        setPost(resp.data.getPost);
      })
      .catch((error) => {
        console.log('Error fetching post', error);
        setErrorMessage('Error fetching post');
        setShowErrorSnackbar(true);
        setTimeout(() => {
          const unauthorizedError = JSON.parse(error.errors.find(error => JSON.parse(error.message).code === "Unauthorized").message);
          if (unauthorizedError) {
            history.push({
              pathname: "/v2/board-directory",
              state: { companyId: unauthorizedError.companyId },
            });
          }
        }, 2000);
      });
  }, []);

  function setUserName() {
    if (type === AccountType.COMPANY) {
      return `${contact.givenName} ${contact.surName}`;
    }
    return accountDetails.givenName;
  }

  function postLikeAction(postInfo) {
    if (postInfo.postCompanyId === accountDetails.id) {
      setErrorMessage('Not allowed to like your own content');
      setShowErrorSnackbar(true);
    } else {
      addPostLike(postInfo);
      likePostCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: asLoggedUserDetails.userId,
        POST_ID: postInfo.postId,
      });
    }
  }

  function isAlreadyLiked(postLikesArray, userId) {
    return (
      postLikesArray.filter((element) => element.userId === userId).length > 0
    );
  }

  function addPostLike(likeDTO) {
    if (post) {
      if (!isAlreadyLiked(likeDTO.postLikes, likeDTO.userId)) {
        setPost({
          ...post,
          likesCount: post.likesCount + 1,
          likes: [
            ...post.likes,
            {
              accountType: likeDTO.type,
              name: likeDTO.name,
              userId: likeDTO.userId,
            },
          ],
        });
      } else {
        setPost({
          ...post,
          likesCount: post.likesCount - 1,
          likes: post.likes.filter(
            (likeElement) => likeElement.userId !== likeDTO.userId
          ),
        });
      }
    }
  }

  function oldSinglePost() {
    return (
      <SinglePost
        postData={post}
        replyLoading={() => { }}
        doNotShowBackClick={true}
        singlePostLike={() =>
          postLikeAction({
            postId: post.id,
            userId:
              type === AccountType.COMPANY ? contact.id : accountDetails.id,
            companyId: type === AccountType.COMPANY && accountDetails.id,
            advisorId: type === accountDetails.id,
            postCompanyId: post.companyId,
            accountType: type,
            name: setUserName(),
            postLikes: post.likes,
          })
        }
        postLikesFilled={
          post.likes.filter(
            (element) => element.userId === asLoggedUserDetails.userId
          ).length > 0
        }
      />
    )
  }

  function newSinglePost() {

    return (post.type !== PostType.BOARD_MEETING) ? oldSinglePost() : (
      <>
        <Box sx={{
          width: "100%",
          height: "100%",
          display: 'flex',
          flexDirection: !isSM ? 'row' : "column",
          columnGap: "24px",
        }}>

         <LeftSideBoardmeetingPost post={post} />
         <RightSideBoardmeetingPost post={post} />

        </Box>
      </>
    )
  }

  return (
    <>
      <ACSnackbar
        open={showErrorSnackbar}
        severity="error"
        text={errorMessage}
        onClose={() => setShowErrorSnackbar(false)}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <div
        style={{
          ...classes.container,
          padding:
            isSM && post.type === PostType.BOARD_MEETING ? '2px' : '24px',
        }}
      >
        {post ? (
          <Treatment
            userId={currentUserInfo.username}
            splitNames={featureFlags.board_meeting_post_redesign}
            updateOnSdkTimedout
            evaluatedComponent={newSinglePost()}
            conditionFlag
            conditionFailedComponent={oldSinglePost()}
          />
        ) : (
          <Box sx={{ width: { xxs: '100%', sm: '80%' } }}>
            <DashboardSkeleton />
          </Box>
        )}
      </div>
    </>
  );
}

export default IndividualPost;
