import { Dialog, DialogContent } from "@mui/material";
import { useAccount } from "../contexts/Account";
import { getAdvisorQueryParams } from "../utils/queryParams";

function LeadershipFrame({ showLeadershipFrame = false, setShowLeadershipFrame = () => { } }) {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();

  return (
    <Dialog open={showLeadershipFrame} onClose={() => setShowLeadershipFrame(false)}>
      <DialogContent>
        <iframe
          about="Thought Leadership"
          style={{ border: 'none' }}
          src={`https://register.advisorycloud.com/platform/thought-leadership?${getAdvisorQueryParams({ accountDetails, isFreeTrial, isFreemium })}`}
          width={800}
          height={600}
          title="Thought Leadership"
        />
      </DialogContent>
    </Dialog>
  );
}

export default LeadershipFrame;