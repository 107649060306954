import React, { useRef, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../contexts/Account';
import {
  searchAdvisorByFilters,
  searchAdvisorByFiltersLegacy,
} from '../../services/advisor_services';
import CompanySearchAdvisorsContent from '../../components/Company/SearchAdvisorsContent';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import {
  EndpointResponseObjectName,
  EndpointParameterFilters,
} from '../../services/utils/types';
import AdvisorFilter from '../../components/Advisor/Filter';
import { isNil } from '../../services/utils';
import { AccountType } from '../../services/utils/types';
import AdvisorsTopBar from '../../components/Advisor/TopBar';
import AdvisorTopTiles from '../../components/TopTiles/NetworkingTile';
import CompaniesFilter from '../../components/CompaniesFilter';
import ACToolTip from '../../components/ACToolTip';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

const getClasses = (isSM) => ({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    // maxWidth: '1200px',
    paddingLeft: isSM ? '10px' : '30px',
    paddingBottom: '20px',
    paddingRight: isSM ? '10px' : '30px',
    overflowY: isSM ? 'auto' : 'unset',
    height: isSM ? '100%' : 'auto',
  },
  containerInfo: {
    paddingTop: '20px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  iconInfo: {
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  messagePopUp: {
    padding: '35px 20px 35px 20px',
  },
});

function SearchAdvisors(props) {
  const { boxRef } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);
  const [doNotFetch, setDoNotFetch] = useState(false);
  const [disableSearchButton, setDisableSearchButton] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  // const [openSearchInfo, setOpenSearchInfo] = useState(false);
  const { accountDetails, type, currentUserInfo } = useAccount();
  const [queryParameters, setQueryParameters] = useState({
    SEARCH_STRING: '',
    LIMIT: EndpointParameterFilters.FETCH_LIMIT,
    OFFSET: 0,
    SKILLS_TAGS_IDS: [],
    INDUSTRY_TAGS_IDS: [],
    //   SKILLS_TAGS_IDS:
    //   type === AccountType.COMPANY ? accountDetails.desiredSkillsTagIds : [],
    // INDUSTRY_TAGS_IDS:
    //   type === AccountType.COMPANY ? accountDetails.industryTagIds : [],
    IS_NEW: true,
    CREATED_SORT: true,
    COMPANY_ID: type === AccountType.COMPANY ? accountDetails.id : null,
    SEARCH_FILTER: false,
    FILTER_TYPE: '',
  });
  const { t } = useTranslation();

  function handleSearch(props) {
    setDisableSearchButton(true);
    const { keywords, skills, position, industries, invitedFilter } = props;
    const searchString = {
      SEARCH_STRING: null,
      SKILLS_TAGS_IDS: type === AccountType.ADVISOR ? [] : null,
      INDUSTRY_TAGS_IDS: type === AccountType.ADVISOR ? [] : null,
      LIMIT: EndpointParameterFilters.FETCH_LIMIT,
      OFFSET: 0,
      IS_NEW: false,
      CREATED_SORT: true,
      COMPANY_ID: type === AccountType.COMPANY ? accountDetails.id : null,
      SEARCH_FILTER: !!invitedFilter,
      FILTER_TYPE: invitedFilter,
    };
    let searchApplied = false;
    if (keywords && keywords.length > 0) {
      searchString.SEARCH_STRING = keywords;
      searchApplied = true;
    } else {
      delete searchString.SEARCH_STRING;
    }
    if (!isNil(skills) && skills[0]) {
      searchString.SKILLS_TAGS_IDS = skills.map((skill) => skill.id);
      searchApplied = true;
    } else {
      delete searchString.SKILLS_TAGS_IDS;
    }
    if (!isNil(position) && position[0]) {
      searchString.POSITION_TAGS_IDS = position.map(
        (singlePosition) => singlePosition.id
      );
      searchApplied = true;
    } else {
      delete searchString.POSITION_TAGS_IDS;
    }
    if (!isNil(industries) && industries[0]) {
      searchString.INDUSTRY_TAGS_IDS = industries.map(
        (industry) => industry.id
      );
      searchApplied = true;
    } else {
      delete searchString.INDUSTRY_TAGS_IDS;
    }
    if (searchApplied) {
      searchString.CREATED_SORT = false;
    }
    setDoNotFetch(false);
    setQueryParameters(searchString);
    setSearchApplied(
      searchString.SEARCH_STRING ||
        searchString.INDUSTRY_TAGS_IDS ||
        searchString.SKILLS_TAGS_IDS ||
        searchString.POSITION_TAGS_IDS
    );
  }

  // function handleCloseInfo() {
  //   setOpenSearchInfo(false);
  // }

  // function openPopUpInfo() {
  //   setOpenSearchInfo(true);
  // }

  const contentRef = useRef(null);

  const scrollToInput = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 190,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {type === AccountType.COMPANY ? <AdvisorsTopBar boxRef={boxRef} /> : null}
      <div ref={contentRef} style={classes.container}>
        {type === AccountType.ADVISOR ? <AdvisorTopTiles /> : null}
        {type === AccountType.ADVISOR ? (
          <CompaniesFilter
            onSearchButtonClicked={handleSearch}
            disableSearchButton={disableSearchButton}
            hidePeerFilter
            scrollToInput={scrollToInput}
            isAdvisorSearch
          />
        ) : (
          <AdvisorFilter
            onSearchButtonClicked={handleSearch}
            disableSearchButton={disableSearchButton}
            hidePeerFilter
            showInvitedFilter={type === AccountType.COMPANY}
          />
        )}

        {searchApplied ? (
          <>
            <div style={classes.containerInfo}>
              <Typography variant="old_font">
                {t('SEARCH_ADVISORS_SEARCH_PROMPT')}
              </Typography>
              <ACToolTip
                title='Search includes results from advisors’ skill tags, biography, and professional experience. You can search for exact matches by placing phrases in quotes like "Digital Marketing". Click an advisor to view their profile and learn more.'
                placement="top"
              >
                <Info sx={classes.iconInfo} />
              </ACToolTip>
            </div>
            {/* <Dialog
              open={openSearchInfo}
              fullWidth
              maxWidth="xs"
              onClose={handleCloseInfo}
              aria-describedby="alert-dialog-slide-description"
            >
              <Typography sx={classes.messagePopUp} variant="old_font">
                {t('SEARCH_ADVISORS_SEARCH_INFORMATION')}
              </Typography>
            </Dialog> */}
          </>
        ) : null}
        <Treatment
          userId={currentUserInfo.username}
          splitNames={featureFlags.opensearch_migration}
          updateOnSdkTimedout
          evaluatedComponent={
            <SceneContentStateSelector
              helmetTitle={
                type === AccountType.ADVISOR
                  ? 'Network'
                  : t('MY_ADVISORS_HELMET_TITLE')
              }
              endPointAppSyncName={
                EndpointResponseObjectName.SEARCH_ADVISOR_BY_FILTERS
              }
              endPointCall={searchAdvisorByFilters}
              endPointCallParameters={queryParameters}
              ContentTag={CompanySearchAdvisorsContent}
              contentProps={{
                searchApplied,
              }}
              doNotShowEmptyStateScene
              doNotFetch={doNotFetch}
              toggleSearchButton={setDisableSearchButton}
              isCardSkeleton
              noMaxWidth
            />
          }
          conditionFlag
          conditionFailedComponent={
            <SceneContentStateSelector
              helmetTitle={
                type === AccountType.ADVISOR
                  ? 'Network'
                  : t('MY_ADVISORS_HELMET_TITLE')
              }
              endPointAppSyncName={
                EndpointResponseObjectName.SEARCH_ADVISOR_BY_FILTERS_LEGACY
              }
              endPointCall={searchAdvisorByFiltersLegacy}
              endPointCallParameters={queryParameters}
              ContentTag={CompanySearchAdvisorsContent}
              contentProps={{
                searchApplied,
              }}
              doNotShowEmptyStateScene
              doNotFetch={doNotFetch}
              toggleSearchButton={setDisableSearchButton}
              isCardSkeleton
              noMaxWidth
            />
          }
        />
      </div>
    </>
  );
}
export default SearchAdvisors;
