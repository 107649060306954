import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  Typography,
  Box,
  Card,
  Grid,
  Avatar,
  Button,
} from '@mui/material';
import { ReactComponent as OneOnOne } from '../../icons/1-1.svg';
import { ReactComponent as CalendarCheckIcon } from '../../icons/calendar-check.svg';
import { ReactComponent as BoardMembers } from '../../icons/boardMembers.svg';
import { ReactComponent as Insights } from '../../icons/insights.svg';
import ACSnackbar from '../../components/ACSnackbar';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../../contexts/Account';
import { companyFetchPostsCall } from '../../services/company_services';
import {
  AccountType,
  EndpointParameterFilters,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import DashboardCompanyContent from '../../components/Company/DashboardContent/index';

import EndpointCaller from '../../components/EndpointCaller';
import {
  CallTypes,
  useInfiniteScroll,
} from '../../components/Post/ResponseReply/useInfiniteScroll';
import {
  getIndexArrayValues,
  getValuesFromKeyInDataResponse,
  isNil,
} from '../../services/utils';
import { useCompanyExtraFeed } from '../../contexts/CompanyFeed';
import SearchBox from './searchBox';
import CompanyTiles from '../../components/TopTiles/company/CompanyTiles';
import CompanyTopTilesContainer from '../../components/Company/TopTiles';
import CompanyTopTilesTemporaryContainer from '../../components/Company/TopTilesTemporary';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';
import BoardroomHeader from '../v2/Boardroom/BoardroomHeader';
import eventBus, { eventBusValues } from '../../eventBus';
import BoardTile from '../v2/Boardroom/BoardTile';
import { getCompanyQueryParams } from '../../utils/queryParams';

function Dashboard(props) {
  const location = useLocation();
  const { showPostPopUp, showPostWrapperContent } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    asLoggedUserDetails,
    accountDetails,
    authenticate,
    isAuthenticated,
    isImpersonated,
    currentUserInfo,
  } = useAccount();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [insightCount, setInsightCount] = useState(0);
  const [legacyIdErrorSnackbarVisible, setLegacyIdErrorSnackbarVisible] =
    useState(false);
  const { state, removeAllPosts, addPost } = useCompanyExtraFeed();

  useEffect(() => {
    return () => {
      removeAllPosts();
    };
  }, []);
  const [filterBy, setFilterBy] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [companyEndpointCalls, setCompanyEndpointCalls] = useState([
    {
      endPointCall: companyFetchPostsCall,
      type: CallTypes.MAIN_CALL,
      endPointCallParameters: {
        ACCOUNT_TYPE: AccountType.CONTACT,
        ADVISOR_ID: '',
        COMPANY_ID: accountDetails.id,
        LIMIT: EndpointParameterFilters.FETCH_LIMIT,
        OFFSET: 0,
        ORDER_BY: getSort(),
        REVERSE: sortBy == 'oldest',
        TYPE: [
          'question',
          'update',
          'boardMeetings',
          'meeting',
          'insight',
        ].join(','),
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
      },
      endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
    },
  ]);

  const {
    posts,
    isMainCallFetching,
    setInitialPosts,
    incrementOffset,
    addInitialPosts,
    addPostLike,
  } = useInfiniteScroll({ endpointCalls: companyEndpointCalls });

  const organizePosts = (posts) => {
    return [...posts].sort((a, b) => {
      if (a.isPinned === b.isPinned) {
        return 0;
      }
      return a.isPinned ? -1 : 1;
    });
  };

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getCompanyQueryParams({ accountDetails })}`
    );
  };

  useEffect(() => {
    if (
      state.temporaryNewPost &&
      !posts.find((post) => post.id == state.temporaryNewPost.id)
    ) {
      addInitialPosts(state.temporaryNewPost);
    }
  }, [state.temporaryNewPost]);

  function GetEndpointResponseArray(array) {
    const post = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 0),
      'getPosts'
    );
    const insightCounts = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 1),
      'getInsights'
    );

    setInsightCount(isNil(insightCounts) ? 0 : insightCounts.length);
    setInitialPosts(isNil(post) ? [] : organizePosts(post));

    setLoading(false);

    if (location.state && location.state.newPost) {
      addPost(location.state.newPost);
    }
  }

  function getSort() {
    if (!sortBy || sortBy == 'newest') {
      return 'sortAt';
    }
    if (sortBy == 'oldest') {
      return 'createdAt';
    }
    if (sortBy == 'recent-activity') {
      return 'updatedAt';
    }
    if (sortBy == 'mostPopular') {
      return 'mostPopular';
    }
  }

  useEffect(() => {
    setLoading(true);
    setCompanyEndpointCalls([
      {
        endPointCall: companyFetchPostsCall,
        type: CallTypes.MAIN_CALL,
        endPointCallParameters: {
          ACCOUNT_TYPE: AccountType.CONTACT,
          ADVISOR_ID: '',
          COMPANY_ID: accountDetails.id,
          LIMIT: EndpointParameterFilters.FETCH_LIMIT,
          OFFSET: 0,
          ORDER_BY: getSort(),
          REVERSE: sortBy == 'oldest',
          TYPE:
            filterBy.length == 0
              ? [
                  'question',
                  'update',
                  'boardMeetings',
                  'meeting',
                  'insight',
                ].join(',')
              : filterBy.join(','),
          COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        },
        endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
      },
    ]);
  }, [filterBy, sortBy]);

  useEffect(() => {
    async function fetchUserData() {
      await authenticate();
    }

    if (
      !isAuthenticated &&
      !isImpersonated &&
      props.location.state &&
      props.location.state.onboarded
    ) {
      fetchUserData();
    }

    if (location.state && location.state.missingLegacyId) {
      setLegacyIdErrorSnackbarVisible(true);
    }
  }, []);

  function seeOpportunity(companyId) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId,
      isEdit: true,
    });
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>

      <Treatment
        userId={
          currentUserInfo && currentUserInfo.username
            ? currentUserInfo.username
            : 'not_id'
        }
        splitNames={featureFlags.b2b_redesign}
        updateOnSdkTimedout
        evaluatedComponent={
          <Box
            sx={{
              backgroundColor: '#F4F8FF',
              height: '100%',
              paddingTop: '30px',
              paddingLeft: isSM ? '10px' : '30px',
              paddingRight: isSM ? '10px' : '30px',
            }}
          >
            <BoardroomHeader
              company={accountDetails}
              setIsLoading={setLoading}
              seeOpportunity={seeOpportunity}
            />
            <Grid container spacing={2}>
              <BoardTile
                image={<Insights style={{ width: 60, height: 60 }} />}
                onClick={() => history.push('/advisor_ideas')}
              >
                <Typography
                  sx={{
                    fontSize: { xxs: 16, xs: 20 },
                    lineHeight: { xxs: '20px', xs: '26px' },
                    fontWeight: '600',
                    marginBottom: { xxs: '7px', xs: '14px' },
                    textAlign: 'left',
                  }}
                >
                  Insights From Your Advisors
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#4D586F',
                    textAlign: 'left',
                  }}
                >
                  See ideas and recommendations from your advisors on your
                  challenges and goals.
                </Typography>
              </BoardTile>
              <BoardTile
                image={<BoardMembers style={{ width: 60, height: 60 }} />}
                onClick={() => history.push('/advisors')}
              >
                <Typography
                  sx={{
                    fontSize: { xxs: 16, xs: 20 },
                    lineHeight: { xxs: '20px', xs: '26px' },
                    fontWeight: '600',
                    marginBottom: { xxs: '7px', xs: '14px' },
                    textAlign: 'left',
                  }}
                >
                  Advisory Board Members
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#4D586F',
                    textAlign: 'left',
                  }}
                >
                  View your current board members, invite more board members and
                  more.
                </Typography>
              </BoardTile>
              <BoardTile
                image={<CalendarCheckIcon style={{ width: 60, height: 60 }} />}
                onClick={() => history.push('/board-meetings')}
              >
                <Typography
                  sx={{
                    fontSize: { xxs: 16, xs: 20 },
                    lineHeight: { xxs: '20px', xs: '26px' },
                    fontWeight: '600',
                    marginBottom: { xxs: '7px', xs: '14px' },
                    textAlign: 'left',
                  }}
                >
                  Board Meetings
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#4D586F',
                    textAlign: 'left',
                  }}
                >
                  Schedule a board meeting, view previous board meetings and
                  more.
                </Typography>
              </BoardTile>
              <BoardTile
                image={<OneOnOne style={{ width: 60, height: 60 }} />}
                onClick={() =>
                  redirectWithParams(
                    'https://register.advisorycloud.com/bab/personal-assist/'
                  )
                }
              >
                <Typography
                  sx={{
                    fontSize: { xxs: 16, xs: 20 },
                    lineHeight: { xxs: '20px', xs: '26px' },
                    fontWeight: '600',
                    marginBottom: { xxs: '7px', xs: '14px' },
                    textAlign: 'left',
                  }}
                >
                  Get 1:1 Input From Board Members
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#4D586F',
                    textAlign: 'left',
                  }}
                >
                  Invite individual advisors for a 1:1 meeting to get more
                  detailed insights.
                </Typography>
              </BoardTile>
            </Grid>
          </Box>
        }
        conditionFlag
        conditionFailedComponent={
          <>
            <div
              style={{
                paddingLeft: isSM ? '10px' : '0px',
                paddingTop: '30px',
                paddingRight: isSM ? '10px' : '30px',
                display: 'flex',
                flexDirection: 'column',
                flex: 'auto',
                // maxWidth: '1260px',
              }}
            >
              <div
                style={{
                  paddingLeft: isSM ? '10px' : '30px',
                  paddingRight: isSM ? '10px' : '0px',
                  maxWidth: '1260px',
                }}
              >
                <CompanyTopTilesTemporaryContainer setFilterBy={setFilterBy} />
              </div>

              {!loading ? (
                <div
                  style={{
                    marginLeft: isSM ? '-6px' : '0px',
                    marginRight: isSM ? '-6px' : '-30px',
                    backgroundColor: '#FBFCFD',
                    // borderTop: '1px solid #E6EDFF',
                    borderRight: '1px solid #E6EDFF',
                    borderBottom: '1px solid #E6EDFF',
                    borderLeft: '0px solid #E6EDFF',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '1290px',
                      paddingRight: isSM ? '10px' : '30px',
                      paddingLeft: isSM ? '10px' : '30px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#232B35',
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '24px',
                        letterSpacing: '0.75px',
                        marginBottom: '-15px',
                        zIndex: 1,
                      }}
                    >
                      Insights from your advisors:
                    </Typography>
                    <DashboardCompanyContent
                      showPostPopUp={showPostPopUp}
                      showPostWrapperContent={showPostWrapperContent}
                      postsList={posts}
                      insightCount={insightCount}
                      isMainCallFetching={isMainCallFetching}
                      incrementOffset={incrementOffset}
                      addPostLike={addPostLike}
                      filterBy={filterBy}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    maxWidth: '1290px',
                    paddingTop: isSM ? '10px' : '30px',
                    paddingRight: isSM ? '10px' : '30px',
                    paddingLeft: isSM ? '10px' : '30px',
                  }}
                >
                  <DashboardSkeleton />
                </div>
              )}
              <EndpointCaller
                endPointCallObjectArray={companyEndpointCalls}
                onEndpointCallsFinished={GetEndpointResponseArray}
                executeCalls={loading}
                isDashboardSkeleton
              />
            </div>

            <ACSnackbar
              open={legacyIdErrorSnackbarVisible}
              text={t('DASHBOARD-IWM-ID-NOT-PRESENT-ERROR')}
              severity="error"
              onClose={() => setLegacyIdErrorSnackbarVisible(false)}
              autoHideDuration={5000}
            />
          </>
        }
      />
    </>
  );
}

export default Dashboard;
