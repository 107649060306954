import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Avatar, Typography, useMediaQuery } from '@mui/material';
import { ArrowForwardIosOutlined, EditOutlined } from '@mui/icons-material';
import CustomIcon from './../../../icons/customIcon';
import ACSnackbar from './../../ACSnackbar';
import DefaultCompany from './../../../icons/building_round.svg';
import { useAccount } from './../../../contexts/Account';
import BoardJoinButton from './../../BoardJoinButton';
import {
  getIsFavoriteCompany,
  saveCompany,
} from './../../../services/advisor_services';
import { AccountType } from './../../../services/utils/types';
import eventBus, { eventBusValues } from './../../../eventBus';
import { ReactComponent as LinkIcon } from './link.svg';
import { ReactComponent as LaunchIcon } from './../../Advisor/CardPublicSearch/launch.svg';
import dateFormat from './../../../utils/dateFormat';
import { isNil } from '../../../services/utils';
import { formatBoardJoinedDate, isDateExpired } from '../../../services/date';
import PostBoardroomIdea from '../../Advisor/PostBoardroomIdea/PostBoardroomIdea';

const useStyles = ({ isSM, isOwnProfile }) => ({
  card: {
    width: '100%',
    paddingTop: '20px',
  },
  publicProfileHeaderContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    width: '100%',
    paddingBottom: '0px',
    paddingRight: isSM ? '0px' : '20px',
    paddingTop: '0px',
    alignItems: isSM ? 'unset' : 'center',
    justifyContent: 'space-between',
  },
  slideRigthArrow: {
    cursor: 'pointer',
    color: '#3171F6',
    marginBottom: isSM ? '15px' : '20px',
    marginLeft: isSM ? '40px' : '0px',
    marginTop: isSM ? '20px' : '0px',
  },
  mainInformationContainer: {
    paddingTop: isOwnProfile ? '0' : '40px',
  },
  title: {
    fontWeight: 600,
    fontSize: isSM ? '20px' : '36px',
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: '22px',
    fontFamily: 'Poppins',
    lineHeight: 1.2,
  },
  headline: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  subHeadline: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#232B35',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageAndBiographyContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    width: isSM ? '180px' : '150px',
    height: isSM ? '180px' : '150px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    border: 'solid 2px white',
  },
  imageContainerOwnProfile: {
    width: isSM ? '180px' : '215px',
    height: isSM ? '180px' : '215px',
    margin: isSM ? 'auto' : '0 20px 10px 0',
    border: 'solid 2px white',
  },
  biographyInformationContainer: {
    display: 'flex',
    marginLeft: '20px',
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  profileOptionDrawerContainer: {
    // position: 'fixed',
    // right: '1.5vw',
    // top: '45px',
    // display: 'flex',
  },
  profileOptionDrawerContainerWithBar: {
    position: 'absolute',
    right: '1.5vw',
    display: 'flex',
  },
  mobileImageContainer: {
    width: '100%',
    marginTop: '15px',
  },
  buttonContainer: {
    width: '100%',
    marginTop: '15px',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '-2px',
    left: '0px',
    zIndex: 10,
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    padding: '0px',
    marginRight: '5px',
  },
  editIcon: {
    fontSize: '20px',
    marginRight: '10px',
    color: '#323b35',
  },
  editText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#323b35',
  },
});

function CompanySlideHeader({
  launchConfetti,
  featureFlag,
  invitation,
  company,
  handleClickAway,
  showUpperBar,
  companyCEO,
  p2pFlag,
  isFreemiumCompany,
  isOwnProfile,
  isAcPlusCompany,
  isJoined,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles({ isSM, isOwnProfile });
  const { tags, accountDetails, type, isFreemium } = useAccount();
  const [isFavorite, setIsFavorite] = useState(false);
  const [allowStarClick, setAllowStarClick] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [industriesDataMap] = tags.industries
    ? useState(
      tags.industries.reduce(
        (currentObject, industry) => ({
          ...currentObject,
          [industry.id]: industry.label,
        }),
        {}
      )
    )
    : [];

  function getHeadlineText() {
    if (p2pFlag) {
      return t('MODERATE-ADVISORY');
    }
    if (company.displayIndustry && company.displayIndustry.label) {
      return company.displayIndustry.label;
    }
    if (company.industryTagIds && company.industryTagIds.length) {
      return industriesDataMap[company.industryTagIds[0]];
    }
    return '';
  }

  function getSizeText() {
    if (p2pFlag) {
      if (company.countAdvisors && company.countAdvisors.length) {
        return t('P2P-SIZE-LABEL', {
          size: company.countAdvisors,
        });
      } else {
        return '';
      }
    }
    if (
      getUpcomingBoardMeetingValue(company.upcomingBoardMeeting, false) &&
      invitation.status === 'pending'
    ) {
      return `Upcoming board meeting on: ${formatBoardJoinedDate(
        new Date(company.upcomingBoardMeeting)
      )}`;
    }
    if (
      company.companySize &&
      company.companySize !== '2 - 10' &&
      company.companySize !== '1'
    ) {
      return t('COMPANY-SIZE-LABEL', {
        companySize: company.companySize,
      });
    }
    return '';
  }

  function getUpcomingBoardMeetingValue(
    upcomingBoardMeeting,
    returnString = false
  ) {
    if (!isNil(upcomingBoardMeeting)) {
      if (!isDateExpired(upcomingBoardMeeting)) {
        if (returnString) {
          const meetingDate = new Date(upcomingBoardMeeting);
          const meetingText = `Join us for our next Board Meeting coming up on ${formatBoardJoinedDate(
            meetingDate
          )}. New advisors welcome!`;
          return meetingText;
        }
        return true;
      }
    }
    return false;
  }

  function starClick() {
    if (allowStarClick) {
      setAllowStarClick(false);
      eventBus.dispatch(
        `${eventBusValues.onCompanyStarClick}_${company.id}`,
        !isFavorite
      );
      setIsFavorite((prevValue) => !prevValue);
      saveCompany({
        COMPANY_ID: company.id,
        ADVISOR_ID: accountDetails.id,
      })
        .catch((error) => {
          setIsFavorite((prevValue) => !prevValue);
          console.log(error);
        })
        .finally(() => {
          setAllowStarClick(true);
        });
    }
  }

  async function closeSlideHeader() {
    handleClickAway();
  }

  function editOwnClick() {
    closeSlideHeader();
    history.push('/account?edit=true');
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`
    );
    setSuccessMessage(
      t('Link copied, you can now share it with whoever you choose')
    );
    setSuccessSnackbar(true);
  };

  function handleOpenInNewIconClick() {
    window.open(
      `${window.location.origin}/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
      '_blank'
    );
  }

  function getImageSrc() {
    if (company.image && company.image.id) {
      return `https://${company.image.location}`;
    } else if (isAcPlusCompany && companyCEO && companyCEO.contactLogoId) {
      return companyCEO.contactLogoLocation;
    }
    return DefaultCompany;
  }

  useEffect(() => {
    let isMounted = true;
    getIsFavoriteCompany({
      COMPANY_ID: company.id,
      ADVISOR_ID: accountDetails ? accountDetails.id : '',
    })
      .then(({ data }) => {
        if (isMounted && data && data.getIsFavoriteCompany) {
          setIsFavorite(data.getIsFavoriteCompany.exists);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (isMounted) {
          setAllowStarClick(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div style={classes.card}>
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      {!isSM ? (
        <>
          <div style={classes.publicProfileHeaderContainer}>
            <ArrowForwardIosOutlined
              sx={classes.slideRigthArrow}
              fontSize="small"
              onClick={closeSlideHeader}
            />
            <div
              style={
                !showUpperBar && !isAcPlusCompany
                  ? classes.profileOptionDrawerContainer
                  : classes.profileOptionDrawerContainerWithBar
              }
            >
              {type === AccountType.COMPANY &&
                company.id === accountDetails.id ? (
                <button
                  onClick={editOwnClick}
                  type="submit"
                  style={{
                    ...classes.editContainer,
                  }}
                >
                  <EditOutlined color="primary" sx={classes.editIcon} />
                  <Typography color="primary" sx={classes.editText}>
                    {t('EDIT-BUTTON-TEXT')}
                  </Typography>
                </button>
              ) : null}
              {type === AccountType.ADVISOR && !isFreemiumCompany ? (
                <div style={{ display: 'flex' }}>
                  <CustomIcon
                    iconname={isFavorite ? 'star-filled' : 'star-border'}
                    onClick={starClick}
                    style={{
                      display: 'flex',
                      marginTop: isFavorite ? '5px' : '6px',
                      marginRight: "10px",
                      cursor: 'pointer',
                      color: '#6736FA',
                    }}
                  />
                  {isJoined ?
                    <PostBoardroomIdea company={company} buttonStyles={{}} /> :
                    <BoardJoinButton
                      launchConfetti={launchConfetti}
                      featureFlag={featureFlag}
                      invitation={invitation}
                      boardId={company.id}
                      company={company}
                      companyCEO={companyCEO}
                      staticClass="joinButtonTop"
                    />}
                </div>
              ) : null}
            </div>
          </div>
          {!invitation.bulk && invitation.status === 'pending' ? (
            <Typography
              variant="body1"
              sx={{
                textAlign: 'end',
                letterSpacing: '-0.43px',
                fontWeight: 500,
                lineHeight: '24px',
                font: 'Poppins',
                marginRight: '2%',
              }}
            >
              Accepting this invitation will not count against your plan limits!
            </Typography>
          ) : null}

          <div style={classes.mainInformationContainer}>
            <div style={classes.row}>
              <div style={classes.imageAndBiographyContainer}>
                <Avatar
                  sx={
                    !isOwnProfile
                      ? classes.imageContainer
                      : classes.imageContainerOwnProfile
                  }
                  src={getImageSrc()}
                />
                <div style={classes.biographyInformationContainer}>
                  <Typography sx={classes.title}>
                    {company.displayName}
                  </Typography>
                  {isAcPlusCompany ? (
                    <Typography sx={{ ...classes.title, paddingTop: '0px' }}>
                      AdvisoryCloud
                    </Typography>
                  ) : null}
                  {!isOwnProfile ? (
                    <>
                      <Typography sx={classes.headline}>
                        {getHeadlineText()}
                        &nbsp; &nbsp;
                        {getSizeText()}
                      </Typography>
                      <Typography sx={classes.subHeadline}>
                        {company.headline || ''}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '400px',
                        }}
                      >
                        <Box
                          onClick={handleCopyLink}
                          sx={{
                            width: '50%',
                            border: '1px solid #EFF0F6',
                            backgroundColor: '#FFFFFF',
                            padding: '6px',
                            paddingTop: '8px',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            height: '35px',
                            marginTop: '2px',
                            marginBottom: '5px',
                            flexGrow: 1,
                          }}
                        >
                          {accountDetails && accountDetails.slug ? (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                                fontWeight: 400,
                                letterSpacing: '-0.43px',
                              }}
                            >
                              https://app.advisorycloud.com/join/advisors/
                              {accountDetails.slug}/{accountDetails.id}
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Box>
                        <Box sx={{ marginLeft: '12px', cursor: 'pointer' }}>
                          <LinkIcon onClick={handleCopyLink} />
                        </Box>
                        <Box sx={{ marginLeft: '12px', cursor: 'pointer' }}>
                          <LaunchIcon onClick={handleOpenInNewIconClick} />
                        </Box>
                      </Box>
                      <Typography
                        sx={{ ...classes.headline, marginLeft: '3px' }}
                      >
                        Joined {dateFormat(company.createdAt, 'mmmm d, yyyy')}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={classes.publicProfileHeaderContainer}>
          <ArrowForwardIosOutlined
            sx={classes.slideRigthArrow}
            fontSize="small"
            onClick={closeSlideHeader}
          />

          <Box sx={classes.mobileImageContainer} display="flex">
            <Avatar sx={classes.imageContainer} src={getImageSrc()} />
          </Box>
          <Typography sx={classes.title}>{company.displayName}</Typography>

          <Typography sx={classes.headline}>
            {company.industryTagIds && company.industryTagIds.length
              ? industriesDataMap[company.industryTagIds[0]]
              : ''}
          </Typography>
          {company.companySize && company.companySize !== '2 - 10' ? (
            <Typography sx={classes.headline}>
              {t('COMPANY-SIZE-LABEL', {
                companySize: company.companySize,
              })}
            </Typography>
          ) : null}
          {!invitation.bulk && invitation.status === 'pending' ? (
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                letterSpacing: '-0.43px',
                fontWeight: 500,
                lineHeight: '24px',
                font: 'Poppins',
                marginLeft: '10%',
                marginRight: '10%',
              }}
            >
              Accepting this invitation will not count against your plan limits!
            </Typography>
          ) : null}
          <Box sx={classes.buttonContainer} display="flex">
            {type === AccountType.ADVISOR ? (
              <>
                {
                  isJoined ?
                    <PostBoardroomIdea company={company} buttonStyles={{ marginBottom: "20px" }} />
                    :
                    <BoardJoinButton
                      invitation={invitation}
                      boardId={company.id}
                      company={company}
                      companyCEO={companyCEO}
                      staticClass="joinButtonTop"
                    />
                }
              </>
            ) : null}
          </Box>
        </div>
      )}
    </div>
  );
}

export default CompanySlideHeader;
