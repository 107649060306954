import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  useMediaQuery,
  Avatar,
  IconButton,
  Backdrop,
} from '@mui/material';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import PostPopUp from './../../CompanyPostPopUp';
import DefaultAdvisorAvatar from '../../../icons/user-avatar.svg';
import NewCompanyMemberConfirmationModal from './../../AccountSettings/NewMemberConfirmationModal';
import UpgradePlanModal from './../../UpgradePlanModal';
import NewCompanyMemberModal from './../NewContactModal';
import { Clear, PersonAddAlt, Menu } from '@mui/icons-material';
import PostTypeSelector from './PostTypeSelector';
import HoverMenuItem from '../../v2/HoverMenuItem';
import AdvisoryCloudLogo from '../../../advisorComponents/AdvisoryCloudLogo';
import DesktopMenu from './Menu';
import MobileMenu from '../../../layouts/MobileMenu';
import { useCompanyExtraFeed } from './../../../contexts/CompanyFeed';
import eventBus, { eventBusValues } from './../../../eventBus';
import { getCompanyQueryParams } from '../../../utils/queryParams';

const useStyles = ({ isSM, isXS, isWrapped, topvalue }) => ({
  bContainerRedesign: {
    width: '100%',
    height: '60px',
    top: topvalue,
    backgroundColor: '#fff',
    borderRadius: '0px',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: { xxs: '10px 3px 10px 5px', sm: '10px 25px' },
    zIndex: 2,
    borderBottom: '1px solid #ddd',
  },
  buttonsContainer: {
    display: { xxs: 'none', md: 'flex' },
    height: '75px',
    flexDirection: 'row',
    justifyContent: isWrapped ? 'left' : '',
    alignItems: 'center',
    marginLeft: '20px',
    gap: '15px',
  },
  dialogTitle: {
    paddingBottom: '10px',
    paddingTop: '40px',
    paddingLeft: isSM ? '25px' : '50px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: !isSM ? '24px' : '18px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  dialogContent: {
    paddingTop: '0px',
    padding: isSM ? '0' : 'auto',
  },
  clearIconContainer: {
    position: !isXS ? 'absolute' : 'inherit',
    height: isXS ? '30px' : 'auto',
    cursor: 'pointer',
    right: '40px',
    top: '20px',
    fontSize: '13px',
    color: '#97A3B7',
  },
  dialogContainer: {
    borderRadius: isSM ? '0px' : '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    width: isSM ? '100%' : '770px',
    maxWidth: isSM ? '100%' : '770px',
    height: isSM ? '100%' : '490px',
    maxHeight: isSM ? '100%' : '490px',
    overflow: 'hidden',
  },
  actionsButton: {
    textTransform: 'none',
    height: '40px',
    borderRadius: '50px',
    width: '142px',
    margin: '5px',
    flexGrow: 1,
    border: '2px solid #0F78FD',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#3171F6',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '2px solid #0F78FD',
    },
  },
  clearIcon: {
    margin: '-75px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
    alignSelf: 'end',
  },
  backDrop: {
    zIndex: 0,
    backgroundColor: 'transparent',
  },
});

function CompanyUpperBar() {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [newPost, setNewPost] = useState();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([500]));
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1304]));

  const {
    accountDetails,
    isImpersonated,
    isFreeTrial,
    isFreemium,
    currentPlan,
  } = useAccount();
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    !isFreeTrial &&
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const classes = useStyles({
    isSM,
    isWrapped,
    isXS,
    topvalue: getTopValue(),
  });
  const [inputText, setInputText] = useState('');
  const [
    isNewMemberConfirmationModalVisible,
    setIsNewMemberConfirmationModalVisible,
  ] = useState(false);
  const [errorSnackbarText, setErrorSnackbarText] = useState(
    t('COMPANY-MEMBERS-GENERIC-ERROR')
  );
  const [invitedEmail, setInvitedEmail] = useState('');
  const [isNewCompanyMemberModalVisible, setIsNewCompanyMemberModalVisible] =
    useState(false);
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const [openQuestionPopUp, setOpenQuestionPopUp] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [postHasContent, setPostHasContent] = useState(false);
  const [postType, setPostType] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const companyExtraFeed = useCompanyExtraFeed();
  const navBarRef = useRef(null);
  const addPost = companyExtraFeed ? companyExtraFeed.addPost : undefined;
  const title =
    accountDetails.userSegment === 'managed_advisory_board'
      ? 'Post in your advisory board'
      : 'Post in your AdvisoryCloud';

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getCompanyQueryParams({ accountDetails })}`
    );
  };

  const menuItemStyle = {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '500',
    padding: '5px 18px',
    borderRadius: '8px',
  };

  const navBarItems = [
    {
      label: 'Boardroom',
      to: '/dashboard',
      id: 'b2bNavBarBoardroom',
    },
    {
      label: 'Insights',
      to: '/advisor_ideas',
      id: 'b2bNavBarInsights',
    },
    {
      label: 'Advisors',
      to: '/advisors',
      id: 'b2bNavBarAdvisors',
    },
    {
      label: 'Board Meetings',
      to: '/board-meetings',
      id: 'b2bNavBarBoardMeetings',
    },
    {
      label: 'FAQs',
      onClick: () =>
        redirectWithParams(
          'https://register.advisorycloud.com/faqs-babs-platform'
        ),
      id: 'b2bNavBarFAQs',
    },
  ];

  function handleNewCompanyMemberSubmit(data) {
    setIsNewCompanyMemberModalVisible(false);
    setInvitedEmail(data.email);
    setIsNewMemberConfirmationModalVisible(true);
  }

  function handleNewCompanyMemberSubmitError(data) {
    if (data.errors && data.errors.length) {
      if (data.errors[0].message) {
        const error = JSON.parse(data.errors[0].message);
        switch (error.type) {
          case 'Active':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-ACTIVED'));
            break;
          case 'Invited':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-INVITED'));
            break;
          case 'Deleted':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-DELETED'));
            break;
          default:
            setErrorSnackbarText(t('COMPANY-MEMBERS-GENERIC-ERROR'));
            break;
        }
      }
    }
    setIsErrorSnackbarVisible(true);
  }

  function handleAddCompanyContact() {
    if (isFreeTrial && !isFreemium) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else if (accountDetails.boardSeatsAvailable === 0) {
      setUpgradePlanModal(true);
    } else {
      setIsNewCompanyMemberModalVisible(true);
    }
  }

  function getTopValue() {
    let top = 0;
    if (isImpersonated) {
      top += 56;
    }
    if (isFreeTrial && !isFreemium) {
      top += 50;
    }
    if (showExFremiumBanner || showOnboardingBanner) {
      top += 50;
    }
    return `${top}px`;
  }

  function onAvatarMenuIconCLick() {
    eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
  }

  function handleClickQuestion(postType) {
    setPostType(postType || 'question');
    setOpenQuestionPopUp(!openQuestionPopUp);
  }

  function localAddPost(post) {
    setInputText('');
    if (addPost && location.pathname === '/dashboard') {
      addPost(post);
    }
    setNewPost(post);
    setSuccessSnackbar(true);
    setSuccessMessage(`Your ${postType} has been posted to your boardroom.`);
  }

  function localAddPostAfter() {
    setSuccessSnackbar(false);

    if (location.pathname !== '/dashboard') {
      history.push('/dashboard', { newPost });
    }
  }

  useEffect(() => {
    if (isFreemium) {
      const queryParams = new URLSearchParams(window.location.search);
      const viewUpgrade = queryParams.get('viewUpgrade');
      if (viewUpgrade === 'true') {
        eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
          action: () => {},
        });
      }
    }

    eventBus.on(eventBusValues.openCreatePost, handleClickQuestion);

    return () => {
      eventBus.remove(eventBusValues.openCreatePost);
    };
  }, []);

  function renderMenu() {
    if (isMenuVisible && !isSM) {
      return (
        <Backdrop
          onClick={() => setIsMenuVisible(false)}
          sx={classes.backDrop}
          open={true}
        >
          <DesktopMenu isV2 navBarRef={navBarRef} />
        </Backdrop>
      );
    }

    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          zIndex: 1200,
          width: '100%',
          backgroundColor: '#fff',
          overscrollBehavior: 'contain',
        }}
      >
        <NewCompanyMemberModal
          visible={isNewCompanyMemberModalVisible}
          onDismiss={() => setIsNewCompanyMemberModalVisible(false)}
          onInvitationSend={handleNewCompanyMemberSubmit}
          onInvitationSendError={handleNewCompanyMemberSubmitError}
          companyId={accountDetails.id}
        />
        <NewCompanyMemberConfirmationModal
          email={invitedEmail}
          onDismiss={() => {
            setIsNewMemberConfirmationModalVisible(false);
            setInvitedEmail(null);
          }}
          visible={isNewMemberConfirmationModalVisible}
        />
        {accountDetails.boardSeatsAvailable === 0 ? (
          <>
            <UpgradePlanModal
              upgradePlanModal={upgradePlanModal}
              setUpgradePlanModal={setUpgradePlanModal}
            />
          </>
        ) : null}
        <Box sx={classes.bContainerRedesign} ref={navBarRef}>
          <div
            style={{
              marginLeft: '30px',
            }}
          >
            <AdvisoryCloudLogo
              removeHyperlink
              height="27px"
              onClick={() => {
                if (location.pathname !== '/dashboard') {
                  history.push('/dashboard');
                }
              }}
            />
          </div>
          <Box
            sx={{
              display: 'none',
              '@media (min-width: 914px)': {
                display: 'flex',
              },
              alignItems: 'center',
            }}
          >
            {navBarItems.map((item, index) => {
              if (item.options) {
                return (
                  <HoverMenuItem
                    key={index}
                    label={item.label}
                    id={item.id}
                    options={item.options}
                    paths={item.paths}
                    menuItemStyle={menuItemStyle}
                    closeOnClick={item.closeOnClick}
                    containerWidth={item.containerWidth}
                    leftOptionsPosition={item.leftOptionsPosition}
                    mainOnClick={item.mainOnClick}
                  />
                );
              }
              const isActive = pathname === item.to;
              if (item.onClick) {
                return (
                  <Button
                    id={item.id}
                    key={index}
                    onClick={item.onClick}
                    sx={{
                      ...menuItemStyle,
                      color: isActive ? '#2B71F6' : '#2E3B56',
                      backgroundColor: isActive ? '#F4F8FF' : 'transparent',
                      cursor: 'pointer',
                      background: 'none',
                      border: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    {item.label}
                  </Button>
                );
              }

              return (
                <NavLink
                  id={item.id}
                  key={index}
                  to={item.to || '#'}
                  style={{
                    ...menuItemStyle,
                    color: isActive ? '#2B71F6' : '#2E3B56',
                    backgroundColor: isActive ? '#F4F8FF' : 'transparent',
                  }}
                >
                  {item.label}
                </NavLink>
              );
            })}
          </Box>
          <Box sx={classes.buttonsContainer}>
            <Button
              id="company-upper-bar-invite-button"
              variant="outlined"
              startIcon={<PersonAddAlt />}
              onClick={() => {
                // handleAddCompanyContact(); // probably?
                eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                  company: accountDetails,
                });
              }}
              sx={{
                display: { xxs: 'none', md: 'flex' },
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '600',
                padding: '0px 24px',
                borderRadius: '4px',
                borderColor: '#2B71F6',
                color: '#2B71F6',
                height: 32,
                '&:hover': {
                  borderColor: '#1e3a8a',
                  backgroundColor: '#f1f5ff',
                },
              }}
            >
              Invite
            </Button>

            <Avatar
              src={
                accountDetails.image?.id
                  ? `${process.env.REACT_APP_IMAGES_URL}${accountDetails.image.id}`
                  : DefaultAdvisorAvatar
              }
              alt={accountDetails.displayName}
              sx={{
                width: 32,
                height: 32,
                cursor: 'pointer',
                display: { xxs: 'none', md: 'block' },
              }}
              onClick={() => {
                if (isSM) {
                  onAvatarMenuIconCLick();
                } else {
                  setIsMenuVisible((prevValue) => !prevValue);
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xxs: 'flex', md: 'none' },
              flexDirection: 'row',
            }}
          >
            <IconButton
              sx={{
                display: 'flex',
                color: '#2563eb',
              }}
              onClick={() => {
                // handleAddCompanyContact(); // probably?
                eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                  company: accountDetails,
                });
              }}
            >
              <PersonAddAlt />
            </IconButton>
            <IconButton
              id="menu-icon-button"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xxs: 'block', md: 'none' },
                color: '#2563eb',
              }}
            >
              <Menu />
            </IconButton>
          </Box>

          <MobileMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            menuItems={navBarItems}
            pathname={pathname}
          />
          {renderMenu()}
        </Box>
      </Box>
      <Dialog
        open={openQuestionPopUp}
        onClose={() => {
          if (postHasContent) {
            setOpenConfirmationDialog(true);
          } else {
            setOpenQuestionPopUp(false);
          }
        }}
        aria-labelledby="post-popUp-dialog"
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        fullScreen={isSM}
      >
        <DialogTitle id="post-popUp-dialog-title" sx={classes.dialogTitle}>
          {title}
          <div style={classes.clearIconContainer}>
            <PostTypeSelector
              setPostType={setPostType}
              currentPostType={postType}
            />
          </div>
          {isSM ? (
            <div style={classes.clearIcon}>
              <Clear
                onClick={() => {
                  if (postHasContent) {
                    setOpenConfirmationDialog(true);
                  } else {
                    setOpenQuestionPopUp(false);
                  }
                }}
              />
            </div>
          ) : null}
        </DialogTitle>
        <DialogContent sx={classes.dialogContent}>
          <PostPopUp
            onChange={() => setOpenQuestionPopUp(false)}
            type={postType}
            callbackAfterPost={(post) => localAddPost(post)}
            editingPost={{ body: inputText }}
            setPostHasContent={setPostHasContent}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmationDialog}
        maxWidth="sm"
        onClose={() => {
          setOpenConfirmationDialog(false);
        }}
      >
        <DialogContent sx={{ padding: '25px 30px 15px' }}>
          <DialogContentText sx={classes.dialogText}>
            Are you sure you want to close without posting your {postType}?
          </DialogContentText>
          <DialogActions sx={{ marginTop: '15px' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                eventBus.dispatch(eventBusValues.closeCreatePost, {
                  callback: () => setOpenQuestionPopUp(false),
                });
                setOpenConfirmationDialog(false);
                setOpenQuestionPopUp(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              Discard Draft
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                setOpenConfirmationDialog(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              Keep Editing
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ACSnackbar
        id="company-member-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={errorSnackbarText}
      />
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => localAddPostAfter()}
        autoHideDuration={2000}
      />
    </>
  );
}

export default CompanyUpperBar;
