
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const useStyles = () => ({
  videoPreview: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0px',
    width: '100%',
    overflow: 'hidden',
    marginTop: 10,
  },
  videoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  grayOverlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    height: '100%',
    opacity: '0.7',
    backgroundColor: 'black',
  },
  playButton: {
    width: '300px',
    opacity: 1,
  },
  LockedVideoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  stickyVideoPreview: {
    position: 'fixed',
    width: '100%',
    height: "30%",
    top: '7rem',
    left: '0px',
    zIndex: 9999,
  }
});

function BoardMeetingVideo({ postData, timeLive, isSingleBoardMeetingPost, boardMeetingLocked, renderVideoGate, setIsSticky = () => { } }) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [sticky, setSticky] = useState(false);
  const videoRef = useRef(null);


  useEffect(() => {
    if (isSM) {
      window.addEventListener('scroll', onScroll, { passive: true });
    }
    return () =>
      window.removeEventListener('scroll', onScroll, { passive: true });
  }, [isSM]);

  function onScroll() {
    if (videoRef?.current?.getBoundingClientRect) {
      setSticky(videoRef.current.getBoundingClientRect()?.top < 120);
      setIsSticky(videoRef.current.getBoundingClientRect()?.top < 120);
    }
  }

  function getEmbebVideoUrl(url) {
    if (!url) {
      return;
    }

    let regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let match = url.match(regExp);
    if (match && match[1].length === 11) {
      return `https://www.youtube.com/embed/${match[1]}?autoplay=0`;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      return `https://player.vimeo.com/video/${match[3]}?autoplay=0`;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:event\/(?:\w+\/)?|event\/([^\/]*)\/event\/|event\/(\d+)\/event\/|event\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      return `https://vimeo.com/event/${match[3]}/embed?autoplay=0`;
    }

    return;
  }

  return (
    <>
      {sticky && <div id='please-work' style={{ height: videoRef?.current?.offsetHeight || 0, visibility: "hidden" }} />}
      <div
        style={{
          ...(sticky ? classes.stickyVideoPreview : classes.videoPreview),
          position: sticky ? "fixed" : "relative",
          zIndex: sticky ? 9999 : 0,
        }}
      >
        {timeLive && !isSingleBoardMeetingPost && (
          <div style={classes.grayOverlay}>
            <PlayCircleOutlineIcon sx={classes.playButton} />
          </div>
        )}
        <iframe
          ref={videoRef}
          src={getEmbebVideoUrl(postData.streamLink)}
          title="postVideo"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={
            boardMeetingLocked
              ? classes.LockedVideoFrame
              : classes.videoFrame
          }
        />
        {boardMeetingLocked ? renderVideoGate() : null}
      </div>
    </>

  );
}

export default BoardMeetingVideo;