import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import AdvisorUpperBar from '../../components/Advisor/UpperBar';
import BoardroomAdvisorView from './Boardroom';
import AdvisorRightMenuSplit from '../../components/Advisor/RightMenu/indexSplit';
import { useAccount } from '../../contexts/Account';
import AdvisorBoardroomCompanyQuestions from '../../scenes/Boardrooms/Questions';
import AdvisorBoardroomCompanyUpdates from '../../scenes/Boardrooms/CompanyUpdates';
import AdvisorBoardroomsLeftMenu from '../../components/Advisor/BoardroomsLeftMenu/';
import Opportunities from '../../scenes/Opportunities';
import FreeTrialBanner from '../../components/FreeTrialBanner';
import Boardroom from '../../scenes/Boardrooms/boardroom';
import AdvisorDashboard from '../../scenes/AdvisorDashboard';
import SearchCompanies from '../../scenes/SearchCompanies';
import SearchBoardMeetings from '../../scenes/SearchBoardMeetings';
import Conversation from '../../scenes/Conversation';
import IndividualPost from '../../scenes/IndividualPost';
import SearchAdvisors from '../../scenes/SearchAdvisors';
import LeftMenu from '../../components/Advisor/CardPublicSearch';
import eventBus, { eventBusValues } from '../../eventBus';
import Insights from '../../scenes/Insights';
import FreemiumAdvisorBanner from '../../components/Advisor/FreemiumAdvisorBanner';
import { checkSplitFlag } from '../../services/Backend/account';
import { featureFlags } from '../../splitSetup';

const useStyles = ({ isFreeTrial, isFreemium, isSM }) => ({
  advisorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    backgroundColor: '#FFF',
  },
  advisorContainerFreeTrial: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'center',
    backgroundColor: '#FFF',
  },
  advisorContainerFreemium: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    marginTop: isSM ? '64px' : '50px',
  },
  viewContainer: {
    width: '100%',
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
    flexDirection: 'row',
    height:
      isFreeTrial && !isFreemium
        ? 'calc(100vh - 145px)'
        : isFreemium
        ? 'calc(100vh - 145px)'
        : 'calc(100vh - 95px)',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F8F8F8',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#C7C7C7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#B7B7B7',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
  },
  contentContainer: {
    flex: 1,
    width: '100%',
  },
});

function AdvisorLayout() {
  const {
    accountDetails,
    isAuthenticated,
    isFreeTrial,
    isFreemium,
    currentUserInfo,
  } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isReady, setIsReady] = useState(false);
  const classes = useStyles({ isFreeTrial, isFreemium, isSM });
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {
    let isMounted = true;
    const checkRedesignFlag = async () => {
      const redesign = await checkSplitFlag({
        USER_ID: currentUserInfo.username,
        SPLIT: featureFlags.master_redesign,
      });
      if (isMounted && redesign.data?.checkSplitFlag?.treatment === 'on') {
        if (location.pathname) {
          history.push('/v2' + location.pathname + location.search + location.hash);
        } else {
          history.push('/v2/dashboard');
        }
      }
      setIsReady(true);
    };

    await checkRedesignFlag();

    return () => {
      isMounted = false;
    };
  }, []);

  function handleMenuClick() {
    eventBus.dispatch(eventBusValues.mobileMenuTrigger);
  }

  function getLeftMenu() {
    return <LeftMenu />;
  }

  function getAdvisorBoardroomsLeftMenu() {
    return <AdvisorBoardroomsLeftMenu />;
  }
  if (!isReady) return null;

  return (
    <>
      {isFreeTrial && !isFreemium && <FreeTrialBanner show={isFreeTrial} />}
      {isFreemium && <FreemiumAdvisorBanner />}
      <div
        style={
          isFreeTrial && !isFreemium
            ? classes.advisorContainerFreeTrial
            : isFreemium
            ? classes.advisorContainerFreemium
            : classes.advisorContainer
        }
      >
        {isAuthenticated && (
          <>
            <AdvisorUpperBar onAvatarMenuIconCLick={handleMenuClick} />
          </>
        )}

        <Box sx={classes.viewContainer}>
          <Switch>
            <Route path="/dashboard" component={getLeftMenu} />
            <Route path="/board-directory" component={getLeftMenu} />
            <Route path="/networking" component={getLeftMenu} />
            <Route path="/conversation" component={getLeftMenu} />
            <Route path="/question/:postId?" component={getLeftMenu} />
            <Route
              path="/post/:postId?"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/post/:postId?"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route path="/board-meetings" component={getLeftMenu} />
            <Route path="/insights" component={getLeftMenu} />
            <Route
              path="/:companySlug/boardroom"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/questions"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/board-meetings"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/question/:postId"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/benefits"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/updates"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/update/:postId"
              component={getAdvisorBoardroomsLeftMenu}
            />
            <Route
              path="/:companySlug/my-answers"
              component={getAdvisorBoardroomsLeftMenu}
            />
          </Switch>
          <div style={classes.contentContainer}>
            <Switch>
              <Route path="/dashboard" component={() => <AdvisorDashboard />} />
              <Route
                path="/board-directory"
                component={() => <SearchCompanies />}
              />
              <Route path="/conversation" component={() => <Conversation />} />
              <Route
                path="/board-meetings"
                component={() => <SearchBoardMeetings />}
              />
              <Route path="/insights" component={() => <Insights />} />
              <Route path="/networking" component={() => <SearchAdvisors />} />
              <Route
                path="/:companySlug/boardroom/:postId?"
                component={() => <BoardroomAdvisorView />}
              />
              <Route
                path="/post/:postId?"
                component={() => <IndividualPost />}
              />
              <Route
                path="/:companySlug/post/:postId?"
                component={() => <IndividualPost />}
              />
              <Route
                path="/:companySlug/questions/:postId?"
                component={() => <AdvisorBoardroomCompanyQuestions />}
              />
              <Route
                path="/:companySlug/question/:postId?"
                component={() => <AdvisorBoardroomCompanyQuestions />}
              />
              <Route
                path="/:companySlug/board-meetings/:postId?"
                component={() => <BoardroomAdvisorView />}
              />
              <Route
                path="/:companySlug/updates/:postId?"
                component={() => <AdvisorBoardroomCompanyUpdates />}
              />
              <Route
                path="/:companySlug/update/:postId?"
                component={() => <AdvisorBoardroomCompanyUpdates />}
              />
              <Route
                path="/:companySlug/my-answers"
                component={() => <BoardroomAdvisorView />}
              />
              <Route
                component={() => <Opportunities />}
                path="/advisor/opportunities"
              />
              <Route
                path="/:companySlug/benefits"
                component={() => <BoardroomAdvisorView />}
              />
              <Route
                path="/advisor/boardroom/:id"
                component={() => <Boardroom />}
              />
            </Switch>
          </div>
        </Box>
        {isAuthenticated && (
          <AdvisorRightMenuSplit
            onDismiss={() => {
              eventBus.dispatch(eventBusValues.mobileMenuTrigger);
            }}
            accountDetails={accountDetails}
          />
        )}
      </div>
    </>
  );
}

export default AdvisorLayout;
