import React, { useState, useRef } from 'react';
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';
import { useMediaQuery, Box } from '@mui/material';
import CompanyMenu from '../../components/Company/Menu';
import CompanyTopMenuMobile from '../../components/Company/TopMenu/mobile';
import CompanyUpperBar from '../../components/Company/UpperBar';
import CompanyUpperBarLegacy from '../../components/Company/UpperBar/indexLegacy.jsx';
import MyUpdates from '../../scenes/MyUpdates';
import ArchivedUpdates from '../../scenes/ArchivedUpdates';
import MyQuestions from '../../scenes/MyQuestions';
import Breakthroughs from '../../scenes/Breakthroughs';
import ArchivedQuestions from '../../scenes/ArchivedQuestions';
import MyAdvisors from '../../scenes/MyAdvisors';
import SearchAdvisors from '../../scenes/SearchAdvisors';
import SavedAdvisors from '../../scenes/SavedAdvisors';
import TopMatchesAdvisors from '../../scenes/TopMatchesAdvisors';
import InvitedAdvisors from '../../scenes/InvitedAdvisors';
import { useAccount } from '../../contexts/Account';
import ImpersonateUpperBar from '../../components/ImpersonationUpperBar';
import CompanyDashboard from '../../scenes/CompanyDashboard';
import CompanyBoardMeetings from '../../scenes/CompanyBoardMeetings';
import IndividualPostEdit from '../../scenes/IndividualPostEdit';
import { CompanyExtraFeedProvider } from '../../contexts/CompanyFeed';
import { PostType } from '../../services/utils/types';
import FreeTrialBanner from '../../components/FreeTrialBanner';
import FreemiumBanner from '../../components/FreemiumBanner';
import ExFreemiumBanner from '../../components/ExFreemiumBanner';
import OnboardingBanner from '../../components/OnboardingBanner';
import IndividualPost from '../../scenes/IndividualPost';
import Conversation from '../../components/Conversation';
import CompanyRightMenu from '../../components/Company/RightMenu/index';
import eventBus, { eventBusValues } from '../../eventBus';
import { AccountType } from '../../services/utils/types';
import Insights from '../../scenes/Insights';
import CompanyAccount from '../../scenes/CompanyAccount';
import ManagedAdvisoryBoard from '../../scenes/ManagedAdvisoryBoard';
import FreeTrialUpgrade from '../../scenes/ManagedAdvisoryBoard/freeTrialUpgrade';
import GeneralUpgrade from '../../components/ManagedAdvisoryBoard/GeneralUpgrade';
import PlusUpgrade from '../../scenes/PlusAdvisoryUpgrade';
import { featureFlags } from '../../splitSetup';
import Treatment from '../../components/Treatment';
import Ideas from '../../scenes/v2/Ideas';

function getTopValue(isSM, isFreemium, showSmallBanner) {
  if (isSM) {
    // if (isFreemium) {
    //   return '104px';
    // }
    return '50px';
  }
  // if (isFreemium || showSmallBanner) {
  //   return '50px';
  // }
  if (showSmallBanner) {
    return '50px';
  }
  return '50px';
}

const useStyles = ({
  isSM,
  showBanner,
  isFreeTrial,
  isFreemium,
  showSmallBanner,
  isAdvisorIdeasPage
}) => ({
  companyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
  },
  viewContainer: {
    marginTop: getTopValue(isSM, isFreemium, showSmallBanner),
    overscrollBehavior: 'contain',
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
    // backgroundColor: '#f4f8ff',
    height: 'calc(100vh - 50px)',
    // height:
    //   isFreeTrial || isFreemium || showSmallBanner
    //     ? 'calc(100vh - 130px)'
    //     : showBanner
    //     ? 'calc(100vh - 110px)'
    //     : 'calc(100vh - 80px)',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F8F8F8',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#C7C7C7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#B7B7B7',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    width: '100%',
  },
  companyContainerImpersonated: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'center',
  },
});

function BaseLayout() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    isImpersonated,
    isFreeTrial,
    currentPlan,
    accountDetails,
    isFreemium,
    type,
    currentUserInfo,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    !isFreeTrial &&
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const showBanner =
    type === AccountType.ADVISOR &&
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const isSM = useMediaQuery((umq) => umq.breakpoints.down('sm'));
  const location = useLocation();
  const classes = useStyles({
    isSM,
    showBanner,
    isFreeTrial,
    isFreemium,
    showSmallBanner: showExFremiumBanner || showOnboardingBanner,
    isAdvisorIdeasPage: location.pathname.includes('/advisor_ideas'),
  });
  const history = useHistory();
  const boxRef = useRef();

  function handleMenuClick() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <CompanyExtraFeedProvider>
        {isImpersonated && <ImpersonateUpperBar />}
        {isFreemium ? null : (
          // <FreemiumBanner show={isFreemium} />
          <FreeTrialBanner show={isFreeTrial} isCompanyContact />
        )}
        {accountDetails.freemiumRegistration &&
          accountDetails.opportunityStage === 'discovery' &&
          !isFreemium && <ExFreemiumBanner show />}
        {showOnboardingBanner && <OnboardingBanner show />}
        <div
          style={
            isImpersonated
              ? classes.companyContainerImpersonated
              : classes.companyContainer
          }
        >
          <CompanyRightMenu
            onDismiss={() => {
              eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
            }}
            accountDetails={accountDetails}
          />
          <Treatment
            userId={
              currentUserInfo && currentUserInfo.username
                ? currentUserInfo.username
                : 'not_id'
            }
            splitNames={featureFlags.b2b_redesign}
            updateOnSdkTimedout
            evaluatedComponent={<></>}
            conditionFlag
            conditionFailedComponent={<CompanyMenu />}
          />

          <div style={classes.childrenContainer}>
            <Treatment
              userId={
                currentUserInfo && currentUserInfo.username
                  ? currentUserInfo.username
                  : 'not_id'
              }
              splitNames={featureFlags.b2b_redesign}
              updateOnSdkTimedout
              evaluatedComponent={<CompanyUpperBar />}
              conditionFlag
              conditionFailedComponent={
                !location.pathname.includes('/messages') && (
                  <>
                    <CompanyTopMenuMobile onMenuIconClick={handleMenuClick} />
                    <CompanyUpperBarLegacy />
                  </>
                )
              }
            />

            <Box sx={classes.viewContainer} ref={boxRef}>
              <div style={{ width: '100%' }}>
                <Switch>
                  <Route path="/dashboard" component={CompanyDashboard} />
                  <Route
                    path="/board-meetings"
                    component={CompanyBoardMeetings}
                  />
                  <Route path="/messages" component={Conversation} />
                  <Route path="/updates/archived" component={ArchivedUpdates} />
                  <Route exact path="/updates" component={MyUpdates} />
                  <Route
                    path="/update/:postId?/edit"
                    component={() => (
                      <IndividualPostEdit postType={PostType.UPDATE} />
                    )}
                  />
                  <Route path="/update/:postId?" component={MyUpdates} />
                  <Route path="/updates/:postId?" component={MyUpdates} />
                  <Route
                    path="/insight/:postId?/edit"
                    component={() => (
                      <IndividualPostEdit postType={PostType.INSIGHT} />
                    )}
                  />
                  <Route
                    path="/meeting/:postId?/edit"
                    component={() => (
                      <IndividualPostEdit postType={PostType.MEETING} />
                    )}
                  />
                  <Route
                    exact
                    path="/breakthroughs"
                    component={Breakthroughs}
                  />
                  <Route path="/post/:postId?" component={IndividualPost} />
                  <Route
                    exact
                    path="/questions/archived"
                    component={ArchivedQuestions}
                  />
                  <Route
                    path="/question/:postId?/edit"
                    component={() => (
                      <IndividualPostEdit postType={PostType.QUESTION} />
                    )}
                  />
                  <Route path="/question/:postId?" component={MyQuestions} />
                  <Route path="/questions/:postId?" component={MyQuestions} />
                  <Route exact path="/advisor_ideas" component={Ideas} />
                  <Route
                    exact
                    path="/advisors"
                    component={() => (
                      <MyAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/search"
                    component={() => <SearchAdvisors boxRef={boxRef} />}
                  />
                  <Route
                    exact
                    path="/advisors/saved"
                    component={() => (
                      <SavedAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/top_matches"
                    component={() => (
                      <TopMatchesAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/invited"
                    component={() => (
                      <InvitedAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/:publicProfileUrl"
                    component={MyAdvisors}
                  />
                  <Route exact path="/insights" component={Insights} />
                  <Route exact path="/account" component={CompanyAccount} />
                  <Route
                    exact
                    path="/upgrade/b2b_managed_advisory_board"
                    component={ManagedAdvisoryBoard}
                  />
                  <Route
                    exact
                    path="/upgrade/b2b_ft"
                    component={FreeTrialUpgrade}
                  />
                  <Route
                    exact
                    path="/upgrade/b2b_general"
                    component={GeneralUpgrade}
                  />
                  <Route path="/upgrade/b2b_ac_plus" component={PlusUpgrade} />
                </Switch>
              </div>
            </Box>
          </div>
        </div>
      </CompanyExtraFeedProvider>
    </>
  );
}

export default BaseLayout;
