import React, { useEffect, useState } from 'react';
import NotificationsSettings from '../NotificationsSettings';
import NotificationsSettingsFreemiumView from '../NotificationsSettings/FreemiumView';
import { useAccount } from '../../contexts/Account';
import { AccountType } from '../../services/utils/types';
import Treatment from '../Treatment';
import { featureFlags } from '../../splitSetup';

function CompanyPreferences({
  freemiumView,
  hidePlanDetails,
  hideBillingInformation,
}) {
  const { accountDetails, asLoggedUserDetails, currentUserInfo } = useAccount();
  const [contact, setContact] = useState({});
  const [toggleTextValuesLegacy] = useState({
    blockNewAdvisorsEmail: 'COMPANY-NOTIFICATIONS-NEW-ADVISORS',
    blockNewAdvisorCommentInUpdateEmail: 'COMPANY-NOTIFICATIONS-NEW-COMMENTS',
    blockNewAdvisorAnswerEmail: 'COMPANY-NOTIFICATIONS-NEW-ANSWERS',
  });
  const [toggleValuesLegacy] = useState([
    'blockNewAdvisorsEmail',
    'blockNewAdvisorAnswerEmail',
    'blockNewAdvisorCommentInUpdateEmail',
  ]);
  const [toggleTextValues] = useState({
    blockNewAdvisorsEmail: 'COMPANY-NOTIFICATIONS-NEW-ADVISORS',
  });
  const [toggleValues] = useState(['blockNewAdvisorsEmail']);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    let isMounted = true;
    const currentContact = accountDetails.contacts.find(
      (cUser) => cUser.id === asLoggedUserDetails.userId
    );
    if (isMounted) {
      setContact(currentContact);
    }
    const defaultEmailObject = {};
    if (isMounted && currentContact.emailPreferences) {
      toggleValues.forEach((value) => {
        defaultEmailObject[value] =
          currentContact.emailPreferences[value] || false;
      });
      toggleValuesLegacy.forEach((value) => {
        defaultEmailObject[value] =
          currentContact.emailPreferences[value] || false;
      });
      setFormValues(defaultEmailObject);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Treatment
      userId={
        currentUserInfo && currentUserInfo.username
          ? currentUserInfo.username
          : 'not_id'
      }
      splitNames={featureFlags.b2b_redesign}
      updateOnSdkTimedout
      evaluatedComponent={
        !freemiumView ? (
          <NotificationsSettings
            formValues={formValues}
            setFormValues={setFormValues}
            toggleValues={toggleValues}
            toggleTextValues={toggleTextValues}
            user={contact}
            companyId={accountDetails.id}
            acountType={AccountType.COMPANY}
          />
        ) : (
          <NotificationsSettingsFreemiumView
            formValues={formValues}
            setFormValues={setFormValues}
            toggleValues={toggleValues}
            toggleTextValues={toggleTextValues}
            user={contact}
            companyId={accountDetails.id}
            acountType={AccountType.COMPANY}
            hideBillingInformation={hideBillingInformation}
            hidePlanDetails={hidePlanDetails}
          />
        )
      }
      conditionFlag
      conditionFailedComponent={
        !freemiumView ? (
          <NotificationsSettings
            formValues={formValues}
            setFormValues={setFormValues}
            toggleValues={toggleValuesLegacy}
            toggleTextValues={toggleTextValuesLegacy}
            user={contact}
            companyId={accountDetails.id}
            acountType={AccountType.COMPANY}
          />
        ) : (
          <NotificationsSettingsFreemiumView
            formValues={formValues}
            setFormValues={setFormValues}
            toggleValues={toggleValuesLegacy}
            toggleTextValues={toggleTextValuesLegacy}
            user={contact}
            companyId={accountDetails.id}
            acountType={AccountType.COMPANY}
            hideBillingInformation={hideBillingInformation}
            hidePlanDetails={hidePlanDetails}
          />
        )
      }
    />
  );
}

export default CompanyPreferences;
