import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import queryString from 'query-string';
import NewsAndEvents from './NewsAndEvents';
import Invitations from './Invitations';
import MyBoards from './MyBoards';
import UpcomingBoardMeetings from './UpcomingBoardMeetings';
import ACSnackbar from '../../components/ACSnackbar';
import LinkedInPostModal from '../../components/LinkedInPostModal';
import {
  authorize,
  getAccessToken,
  isLinkedinAuthenticated,
} from '../../services/LinkedIn';
import { useAccount } from '../../contexts/Account';
import BoardsAndInvitations from './BoardsAndInvitations';
import { featureFlags } from '../../splitSetup';
import Treatment from '../../components/Treatment';
import BoardroomHeader from './BoardroomHeader';
import { useLocation } from 'react-router-dom';
import eventBus, { eventBusValues } from '../../eventBus';

const Dashboard = () => {
  const { currentUserInfo, accountDetails, isFreemium } = useAccount();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1302]));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Estados para los snackbars y el modal de LinkedIn
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);

  // Función para iniciar el proceso de LinkedIn
  async function linkedInSign() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorize();
    }
  }

  // Callback para procesar la respuesta de LinkedIn en el modal
  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setErrorMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setErrorSnackbar(true);
    } else {
      setErrorMessage('An error occurred while sharing your profile.');
      setErrorSnackbar(true);
    }
  }

  // Función para revisar si en la URL existe el código de LinkedIn
  function checkIfLinkedinCode() {
    const parsed = queryString.parse(window.location.search);
    if (parsed.code) {
      if (!isLinkedinAuthenticated()) {
        getAccessToken(parsed).then((response) => {
          if (response.data && response.data.getLinkedinAccessToken) {
            linkedInSign();
          } else {
            setErrorMessage('An error occurred while sharing your profile.');
            setErrorSnackbar(true);
          }
        });
      } else {
        linkedInSign();
      }
    }
  }

  useEffect(() => {
    checkIfLinkedinCode();
  }, []);

  useEffect(() => {
    let conversationId = queryParams.get('conversationId');
    if (conversationId) {
      eventBus.dispatch(eventBusValues.triggerConversationDrawer, {
        conversationId,
      });
    }
  }, []);

  return (
    <Box
      sx={{
        padding: { xxs: '12px 15px', sm: '24px 30px' },
        width: isSM ? '100%' : null,
      }}
    >
      {/* Snackbars de éxito y error */}
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />

      {/* Modal de LinkedIn */}
      {linkedInPostModal && (
        <LinkedInPostModal
          linkedInPostModal={linkedInPostModal}
          setLinkedInPostModal={setLinkedInPostModal}
          linkedInResponse={linkedInResponse}
          shareProfile
        />
      )}

      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            width: isSM ? '100%' : null,
          }}
        >
          {/* <Box sx={{ marginBottom: '20px' }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: { xxs: 32, sm: 40 },
                lineHeight: isWrapped ? '25px' : 'inherit',
              }}
            >
              Welcome,{' '}
              <span style={{ color: '#2C71F6' }}>
                {accountDetails.givenName}.&nbsp;
              </span>
              {!isXs ? (
                <span
                  style={{
                    fontWeight: '400',
                    color: '#4D586E',
                    fontSize: 20,
                  }}
                >
                  Here's your dashboard for your boards.
                </span>
              ) : null}
            </Typography>
            {isXs && (
              <Typography
                sx={{ fontWeight: '400', color: '#4D586E', fontSize: 16 }}
              >
                Here's your dashboard for your boards.
              </Typography>
            )}
          </Box> */}
          <BoardroomHeader advisor={accountDetails} />
          {/* <NewsAndEvents /> */}
          <Treatment
            userId={currentUserInfo.username}
            splitNames={featureFlags.advisor_boardroom_redesign}
            updateOnSdkTimedout
            conditionFlag
            evaluatedComponent={<BoardsAndInvitations />}
            conditionFailedComponent={
              <>
                {' '}
                <Invitations /> <MyBoards />{' '}
              </>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            width: isSM ? '100%' : null,
          }}
        >
          <UpcomingBoardMeetings />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
