import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
  Fade,
  Box,
} from '@mui/material';
import { zonedTimeToUtc } from 'date-fns-tz';
import { google, outlook, ics, office365 } from 'calendar-link';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ACAvatar from './../../../../ACAvatar';
import CustomIcon from './../../../../../icons/customIcon';
import { useAccount } from './../../../../../contexts/Account';
import dateFormat from './../../../../../utils/dateFormat';
import { getModeratorImagePath } from './../../../../../services/utils';
import eventBus, { eventBusValues } from './../../../../../eventBus';
import { ReactComponent as AppleIcon } from './apple.svg';
import { ReactComponent as MicrosoftIcon } from './microsoft.svg';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as OutlookIcon } from './outlook.svg';
import CalendarProviderButton from '../../../../TopTiles/CalendarProviderButton';
import { SnackbarType } from '../../../../../services/utils/types';

const useStyles = (isSM) => ({
  boardMeetingContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  boardMeetingContainerSingle: {
    width: '100%',
  },
  boardMeetingHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '22px',
  },
  boardMeetingDescription: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: '15px',
  },
  boardMeetingDate: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    color: '#646D7A',
  },
  actionButton: {
    background: '#6736FA',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 800,
    whiteSpace: 'nowrap',
    fontSize: '15px',
    lineHeight: '22px',
    color: 'white',
    height: '41px',
    // width: isSM ? '100%' : 'fit-content',
    width: '100%',
    maxWidth: '900px',
    minWidth: '200px',
    textTransform: 'none',
    '&:hover': {
      background: '#6736FA',
    },
  },
  actionButtonBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    //justifyContent: 'flex-end',
    justifyContent: 'center',
    marginTop: '22px',
  },
  stickyActionButton: {
    width: '100%',
    position: 'fixed',
    top: '360px',
    left: '-40px',
    zIndex: 2,
  },
  description: {
    width: '100%',
    whiteSpace: 'break-spaces',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'left',
    paddingRight: '15px',
    flexDirection: 'row',
    paddingTop: '10px',
  },
  avatarContainer: {
    paddingRight: '50px',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row',
  },
  avatarDetailsContainer: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    textAlign: 'left',
  },
  avatarContainerName: {
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
    margin: '4px 0px 8px 0px',
  },
  avatarContainerTitle: {
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
  },
  backDrop: {
    zIndex: 999,
  },
  elipsies: {
    color: 'secondary.main',
    position: 'relative',
    fontSize: isSM ? '20px' : '25px',
  },
  button: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    width: '10px',
    height: '22px',
    overflow: 'hidden',
  },
  iconStyle: {
    height: '14px',
    marginBottom: '2px',
    marginRight: '11px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemText: {
    fontSize: '14px',
    marginBottom: '5px',
    marginRight: '11px',
  },
  actionsMenu: {
    width: '140px',
    borderRadius: '12px',
    border: '1px solid var(--Gray-5, #F5F7FA)',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
});

function BoardMeetingActions(props) {
  const {
    post,
    isSingleBoardMeetingPost,
    stickyButton,
    boardMeetingLocked,
    automaticCalendarLink,
    body,
  } = props;
  const { isFreeTrial, accountDetails, type } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);

  const calendarTokens = accountDetails?.calendarTokens || {};
  const googleToken = calendarTokens.google?.isSync;
  const appleToken = calendarTokens.apple?.isSync;
  const advisorId = accountDetails?.id;
  const companyIds = accountDetails?.boards?.memberBoards?.map(
    (board) => board.id
  );
  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };
  const localeHour = new Date(post.meetingTime);
  const today = new Date();

  const [formatDate, setFormatDate] = useState('');
  const [time, setTime] = useState(
    localeHour > today
      ? 'future'
      : differenceInHours(today, localeHour) < 1
      ? 'live'
      : 'past'
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (post.meetingTime) {
      try {
        let strTime = localeHour.toLocaleString('en-US', {
          timeZone: `${post.meetingTimeZone}`,
        });
        const streamingDate = new Date(strTime);
        const formatDate = dateFormat(streamingDate, 'mmmm dS, yyyy @ h:MM TT');
        setFormatDate(formatDate);
      } catch (error) {
        setFormatDate('');
        console.log('Error in date format', error);
      }
    }
  }, [post.meetingTime]);

  useEffect(() => {
    if (time == 'future' && differenceInHours(localeHour, today) < 2) {
      const interval = setInterval(() => {
        const today = new Date();
        setTime(
          localeHour > today
            ? 'future'
            : differenceInHours(today, localeHour) < 1
            ? 'live'
            : 'past'
        );
      }, 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  function differenceInHours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return diff;
  }

  function openJoinSlide() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId: post.companyId,
    });
  }

  function joinTheMeeting() {
    if (boardMeetingLocked) {
      openJoinSlide();
    } else if (!isSingleBoardMeetingPost && !open) {
      history.push(`/post/${post.id}`);
    }
  }

  function askQuestionLive(event) {
    event.stopPropagation();
    if (!isFreeTrial) {
      if (boardMeetingLocked) {
        openJoinSlide();
      } else {
        window.open(
          post.meetingLink ||
            'https://studio.restream.io/RUc1Sk84ejdqnypQrU4pgpW8wjUQgR0?default-camera-mirror=true',
          '_blank'
        );
      }
    } else {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: post.postCompanyContact.companyName,
      });
    }
  }

  function addToCalendar(event) {
    event.stopPropagation();
    if (boardMeetingLocked) {
      openJoinSlide();
    } else {
      event.stopPropagation();
      if (automaticCalendarLink) {
        if (!anchorEl) {
          setAnchorEl(event.currentTarget);
        } else {
          setAnchorEl(null);
        }
      } else {
        window.open(post.calendarLink, '_blank');
      }
    }
  }

  function addToCalendarAction(event, type) {
    event.stopPropagation();
    let dateUTC = zonedTimeToUtc(post.meetingTime, post.meetingTimeZone);
    if (dateUTC != 'Invalid Date') {
      dateUTC = dateUTC.toISOString();
      const eventCalendar = {
        title: post.postTitle,
        description: post.body,
        start: post.meetingTime,
        duration: [1, 'hour'],
        location: `${window.location.origin}/post/${post.id}`,
      };
      switch (type) {
        case 'apple': {
          const appleCalendarLink = ics(eventCalendar);
          var link = document.createElement('a');
          link.href = appleCalendarLink;
          link.setAttribute('download', 'boardMeeting.ics');
          document.body.appendChild(link);
          link.click();
          break;
        }
        case 'google': {
          const googleCalendarLink = google(eventCalendar);
          window.open(googleCalendarLink, '_blank');
          break;
        }
        case 'outlook': {
          const outlookCalendarLink = outlook(eventCalendar);
          window.open(outlookCalendarLink, '_blank');
          break;
        }
        case 'microsoft': {
          const office365CalendarLink = office365(eventCalendar);
          window.open(office365CalendarLink, '_blank');
          break;
        }
      }
      handleClose();
    }
  }

  function replyAction(event) {
    event.stopPropagation();
    if (boardMeetingLocked) {
      openJoinSlide();
    } else {
      history.push(`/post/${post.id}`);
    }
  }

  function displayImage() {
    if (post.postCompanyContact.companyContactImage != null) {
      if (!post.postCompanyContact.companyContactImage.includes('undefined')) {
        return post.postCompanyContact.companyContactImage;
      }
    }
    return DefaultAdvisorAvatar;
  }

  function displayButton() {
    return (
      <>
        {time == 'live' && isSingleBoardMeetingPost ? (
          <Grid item md={4} xs={12}>
            <div
              style={{
                ...classes.actionButtonBox,
                ...(stickyButton ? classes.stickyActionButton : {}),
              }}
            >
              <Button
                id="actionButtonLive"
                className="boardMeetingActionButton"
                sx={classes.actionButton}
                onClick={askQuestionLive}
              >
                <VideoCameraFrontOutlinedIcon
                  color="white"
                  style={{ marginRight: 10, fontSize: '24px' }}
                />
                {t('BOARD-MEETING-ASK-LIVE-QUESTION')}
              </Button>
            </div>
          </Grid>
        ) : null}
        {time == 'live' && !isSingleBoardMeetingPost ? (
          <Grid item md={4} xs={12}>
            <div
              style={{
                ...classes.actionButtonBox,
                ...(stickyButton ? classes.stickyActionButton : {}),
              }}
            >
              <Button
                id="actionButtonLive"
                className="boardMeetingActionButton"
                sx={classes.actionButton}
                onClick={askQuestionLive}
              >
                <VideoCameraFrontOutlinedIcon
                  color="white"
                  style={{ marginRight: 10, fontSize: '24px' }}
                />
                {t('BOARD-MEETING-JOIN-MEETING')}
              </Button>
            </div>
          </Grid>
        ) : null}
        {time == 'future' ? (
          <Grid item md={4} xs={12}>
            <div
              style={{
                ...classes.actionButtonBox,
                ...(stickyButton ? classes.stickyActionButton : {}),
              }}
            >
              {type === 'advisor' && (
                <>
                  {!appleToken && !googleToken && (
                    <Box onClick={(event) => event.stopPropagation()}>
                      <CalendarProviderButton
                        syncStates={{
                          googleSync,
                          appleSync,
                        }}
                        setSyncStates={{
                          setGoogleSync,
                          setAppleSync,
                        }}
                        advisorId={advisorId}
                        companyIds={companyIds}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        showSnackbar={showSnackbar}
                        isSinglePost={true}
                      />
                    </Box>
                  )}
                </>
              )}

              <Box sx={classes.backDrop} open={open} onClick={handleClose}>
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  // keepMounted
                  sx={{ '& .MuiMenu-paper': { ...classes.actionsMenu } }}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={(e) => addToCalendarAction(e, 'apple')}>
                    <div style={classes.menuItem}>
                      <AppleIcon style={classes.iconStyle} />
                      <Typography
                        variant="caption_old_font"
                        sx={classes.menuItemText}
                      >
                        Apple
                      </Typography>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={(e) => addToCalendarAction(e, 'google')}>
                    <div style={classes.menuItem}>
                      <GoogleIcon style={classes.iconStyle} />
                      <Typography
                        variant="caption_old_font"
                        sx={classes.menuItemText}
                      >
                        Google
                      </Typography>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={(e) => addToCalendarAction(e, 'outlook')}>
                    <div style={classes.menuItem}>
                      <OutlookIcon style={classes.iconStyle} />
                      <Typography
                        variant="caption_old_font"
                        sx={classes.menuItemText}
                      >
                        Outlook
                      </Typography>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => addToCalendarAction(e, 'microsoft')}
                  >
                    <div style={classes.menuItem}>
                      <MicrosoftIcon style={classes.iconStyle} />
                      <Typography
                        variant="caption_old_font"
                        sx={classes.menuItemText}
                      >
                        Microsoft
                      </Typography>
                    </div>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </Grid>
        ) : null}
        {time == 'past' && !isSingleBoardMeetingPost ? (
          <Grid item md={4} xxs={12} xs={12}>
            <div
              style={{
                ...classes.actionButtonBox,
                ...(stickyButton ? classes.stickyActionButton : {}),
              }}
            >
              <Button
                id="actionButtonPast"
                className="boardMeetingActionButton"
                style={classes.actionButton}
                onClick={replyAction}
              >
                <PlayCircleFilledWhiteIcon
                  color="white"
                  style={{ marginRight: 10, fontSize: '24px' }}
                />
                {t('BOARD-MEETING-WATCH-REPLY')}
              </Button>
            </div>
          </Grid>
        ) : null}
      </>
    );
  }

  return (
    <div
      style={{
        ...(isSingleBoardMeetingPost
          ? classes.boardMeetingContainerSingle
          : classes.boardMeetingContainer),
        paddingTop: stickyButton ? '250px' : '0px',
      }}
      onClick={joinTheMeeting}
    >
      {displayButton()}
      <Grid container spacing={2}>
        {/* {isSM ? displayButton() : null} */}
        <Grid item md={8} xs={12}>
          <div style={classes.boardMeetingHeader} id="boardMeetingInfo">
            <Typography sx={classes.boardMeetingDescription}>
              {post.postTitle}
            </Typography>
            <Typography sx={classes.boardMeetingDate} id="boardMeetingDate">
              {`${formatDate} ${post.meetingTimeZone || ''}`}
            </Typography>
          </div>
        </Grid>
        {/* {!isSM ? displayButton() : null} */}
      </Grid>
      <div
        style={{
          ...classes.description,
          margin: isSingleBoardMeetingPost ? '0px' : '15px 15px 15px 0',
        }}
      >
        {body}
      </div>
      <div style={classes.avatarBox}>
        <div style={classes.avatarContainer}>
          <ACAvatar size="md" image={displayImage()} />
          <div style={classes.avatarDetailsContainer}>
            <Typography sx={classes.avatarContainerName}>
              {post.postCompanyContact.name}
            </Typography>
            <Typography sx={classes.avatarContainerTitle}>
              {post.postCompanyContact.title}
              {post.postCompanyContact.title &&
              post.postCompanyContact.companyName
                ? ', '
                : ''}
              {post.postCompanyContact.companyName}
            </Typography>
          </div>
        </div>
        <div style={classes.avatarContainer}>
          <ACAvatar
            size="md"
            image={getModeratorImagePath(post.moderatorAvatar)}
          />
          <div style={classes.avatarDetailsContainer}>
            <Typography sx={classes.avatarContainerName}>
              {post.moderatorName}
            </Typography>
            <Typography sx={classes.avatarContainerTitle}>
              {post.moderatorTitle}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoardMeetingActions;
