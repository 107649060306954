import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../../contexts/Account';
import { companyFetchPostsCall } from '../../services/company_services';
import {
  AccountType,
  PostType,
  EndpointParameterFilters,
} from '../../services/utils/types';
import BoardMeetingsCompanyContent from '../../components/Post/BoardMeetingsCompanyContent/index';

import EndpointCaller from '../../components/EndpointCaller';
import {
  CallTypes,
  useInfiniteScroll,
} from '../../components/Post/ResponseReply/useInfiniteScroll';
import {
  getIndexArrayValues,
  getValuesFromKeyInDataResponse,
  isNil,
} from '../../services/utils';
import { useCompanyExtraFeed } from '../../contexts/CompanyFeed';
import CompanyTiles from '../../components/TopTiles/company/CompanyTiles';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

const useStyles = (isSM) => ({
  screenContainer: {
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: '30px',
    paddingRight: isSM ? '10px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    maxWidth: '1260px',
  },

  screenContainerV2: {
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: '30px',
    paddingRight: isSM ? '10px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
});

function BoardMeetings(props) {
  const location = useLocation();
  const { showPostPopUp, showPostWrapperContent } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const {
    asLoggedUserDetails,
    accountDetails,
    authenticate,
    isAuthenticated,
    isImpersonated,
    currentUserInfo,
  } = useAccount();
  const [loading, setLoading] = useState(true);
  const [insightCount, setInsightCount] = useState(0);
  const [legacyIdErrorSnackbarVisible, setLegacyIdErrorSnackbarVisible] =
    useState(false);
  const { state, removeAllPosts } = useCompanyExtraFeed();

  useEffect(() => {
    return () => {
      removeAllPosts();
    };
  }, []);

  const companyEndpointCalls = [
    {
      endPointCall: companyFetchPostsCall,
      type: CallTypes.MAIN_CALL,
      endPointCallParameters: {
        ACCOUNT_TYPE: AccountType.CONTACT,
        ADVISOR_ID: '',
        COMPANY_ID: accountDetails.id,
        LIMIT: EndpointParameterFilters.FETCH_LIMIT,
        OFFSET: 0,
        ORDER_BY: EndpointParameterFilters.ORDER_BY_SORT_AT,
        REVERSE: false,
        TYPE: PostType.BOARD_MEETINGS,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
      },
    },
  ];

  const {
    posts,
    isMainCallFetching,
    setInitialPosts,
    incrementOffset,
    addInitialPosts,
    addPostLike,
  } = useInfiniteScroll({ endpointCalls: companyEndpointCalls });

  useEffect(() => {
    addInitialPosts(state.temporaryNewPost);
  }, [state.temporaryNewPost]);

  function GetEndpointResponseArray(array) {
    const post = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 0),
      'getPosts'
    );
    const insightCounts = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 1),
      'getInsights'
    );

    setInsightCount(isNil(insightCounts) ? 0 : insightCounts.length);
    setInitialPosts(isNil(post) ? [] : post);

    setLoading(false);
  }

  useEffect(() => {
    async function fetchUserData() {
      await authenticate();
    }

    if (
      !isAuthenticated &&
      !isImpersonated &&
      props.location.state &&
      props.location.state.onboarded
    ) {
      fetchUserData();
    }

    if (location.state && location.state.missingLegacyId) {
      setLegacyIdErrorSnackbarVisible(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>

      <Treatment
        userId={
          currentUserInfo && currentUserInfo.username
            ? currentUserInfo.username
            : 'not_id'
        }
        splitNames={featureFlags.b2b_redesign}
        updateOnSdkTimedout
        evaluatedComponent={
          <div style={classes.screenContainerV2}>
            <EndpointCaller
              endPointCallObjectArray={companyEndpointCalls}
              onEndpointCallsFinished={GetEndpointResponseArray}
              executeCalls={loading}
            />

            {!loading ? (
              <BoardMeetingsCompanyContent
                showPostPopUp={showPostPopUp}
                showPostWrapperContent={showPostWrapperContent}
                postsList={posts}
                insightCount={insightCount}
                isMainCallFetching={isMainCallFetching}
                incrementOffset={incrementOffset}
                addPostLike={addPostLike}
              />
            ) : null}
          </div>
        }
        conditionFlag
        conditionFailedComponent={
          <div style={classes.screenContainer}>
            <EndpointCaller
              endPointCallObjectArray={companyEndpointCalls}
              onEndpointCallsFinished={GetEndpointResponseArray}
              executeCalls={loading}
            />
            <CompanyTiles />
            {!loading ? (
              <BoardMeetingsCompanyContent
                showPostPopUp={showPostPopUp}
                showPostWrapperContent={showPostWrapperContent}
                postsList={posts}
                insightCount={insightCount}
                isMainCallFetching={isMainCallFetching}
                incrementOffset={incrementOffset}
                addPostLike={addPostLike}
              />
            ) : null}
          </div>
        }
      />

      <ACSnackbar
        open={legacyIdErrorSnackbarVisible}
        text={t('DASHBOARD-IWM-ID-NOT-PRESENT-ERROR')}
        severity="error"
        onClose={() => setLegacyIdErrorSnackbarVisible(false)}
        autoHideDuration={5000}
      />
    </>
  );
}

export default BoardMeetings;
