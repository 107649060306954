import React, { useState } from 'react';
import PostResponseReply from './../../Post/ResponseReply';
import DashboardEmptyState from './dashboardEmptyState';
import InsightsEmptyState from './insightsEmptyState';
import MeetingsEmptyState from './meetingsEmptyState';

function DashboardCompanyContent(props) {
  const {
    postsList,
    isMainCallFetching,
    incrementOffset,
    addPostLike,
    filterBy,
  } = props;

  const [singlePostMode, setSinglePostMode] = useState(false);

  function SinglePostChangedCallback(isSinglePost) {
    setSinglePostMode(isSinglePost);
  }

  return (
    <>
      <PostResponseReply
        gotPostList={postsList}
        hideTopCards
        onSinglePostChanged={SinglePostChangedCallback}
        multiplePosts
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        addPostLike={addPostLike}
        dashboardOrigin
      />

      {postsList.length === 0 &&
        (filterBy.includes('meeting') ? (
          <MeetingsEmptyState />
        ) : filterBy.includes('insight') ? (
          <InsightsEmptyState />
        ) : (
          <DashboardEmptyState />
        ))}
    </>
  );
}

export default DashboardCompanyContent;
