import React from 'react';
import CompanyListGrid from './../../Company/ListGrid';
import CompanyList from '../../v2/CompanyList';
import { useAccount } from '../../../contexts/Account';

function AdvisorSearchCompanies(props) {
  const { currentUserInfo } = useAccount();
  const {
    endpointResponse,
    isMainCallFetching,
    incrementOffset,
    loading,
    emptySearchParams,
    lastFetch,
  } = props;

  function companyListGrid() {
    return (
      <CompanyList
        companiesList={endpointResponse}
        removeBelowButtons
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        isCompanyCard
        isBoardSekeleton
        emptySearchParams={emptySearchParams}
        buildBoardFlagOn
        lastFetch={lastFetch}
      />
    );
  }

  return (
    <>{endpointResponse.length > 0 && !loading ? companyListGrid() : null}</>
  );
}

export default AdvisorSearchCompanies;
