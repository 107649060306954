import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import PostPopUp from './../../CompanyPostPopUp';
import CompanyPostButtonMobile from './PostButtonMobile';
import CompanyMenu from './CompanyMenu';
import NewCompanyMemberConfirmationModal from './../../AccountSettings/NewMemberConfirmationModal';
import UpgradePlanModal from './../../UpgradePlanModal';
import ConversationNotification from './../../Conversation/Notification';
import NewCompanyMemberModal from './../NewContactModal';
import { useCompanyExtraFeed } from './../../../contexts/CompanyFeed';
import eventBus, { eventBusValues } from './../../../eventBus';
import { Clear } from '@mui/icons-material';
import PostTypeSelector from './PostTypeSelector';
import HoverMenuItem from '../../v2/HoverMenuItem';
import { getCompanyQueryParams } from '../../../utils/queryParams';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';
import AdvisoryCloudLogo from '../../AdvisoryCloudLogo';

const useStyles = ({ isSM, isXS, isWrapped, topvalue }) => ({
  bContainer: {
    width: !isSM ? '80%' : '100%',
    maxWidth: !isSM ? 'calc(100% - 280px)' : '100%',
    minWidth: !isSM ? 'calc(100% - 350px)' : '100%',
    height: '82px',
    top: topvalue,
    backgroundColor: 'white',
    borderRadius: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: isSM ? '10px' : '0px',
    alignItems: 'center',
    paddingRight: isSM ? '20px' : '70px',
    zIndex: 2,
    position: 'fixed',
    border: 'solid 1px #E6EDFF',
    borderLeft: 'none',
  },
  bContainerRedesign: {
    width: '100%',
    height: '82px',
    top: topvalue,
    backgroundColor: 'white',
    borderRadius: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: isSM ? '10px' : '0px',
    alignItems: 'center',
    paddingRight: isSM ? '20px' : '70px',
    zIndex: 2,
    position: 'fixed',
    border: 'solid 1px #E6EDFF',
    borderLeft: 'none',
  },
  navContainer: {
    display: 'flex',
    height: '75px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: isSM ? 'none' : 'flex',
    flex: 'auto',
    height: '75px',
    flexDirection: 'row',
    justifyContent: isWrapped ? 'left' : '',
    alignItems: 'center',
    marginLeft: '20px',
  },
  container: {
    width: '100%',
    height: '80px',
    zIndex: 999,
  },
  fixedContainer: {
    width: isSM ? '100%' : 'calc(100% - 283px)',
    height: '80px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowY: 'hidden',
    position: 'fixed',
    paddingLeft: isSM ? '15px' : '',
    paddingRight: isSM ? '15px' : '',
    paddingTop: isSM ? '0px' : '10px',
    paddingBottom: '0px',
  },
  mobileContainer: {
    position: 'fixed',
    bottom: '0px',
    zIndex: 1,
  },
  dialogTitle: {
    paddingBottom: '10px',
    paddingTop: '40px',
    paddingLeft: isSM ? '25px' : '50px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: !isSM ? '24px' : '18px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  dialogContent: {
    paddingTop: '0px',
    padding: isSM ? '0' : 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: !isSM ? '16px' : '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: '#232B35',
    '&:hover': { backgroundColor: 'transparent' },
    display: 'flex',
    flexDirection: 'column',
  },
  selectedButton: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: !isSM ? '16px' : '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: '#232B35',
    '&:hover': { backgroundColor: 'transparent' },
    display: 'flex',
    flexDirection: 'column',
  },
  clearIconContainer: {
    position: !isXS ? 'absolute' : 'inherit',
    height: isXS ? '30px' : 'auto',
    cursor: 'pointer',
    right: '40px',
    top: '20px',
    fontSize: '13px',
    color: '#97A3B7',
  },
  dialogContainer: {
    borderRadius: isSM ? '0px' : '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    width: isSM ? '100%' : '770px',
    maxWidth: isSM ? '100%' : '770px',
    height: isSM ? '100%' : '490px',
    maxHeight: isSM ? '100%' : '490px',
    overflow: 'hidden',
  },
  actionsButton: {
    textTransform: 'none',
    height: '40px',
    borderRadius: '50px',
    width: '142px',
    margin: '5px',
    flexGrow: 1,
    border: '2px solid #0F78FD',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#3171F6',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '2px solid #0F78FD',
    },
  },
  clearIcon: {
    margin: '-75px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
    alignSelf: 'end',
  },
});

function CompanyUpperBar() {
  const { t } = useTranslation();
  const history = useHistory();
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [newPost, setNewPost] = useState();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([500]));
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1304]));

  const {
    accountDetails,
    currentUserInfo,
    conversations,
    isImpersonated,
    isFreeTrial,
    isFreemium,
    currentPlan,
  } = useAccount();
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    !isFreeTrial &&
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const classes = useStyles({
    isSM,
    isWrapped,
    isXS,
    topvalue: getTopValue(),
  });
  const [inputText, setInputText] = useState('');
  const [
    isNewMemberConfirmationModalVisible,
    setIsNewMemberConfirmationModalVisible,
  ] = useState(false);
  const [errorSnackbarText, setErrorSnackbarText] = useState(
    t('COMPANY-MEMBERS-GENERIC-ERROR')
  );
  const [invitedEmail, setInvitedEmail] = useState('');
  const [isNewCompanyMemberModalVisible, setIsNewCompanyMemberModalVisible] =
    useState(false);
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const [openQuestionPopUp, setOpenQuestionPopUp] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [postHasContent, setPostHasContent] = useState(false);
  const [postType, setPostType] = useState('');
  const companyExtraFeed = useCompanyExtraFeed();
  const addPost = companyExtraFeed ? companyExtraFeed.addPost : undefined;
  const title =
    accountDetails.userSegment === 'managed_advisory_board'
      ? 'Post in your advisory board'
      : 'Post in your AdvisoryCloud';
  const [exploreItemOptions, setExploreItemOptions] = useState(
    getInitialItems()
  );

  function convertToAbsoluteUrl(url) {
    const baseUrl = url.startsWith('http')
      ? currentPlan.upgradeUrl
      : `https://${currentPlan.upgradeUrl}`;
    return baseUrl;
  }

  function getUnreadMessages() {
    let totalUnread = 0;
    conversations.forEach((conv) => {
      totalUnread += conv.unreadCount;
    });
    return totalUnread;
  }
  const [localUnreadMessages, setLocalUnreadMessages] = useState([
    getUnreadMessages(),
  ]);

  function CustomMenuItem({
    label,
    route,
    messagesTag,
    id,
    marginRight,
    newOption,
    enableUnderline,
    onClick,
  }) {
    function handleMenuItem() {
      if (location.pathname === route) {
        return;
      }
      history.push(route);
    }

    const selectedItem =
      location.pathname.startsWith(route) ||
      (location.pathname.includes('advisors') && label === 'Advisors');

    return (
      <>
        <div
          id={id}
          style={{
            height: '82px',
            display: 'flex',
            alignItems: 'center',
            ...(route === '/dashboard'
              ? {
                  marginRight: !isSM ? '10px' : '20px',
                }
              : {
                  marginLeft: '20px',
                }),
            ...(marginRight ? { marginRight } : {}),
          }}
        >
          <Button
            type="button"
            disableRipple
            sx={selectedItem ? classes.selectedButton : classes.button}
            onClick={route ? handleMenuItem : onClick}
          >
            {label}
            {newOption && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -8,
                  right: -24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  height: 18,
                  color: '#3171F6',
                  fontSize: '10px',
                  fontWeight: '600',
                  backgroundColor: '#D7F1FD',
                  borderRadius: '10px',
                  padding: '2px 5px',
                }}
              >
                New
              </Box>
            )}
            {selectedItem && enableUnderline ? (
              <div
                style={{
                  height: '2px',
                  width: '100%',
                  backgroundColor: 'black',
                  borderRadius: '2px',
                  position: 'inherit',
                  bottom: '-20px',
                  zIndex: -1,
                }}
              />
            ) : null}
          </Button>

          {messagesTag && (
            <ConversationNotification
              value={localUnreadMessages}
              styleProps={{
                fontSize: '9px',
                backgroundColor: '#FF6666',
              }}
            />
          )}
        </div>
      </>
    );
  }

  function handleNewCompanyMemberSubmit(data) {
    setIsNewCompanyMemberModalVisible(false);
    setInvitedEmail(data.email);
    setIsNewMemberConfirmationModalVisible(true);
  }

  function handleNewCompanyMemberSubmitError(data) {
    if (data.errors && data.errors.length) {
      if (data.errors[0].message) {
        const error = JSON.parse(data.errors[0].message);
        switch (error.type) {
          case 'Active':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-ACTIVED'));
            break;
          case 'Invited':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-INVITED'));
            break;
          case 'Deleted':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-DELETED'));
            break;
          default:
            setErrorSnackbarText(t('COMPANY-MEMBERS-GENERIC-ERROR'));
            break;
        }
      }
    }
    setIsErrorSnackbarVisible(true);
  }
  function handleAddCompanyContact() {
    if (isFreeTrial && !isFreemium) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else if (accountDetails.boardSeatsAvailable === 0) {
      setUpgradePlanModal(true);
    } else {
      setIsNewCompanyMemberModalVisible(true);
    }
  }

  function getTopValue() {
    let top = 0;
    if (isImpersonated) {
      top += 56;
    }
    if (isFreeTrial && !isFreemium) {
      top += 50;
    }
    if (showExFremiumBanner || showOnboardingBanner) {
      top += 50;
    }
    return `${top}px`;
  }

  function onAvatarMenuIconCLick() {
    eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
  }

  function handleClickQuestion(postType) {
    setPostType(postType || 'question');
    setOpenQuestionPopUp(!openQuestionPopUp);
  }

  function localAddPost(post) {
    setInputText('');
    if (addPost && location.pathname === '/dashboard') {
      addPost(post);
    }
    setNewPost(post);
    setSuccessSnackbar(true);
    setSuccessMessage(`Your ${postType} has been posted to your boardroom.`);
  }

  function localAddPostAfter() {
    setSuccessSnackbar(false);

    if (location.pathname !== '/dashboard') {
      history.push('/dashboard', { newPost });
    }
  }

  useEffect(() => {
    if (isFreemium) {
      const queryParams = new URLSearchParams(window.location.search);
      const viewUpgrade = queryParams.get('viewUpgrade');
      if (viewUpgrade === 'true') {
        eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
          action: () => {},
        });
      }
    }

    eventBus.on(eventBusValues.onNewMessage, (event) => {
      if (
        currentUserInfo.username !== event.senderId &&
        !window.location.search.includes(event.conversationId)
      ) {
        setLocalUnreadMessages((prevValue) => prevValue + 1);
      }
    });
    eventBus.on(eventBusValues.onReadConversation, (subtract) => {
      setLocalUnreadMessages((prevValue) => prevValue - subtract);
    });
    eventBus.on(eventBusValues.openCreatePost, handleClickQuestion);

    return () => {
      eventBus.remove(eventBusValues.onNewMessage);
      eventBus.remove(eventBusValues.onReadConversation);
      eventBus.remove(eventBusValues.openCreatePost);
    };
  }, []);

  function getInitialItems() {
    const options = [
      {
        label: 'Personalized Advisor Sourcing',
        id: 'company-submenu-advisor-sourcing',
        onClick: () =>
          redirectWithParams(
            'https://register.advisorycloud.com/bab/personal-assist/'
          ),
      },
      {
        label: 'Take a Tour',
        id: 'company-submenu-platform-tour',
        closeOnClick: true,
        onClick: () => {
          redirectWithParams(
            'https://app.advisorycloud.com/dashboard?tour=true'
          );
        },
      },
      {
        label: 'FAQs',
        id: 'company-submenu-faq',
        onClick: () =>
          redirectWithParams(
            'https://register.advisorycloud.com/faqs-babs-platform'
          ),
      },
      {
        label: 'Success Stories',
        id: 'company-submenu-success-stories',
        onClick: () =>
          redirectWithParams(
            'https://register.advisorycloud.com/success-stories'
          ),
      },
      {
        label: 'Upgrade Options',
        id: 'company-submenu-advisor-sourcing',
        onClick: () => {
          if (isFreemium) {
            eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
          } else {
            window.location.assign(
              `${convertToAbsoluteUrl(currentPlan.upgradeUrl)}?iwm_advisor_id=${
                accountDetails.iwmId
              }&sosfcontactid=${accountDetails.salesforceContactId}&email=${
                accountDetails.email
              }&plancode=${accountDetails.recurlyPlanCode}`
            );
          }
        },
      },
    ];
    return options;
  }

  useEffect(() => {
    const hasUpgradeOption = exploreItemOptions.some(
      (item) => item.label === 'Upgrade Options'
    );

    if (
      hasUpgradeOption &&
      !isFreeTrial &&
      !isFreemium &&
      !currentPlan.upgradeUrl
    ) {
      setExploreItemOptions((prevOptions) =>
        prevOptions.filter((item) => item.label !== 'Upgrade Options')
      );
    }
  }, [exploreItemOptions, isFreeTrial, isFreemium, currentPlan.upgradeUrl]);

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getCompanyQueryParams({ accountDetails })}`
    );
  };

  function newNavOptions() {
    return (
      <>
        <CustomMenuItem
          label={'Boardroom'}
          route="/dashboard"
          id="b2bNavBarBoardroom"
          enableUnderline
        />
        <CustomMenuItem
          label="Advisors"
          route="/advisors"
          id="b2bNavBarAdvisors"
          enableUnderline
        />
        <CustomMenuItem
          label="Board Meetings"
          onClick={() => {
            redirectWithParams(
              'https://register.advisorycloud.com/board-meetings'
            );
          }}
          id="b2bNavBarBoardMeetings"
          marginRight="0px"
          enableUnderline
        />
        <CustomMenuItem
          label="FAQs"
          onClick={() => {
            redirectWithParams(
              'https://register.advisorycloud.com/faqs-babs-platform'
            );
          }}
          id="b2bNavBarFAQs"
          marginRight="0px"
          enableUnderline
        />
        <HoverMenuItem
          id={'b2bNavBarExplore'}
          menuItemStyle={{
            padding: '6px 8px',
            color: '#232B35',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '32px',
            letterSpacing: '0.75px',
            fontFamily: 'Poppins',
            display: 'flex',
            marginLeft: '20px',
          }}
          optionsMenuStyle={{ marginTop: '5%', marginRight: '10px' }}
          label={'Explore'}
          options={exploreItemOptions}
        />
      </>
    );
  }
  function navOptions() {
    return <> {newNavOptions()}</>;
  }

  return (
    <>
      {!isSM ? (
        <div
          style={{
            ...classes.container,
          }}
        >
          <NewCompanyMemberModal
            visible={isNewCompanyMemberModalVisible}
            onDismiss={() => setIsNewCompanyMemberModalVisible(false)}
            onInvitationSend={handleNewCompanyMemberSubmit}
            onInvitationSendError={handleNewCompanyMemberSubmitError}
            companyId={accountDetails.id}
          />
          <NewCompanyMemberConfirmationModal
            email={invitedEmail}
            onDismiss={() => {
              setIsNewMemberConfirmationModalVisible(false);
              setInvitedEmail(null);
            }}
            visible={isNewMemberConfirmationModalVisible}
          />
          {accountDetails.boardSeatsAvailable === 0 ? (
            <>
              <UpgradePlanModal
                upgradePlanModal={upgradePlanModal}
                setUpgradePlanModal={setUpgradePlanModal}
              />
            </>
          ) : null}
          <div style={classes.fixedContainer}>
            <Treatment
              userId={
                currentUserInfo && currentUserInfo.username
                  ? currentUserInfo.username
                  : 'not_id'
              }
              splitNames={featureFlags.b2b_redesign}
              updateOnSdkTimedout
              evaluatedComponent={
                <Paper sx={classes.bContainerRedesign} elevation={0}>
                  <div style={classes.navContainer}>
                    <div
                      style={{
                        marginLeft: '30px',
                      }}
                    >
                      <AdvisoryCloudLogo
                        removeHyperlink
                        onClick={() => {
                          if (location.pathname !== '/dashboard') {
                            history.push('/dashboard');
                          }
                        }}
                        width={153}
                      />
                    </div>
                    <div style={classes.buttonsContainer}>{navOptions()}</div>
                  </div>
                  <div style={classes.navContainer}>
                    <div style={classes.buttonsContainer}>
                      <Button
                        id="company-upper-bar-invite-button"
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => {
                          eventBus.dispatch(
                            eventBusValues.triggerCompanyDrawerAction,
                            {
                              company: accountDetails,
                            }
                          );
                        }}
                        sx={{
                          padding: '10px 30px',
                          borderRadius: '25px',
                          textTransform: 'none',
                          fontSize: '16px',
                          lineHeight: '24px',
                          letterSpacing: '-0.43px',
                          fontWeight: 600,
                          boxShadow: 'none',
                          fontFamily: 'Poppins',
                        }}
                      >
                        + Invite
                      </Button>

                      <CompanyMenu
                        isSM={isSM}
                        handleAddCompanyContact={handleAddCompanyContact}
                        onAvatarMenuIconCLick={onAvatarMenuIconCLick}
                        image={
                          accountDetails.image ? accountDetails.image.id : ''
                        }
                      />
                    </div>
                  </div>
                </Paper>
              }
              conditionFlag
              conditionFailedComponent={
                <Paper sx={classes.bContainer} elevation={0}>
                  <div style={classes.navContainer}>
                    <div style={classes.buttonsContainer}>{navOptions()}</div>
                  </div>
                  <div style={classes.navContainer}>
                    <div style={classes.buttonsContainer}>
                      <Button
                        id="company-upper-bar-invite-button"
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => {
                          eventBus.dispatch(
                            eventBusValues.triggerCompanyDrawerAction,
                            {
                              company: accountDetails,
                            }
                          );
                        }}
                        sx={{
                          padding: '10px 30px',
                          borderRadius: '25px',
                          textTransform: 'none',
                          fontSize: '16px',
                          lineHeight: '24px',
                          letterSpacing: '-0.43px',
                          fontWeight: 600,
                          boxShadow: 'none',
                          fontFamily: 'Poppins',
                        }}
                      >
                        + Invite
                      </Button>

                      <CompanyMenu
                        isSM={isSM}
                        handleAddCompanyContact={handleAddCompanyContact}
                        onAvatarMenuIconCLick={onAvatarMenuIconCLick}
                        image={
                          accountDetails.image ? accountDetails.image.id : ''
                        }
                      />
                    </div>
                  </div>
                </Paper>
              }
            />
          </div>
        </div>
      ) : (
        <CompanyPostButtonMobile
          buttonColor="primary"
          buttonSize={isSM ? 'small' : 'medium'}
          onClickPost={() => handleClickQuestion()}
        />
      )}
      <Dialog
        open={openQuestionPopUp}
        onClose={() => {
          if (postHasContent) {
            setOpenConfirmationDialog(true);
          } else {
            setOpenQuestionPopUp(false);
          }
        }}
        aria-labelledby="post-popUp-dialog"
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        fullScreen={isSM}
      >
        <DialogTitle id="post-popUp-dialog-title" sx={classes.dialogTitle}>
          {title}
          <div style={classes.clearIconContainer}>
            <PostTypeSelector
              setPostType={setPostType}
              currentPostType={postType}
            />
          </div>
          {isSM ? (
            <div style={classes.clearIcon}>
              <Clear
                onClick={() => {
                  if (postHasContent) {
                    setOpenConfirmationDialog(true);
                  } else {
                    setOpenQuestionPopUp(false);
                  }
                }}
              />
            </div>
          ) : null}
        </DialogTitle>
        <DialogContent sx={classes.dialogContent}>
          <PostPopUp
            onChange={() => setOpenQuestionPopUp(false)}
            type={postType}
            callbackAfterPost={(post) => localAddPost(post)}
            editingPost={{ body: inputText }}
            setPostHasContent={setPostHasContent}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmationDialog}
        maxWidth="sm"
        onClose={() => {
          setOpenConfirmationDialog(false);
        }}
      >
        <DialogContent sx={{ padding: '25px 30px 15px' }}>
          <DialogContentText sx={classes.dialogText}>
            Are you sure you want to close without posting your {postType}?
          </DialogContentText>
          <DialogActions sx={{ marginTop: '15px' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                eventBus.dispatch(eventBusValues.closeCreatePost, {
                  callback: () => setOpenQuestionPopUp(false),
                });
                setOpenConfirmationDialog(false);
                setOpenQuestionPopUp(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              Discard Draft
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={classes.actionsButton}
              onClick={() => {
                setOpenConfirmationDialog(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              Keep Editing
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ACSnackbar
        id="company-member-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={errorSnackbarText}
      />
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => localAddPostAfter()}
        autoHideDuration={2000}
      />
    </>
  );
}

export default CompanyUpperBar;
