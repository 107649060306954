import React, { useState } from 'react';
import { Card, Grid, Avatar, Typography, Box, Button } from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { ReactComponent as PersonAdd } from './../../../icons/person_add.svg';
import { ReactComponent as CalendarToday } from './../../../icons/calendar_today.svg';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DefaultCompany from './../../../icons/building_round.svg';
import { requestToSpeak } from '../../../services/advisor_services';
import { useAccount } from '../../../contexts/Account';
import ConfirmRequestToSpeakDialog from './ConfirmRequestToSpeakDialog';
import ShareYourIdeasDialog from './ShareYourIdeasDialog';
import eventBus, { eventBusValues } from '../../../eventBus';
import Treatment from '../../../components/Treatment';
import { featureFlags } from '../../../splitSetup';
import UpdateGoalModal from './UpdateGoalModal';

export default function BoardroomHeader({
  company,
  setIsLoading,
  seeOpportunity,
}) {
  const { accountDetails, isFreeTrial, currentUserInfo } = useAccount();
  const isOwnProfile = company.id === accountDetails.id;

  const [openRequestToSpeakDialog, setOpenRequestToSpeakDialog] =
    useState(false);
  const [shareYourIdeasDialogOpen, setShareYourIdeasDialogOpen] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRequestToSpeak = async () => {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
      return;
    }
    try {
      setIsLoading(true);
      const boardOwner = company.contacts.find(
        (contact) => contact.isBoardOwner
      );
      const response = await requestToSpeak({
        advisorName: accountDetails.displayName,
        advisorEmail: accountDetails.email,
        salesforceAdvisorContactId: accountDetails.salesforceContactId || '',
        companyName: company.companyName,
        boardOwnerEmail: boardOwner ? boardOwner.email : '',
        salesforceCompanyContactId: company.salesforceContactId,
      });
      console.log(response);
      setIsLoading(false);
      setOpenRequestToSpeakDialog(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  function openProfile(company) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company,
    });
  }
  function openProfileEdit(companyId) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId,
      isEditing: true,
    });
  }

  return (
    <Card
      sx={{
        mb: 3,
        p: 3,
        borderRadius: '8px',
        boxShadow: 'none',
      }}
    >
      <Grid container spacing={3} alignItems="center" justifyContent={'center'}>
        <Grid item>
          <Box
            id="boardroom-avatar"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xxs: 128, xs: 207 },
              height: { xxs: 128, xs: 207 },
              border: '1px solid #DEE9FE',
              borderRadius: '8px',
            }}
          >
            <Avatar
              alt={`${company.companyName} Logo`}
              src={
                company &&
                company.image &&
                company.image.id &&
                !company.image.id.includes('undefined')
                  ? `${process.env.REACT_APP_IMAGES_URL}${company.image.id}`
                  : DefaultCompany
              }
              sx={{
                width: 150,
                height: 150,
                borderRadius: '8px',
              }}
              onClick={() =>
                isOwnProfile
                  ? openProfile(accountDetails)
                  : seeOpportunity(company.id)
              }
            ></Avatar>
          </Box>
        </Grid>
        <Grid item xs>
          <Typography
            id="boardroom-company-name"
            sx={{
              fontWeight: '600',
              fontSize: { xxs: 24, xs: 32 },
              textAlign: { xxs: 'center', xs: 'left' },
              lineHeight: '41px',
              color: '#001030',
            }}
          >
            {company.companyName}
          </Typography>
          <Typography
            id="boardroom-headline"
            sx={{
              fontWeight: '400',
              fontSize: 14,
              textAlign: { xxs: 'center', xs: 'left' },
              color: '#4D586F',
              py: '10px',
            }}
          >
            {company.headline}
          </Typography>
          <Box
            id="boardroom-current-goal"
            sx={{
              bgcolor: '#F1F9FF',
              borderRadius: '8px',
              py: '5px',
              px: '10px',
              width: '100%',
              borderTop: '1px solid #4FB5F9',
              borderRight: '1px solid #4FB5F9',
              borderBottom: '1px solid #4FB5F9',
              borderLeft: '5px solid #4FB5F9',
              display: company.currentGoal ? 'block' : 'none',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: { xxs: 14, xs: 16 },
                color: '#00548B',
                lineHeight: '21px',
              }}
            >
              Current Challenge or Goal:
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: { xxs: 12, xs: 14 },
                color: '#00548B',
                lineHeight: { xxs: '18px', xs: '21px' },
              }}
            >
              {company.currentGoal || 'No current goal'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xxs: 'block', xs: 'flex' },
              justifyContent: 'flex-start',
              mt: 2,
              gap: 2,
            }}
          >
            <Treatment
              userId={
                currentUserInfo && currentUserInfo.username
                  ? currentUserInfo.username
                  : 'not_id'
              }
              splitNames={featureFlags.b2b_redesign}
              updateOnSdkTimedout
              evaluatedComponent={
                <>
                  {isOwnProfile ? (
                    <Button
                      id="boardroom-request-to-speak"
                      variant="contained"
                      color="primary"
                      startIcon={<PersonAdd />}
                      onClick={() => openProfile(accountDetails)}
                      sx={{
                        textTransform: 'none',
                        bgcolor: '#2B71F6',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        width: { xxs: '100%', xs: 223 },
                        height: 42,
                        fontSize: 14,
                        fontWeight: '600',
                        '&:hover': {
                          bgcolor: '#2B71F6',
                        },
                      }}
                    >
                      Invite Board Members
                    </Button>
                  ) : (
                    <Button
                      id="boardroom-request-to-speak"
                      variant="contained"
                      color="primary"
                      startIcon={<RecordVoiceOverIcon />}
                      onClick={handleRequestToSpeak}
                      sx={{
                        textTransform: 'none',
                        bgcolor: '#2B71F6',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        width: { xxs: '100%', xs: 298 },
                        height: 42,
                        fontSize: 14,
                        fontWeight: '600',
                        '&:hover': {
                          bgcolor: '#2B71F6',
                        },
                      }}
                    >
                      Request to Speak at Next Meeting
                    </Button>
                  )}
                </>
              }
              conditionFlag
              conditionFailedComponent={
                <Button
                  id="boardroom-request-to-speak"
                  variant="contained"
                  color="primary"
                  startIcon={<RecordVoiceOverIcon />}
                  onClick={handleRequestToSpeak}
                  sx={{
                    textTransform: 'none',
                    bgcolor: '#2B71F6',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    width: { xxs: '100%', xs: 298 },
                    height: 42,
                    fontSize: 14,
                    fontWeight: '600',
                    '&:hover': {
                      bgcolor: '#2B71F6',
                    },
                  }}
                >
                  Request to Speak at Next Meeting
                </Button>
              }
            />

            <ConfirmRequestToSpeakDialog
              open={openRequestToSpeakDialog}
              onClose={() => setOpenRequestToSpeakDialog(false)}
            />

            <Treatment
              userId={
                currentUserInfo && currentUserInfo.username
                  ? currentUserInfo.username
                  : 'not_id'
              }
              splitNames={featureFlags.b2b_redesign}
              updateOnSdkTimedout
              evaluatedComponent={
                <>
                  {isOwnProfile ? (
                    <>
                      <Button
                        id="boardroom-share-your-ideas"
                        variant="contained"
                        color="primary"
                        startIcon={<CalendarToday />}
                        onClick={() => setModalOpen(true)}
                        sx={{
                          textTransform: 'none',
                          bgcolor: '#4FB5F9',
                          borderRadius: '8px',
                          boxShadow: 'none',
                          width: { xxs: '100%', xs: 310 },
                          mt: { xxs: 1, xs: 0 },
                          height: 42,
                          fontSize: 14,
                          fontWeight: '600',
                          '&:hover': { bgcolor: '#4FB5F9' },
                        }}
                      >
                        Update Current Challenge or Goal
                      </Button>
                      <UpdateGoalModal
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                      />
                    </>
                  ) : (
                    <Button
                      id="boardroom-share-your-ideas"
                      variant="contained"
                      color="primary"
                      startIcon={<LightbulbIcon />}
                      onClick={() => {
                        if (isFreeTrial) {
                          eventBus.dispatch(
                            eventBusValues.triggerAdvisorUpgradeAction
                          );
                        } else {
                          setShareYourIdeasDialogOpen(true);
                        }
                      }}
                      sx={{
                        textTransform: 'none',
                        bgcolor: '#4FB5F9',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        width: { xxs: '100%', xs: 181 },
                        mt: { xxs: 1, xs: 0 },
                        height: 42,
                        fontSize: 14,
                        fontWeight: '600',
                        display: company.currentGoal ? 'flex' : 'none',
                        '&:hover': { bgcolor: '#4FB5F9' },
                      }}
                    >
                      Share Your Ideas
                    </Button>
                  )}
                </>
              }
              conditionFlag
              conditionFailedComponent={
                <Button
                  id="boardroom-share-your-ideas"
                  variant="contained"
                  color="primary"
                  startIcon={<LightbulbIcon />}
                  onClick={() => {
                    if (isFreeTrial) {
                      eventBus.dispatch(
                        eventBusValues.triggerAdvisorUpgradeAction
                      );
                    } else {
                      setShareYourIdeasDialogOpen(true);
                    }
                  }}
                  sx={{
                    textTransform: 'none',
                    bgcolor: '#4FB5F9',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    width: { xxs: '100%', xs: 181 },
                    mt: { xxs: 1, xs: 0 },
                    height: 42,
                    fontSize: 14,
                    fontWeight: '600',
                    display: company.currentGoal ? 'flex' : 'none',
                    '&:hover': { bgcolor: '#4FB5F9' },
                  }}
                >
                  Share Your Ideas
                </Button>
              }
            />

            <ShareYourIdeasDialog
              open={shareYourIdeasDialogOpen}
              onClose={() => setShareYourIdeasDialogOpen(false)}
              company={company}
              setIsLoading={setIsLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
