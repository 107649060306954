import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import union_left from './assets/union_left.svg';
import union_right from './assets/union_right.svg';

function GeneralTile({
  title = "",
  description = "",
  onClick = () => { },
  image,
  ids = {
    tileID: "",
    buttonID: "",
  }
}) {
  return (
    <Paper
      id={ids.tileID}
      className={'view-boardrooms'}
      elevation={1}
      sx={{
        height: 'auto',
        minHeight: '20rem',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        justifyContent: 'space-between',
        padding: '16px',
        paddingTop: '0px',
        borderRadius: '12px',
        boxShadow: '0px 10px 12px 0px #0010300D',
        width: '100%',
      }}
    >
      <div
        style={{
          width: 'calc(100% + 32px)',
          left: '-16px',
          height: '56px',
          background: 'linear-gradient(#2C71F633, #25A4FF33)',
          borderRadius: '8px 8px 0px 0px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: '3rem',
        }}
      >
        <img
          src={union_left}
          alt="left"
          style={{ position: 'absolute', zIndex: 1, left: 60, marginTop: 25 }}
        />
        <img
          src={union_right}
          alt="right"
          style={{ position: 'absolute', zIndex: 1, right: 0 }}
        />
        <Box
          sx={{
            display: 'flex',
            marginRight: 'auto',
            marginLeft: '20px',
            marginTop: '25px',
            height: 80,
            width: 80,
            backgroundColor: 'white',
            borderRadius: '12px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={image}
            sx={{
              height: '70%',
              width: '70%',
              borderRadius: '8px',
            }}
          />
        </Box>
      </div>
      <Box sx={{ marginBottom: "auto", display: 'flex', flexDirection: 'column', zIndex: 1 }}>
        <Typography
          sx={{
            marginTop: "-15px",
            marginBottom: "15px",
            fontFamily: 'Poppins',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '26px',
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            marginTop: "12px",
            fontSize: '12px',
            color: '#4D586F',
            fontWeight: 400,
            lineHeight: '18px',
          }}
        >
          {description}
        </Typography>
      </Box>

      <Button
        id={ids.buttonID}
        variant="outlined"
        className={'view-company-button'}
        onClick={onClick}
        sx={{
          textTransform: 'none',
          fontSize: '14px',
          padding: '6px 12px',
          borderRadius: 2,
          borderColor: 'hsla(219, 92%, 57%, 1)',
          color: 'hsla(219, 92%, 57%, 1)',
          borderWidth: '1px',
          backgroundColor: 'white',
        }}
      >
        View
      </Button>
    </Paper>
  )
}

export default GeneralTile;