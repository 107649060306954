import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAccount } from '../../../contexts/Account';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';

function ProfileItemEmptyState(props) {
  const {
    title,
    subtitle,
    items = [],
    TitleComponent,
    mainBoxStyle = {},
  } = props;
  const { currentUserInfo } = useAccount();
  return (
    <Treatment
      userId={
        currentUserInfo && currentUserInfo.username
          ? currentUserInfo.username
          : 'not_id'
      }
      splitNames={featureFlags.advisor_empty_state}
      updateOnSdkTimedout
      evaluatedComponent={
        <Box sx={{ width: '100%', ...mainBoxStyle }}>
          {TitleComponent && <TitleComponent />}
          {title && (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '20px',
                color: '#232B35',
                lineHeight: '24px',
                marginBottom: '24px',
              }}
            >
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: '#232B35',
                lineHeight: '24px',
                marginBottom: '12px',
              }}
            >
              {subtitle}
            </Typography>
          )}

          {items.map((item, index) => {
            if (item.tagItems) {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: item.width || '100%',
                    height: '26px',
                    marginBottom: index === items.length - 1 ? 0 : '16px',
                  }}
                >
                  {item.tagItems.map((subItem, subIndex) => {
                    return (
                      <Box
                        key={subIndex}
                        sx={{
                          width: subItem,
                          height: '26px',
                          backgroundColor: '#F5F7FA',
                          borderRadius: '100px',
                          marginRight:
                            subIndex === item.tagItems.length - 1 ? 0 : '12px',
                        }}
                      />
                    );
                  })}
                </Box>
              );
            }

            return (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                key={index}
              >
                {item.imageItem && (
                  <Box
                    key={index}
                    sx={{
                      width: '32px',
                      height: '32px',
                      backgroundColor: '#F5F7FA',
                      borderRadius: '100px',
                      marginBottom: index === items.length - 1 ? 0 : '8px',
                      marginRight: '12px',
                    }}
                  />
                )}
                <Box
                  sx={{
                    width: item.width || '100%',
                    height: '18px',
                    backgroundColor: '#F5F7FA',
                    borderRadius: '5px',
                    marginBottom: index === items.length - 1 ? 0 : '12px',
                  }}
                />
              </Box>
            );
          })}
        </Box>
      }
    />
  );
}

export default ProfileItemEmptyState;
