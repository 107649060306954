import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import emptyStateImg from './emptyState.png';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';

function Card({ title, text }) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xxs: '100%', xs: '245px' },
        height: '238px',
        borderRadius: '16px',
        padding: '30px',
        backgroundColor: '#D7F1FD',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          color: '#232B35',
          marginBottom: '20px',
          marginTop: '5px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: { xxs: '16px', xs: '12px' },
          color: '#232B35',
        }}
      >
        {text}
      </Typography>
    </Paper>
  );
}

function CompanyEmptyStateFreemium(props) {
  const { isFreemium } = useAccount();
  const matches = useMediaQuery('(min-width: 787px) and (max-width: 1390px)');
  const history = useHistory();
  const {
    content = 'Start browsing advisors to invite to your boardroom',
    buttonText = 'Schedule meeting',
    buttonAction = () => {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    },
    buttonId,
  } = props;

  return (
    <Box sx={{ maxWidth: '1200px', alignSelf: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          alignItems: 'center',
          justifyContent: { xxs: 'center', xs: 'space-between' },
          marginTop: { xxs: '25px', xs: '50px' },
          marginBottom: { xxs: '25px', xs: '50px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xxs: '100%', xs: '600px' },
            padding: { xxs: '20px', xs: '0px' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '28px',
              color: '#232B35',
              marginBottom: '30px',
            }}
          >
            {content}
          </Typography>
          <Button
            id={buttonId || null}
            sx={{
              alignSelf: 'flex-start',
              backgroundColor: '#DBF7EB',
              fontWeight: 600,
              fontSize: '20px',
              color: '#232B35',
              width: '100%',
              height: '100px',
              borderRadius: '50px',
              textTransform: isFreemium ? 'none' : 'uppercase',
              '&:hover': {
                backgroundColor: '#DBF7EB',
              },
              marginBottom: { xxs: '10px', xs: '0px' },
              width: { xxs: '100%', xs: '333px' },
            }}
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        </Box>
        <Box>
          <img src={emptyStateImg} style={{ width: '100%' }} />
        </Box>
      </Box>{' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          maxWidth: { xxs: '100%', xs: '80%' },
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '28px',
            color: '#232B35',
            width: '100%',
            lineHeight: '40px',
            marginBottom: '10px',
          }}
        >
          See how other companies use moderated meetings to help them accomplish
          goals
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '18px',
            color: '#232B35',
            height: '27px',
            lineHeight: '27px',
            letterSpacing: '.5px',
            marginBottom: { xxs: '40px', xs: '10px' },
          }}
        >
          Meet with your entire board or a select group
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',

          gridTemplateColumns: {
            xxs: '1fr',
            xs: 'repeat(2, max-content)',
            sm: 'repeat(3, max-content)',
          }, // Mantiene tamaño de las tarjetas sin expandirse
          gap: 3,
          placeContent: 'initial', // Centra el contenido del grid
          marginBottom: '50px',
          gridAutoFlow: 'row', // Mantiene el orden natural
        }}
      >
        <Card
          title="Strategic Guidance"
          text="Talk through your business - Advisors can give you an outside
    perspective and help with setting clear objectives, target markets,
    and a strategy that aligns with your vision and current resources."
        />
        <Card
          title="Pitch Deck Review"
          text="Use advisors to review your pitch deck and provide feedback to ensure you go into your investor meetings fully prepared and with a group of advisors that add credibility to your venture."
        />

        <Card
          title="Sales & Marketing"
          text="Share your Sales & Marketing strategy and materials with your advisors to get live guidance and feedback on reaching and converting your ideal customers."
        />
        <Card
          title="Investor Demos"
          text="Invite potential investors to your meeting and expand your network of people who can make introductions to potential angel investors, VCs, and PE firms."
        />
        <Card
          title="Product Feedback"
          text="Conduct a demo of your MVP, beta, or product live for a group of advisors that make up your target demographic. Get invaluable feedback to help build and grow your product."
        />
        <Card
          title="Launch Traction"
          text="Hold a meeting with your advisory board about a new product, promotion, or initiative and ask them to help spread the word. Get early traction on your initiative through their audience and reach."
        />
      </Box>
    </Box>
  );
}

export default CompanyEmptyStateFreemium;
