import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../../contexts/Account';
import ACSnackbar from '../../../components/ACSnackbar';
import { setCurrentGoalIdea } from '../../../services/advisor_services';

const ShareYourIdeasDialog = ({ open, onClose, company, setIsLoading }) => {
  const { accountDetails } = useAccount();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [idea, setIdea] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    const resp = await setCurrentGoalIdea({
      advisorId: accountDetails.id,
      companyId: company.id,
      idea,
      currentGoal: company.currentGoal,
    });
    console.log(resp);
    setIsSuccess(true);
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: 544,
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            color: '#000',
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!isSuccess ? (
        <DialogContent sx={{ padding: '16px 30px' }}>
          <Typography
            id="modal-current-goal"
            sx={{
              fontSize: { xxs: 16, xs: 20 },
              fontWeight: 600,
              color: '#001030',
              lineHeight: { xxs: '21px', xs: '26px' },
              marginBottom: '16px',
            }}
          >
            {company.currentGoal}
          </Typography>
          <Typography
            sx={{
              fontSize: { xxs: 12, xs: 14 },
              color: '#4D586F',
              fontWeight: 400,
              marginBottom: '16px',
            }}
          >
            Please share your ideas here and make sure they are focused only on
            the topic above
          </Typography>
          <TextField
            id="modal-idea-input"
            value={idea}
            onChange={(e) => setIdea(e.target.value)}
            multiline
            rows={4}
            placeholder="What's your idea? What's your experience with this? Why is it unique?"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: '#C9CDD3',
                },
                // "&:hover fieldset": {
                //   borderColor: "#C9CDD3",
                // },
                // "&.Mui-focused fieldset": {
                //   borderColor: "#1976d2",
                // },
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 2,
              py: '15px',
            }}
          >
            <Button
              id="modal-idea-submit"
              onClick={submit}
              fullWidth
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                padding: '6px 12px',
                borderRadius: 2,
                borderColor: '#2B71F6',
                color: 'white',
                borderWidth: '1px',
                backgroundColor: '#2B71F6',
                '&:hover': {
                  backgroundColor: '#2B71F6',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: '16px 30px' }}>
          <Typography
            sx={{
              fontSize: { xxs: 24, xs: 32 },
              fontWeight: 600,
              color: '#001030',
              lineHeight: { xxs: '32px', xs: '42px' },
              marginBottom: '16px',
            }}
          >
            Success, your idea has been sent.
          </Typography>
          <Typography
            sx={{
              fontSize: { xxs: 12, xs: 14 },
              color: '#4D586F',
              fontWeight: 400,
              marginBottom: '16px',
            }}
          >
            Please do not expect a response, however we guarantee your idea will
            be shared and you may receive a request to speak at an upcoming
            board meeting to discuss further.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 2,
              py: '15px',
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              fullWidth
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                padding: '6px 12px',
                borderRadius: 2,
                color: '#2B71F6',
                borderColor: '#2B71F6',
                borderWidth: '1px',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#white',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      )}

      <ACSnackbar
        open={snackbarOpen}
        text={snackMessage}
        severity={snackSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={5000}
      />
    </Dialog>
  );
};

export default ShareYourIdeasDialog;
