import React from 'react';
import { useMediaQuery } from '@mui/material';
import LikeButton from './Buttons/likeButton';
import AnswersButton from './Buttons/answersButton';
import CommentsButton from './Buttons/commentsButton';
import AvatarsInvolved from './../../AvatarsInvolved';
import BreakthroughsButton from './breakthroughsComponent';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';
import { useAccount } from '../../../contexts/Account';

const useStyles = (props, isSM) => ({
  groupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    height: 'fit-content',
    paddingBottom: props.isPost ? '7px' : '0px',
  },

  likesContainer: {
    order: props.likesOrder,
  },
  answersContainer: {
    order: props.answersOrder,
    marginTop: props.type && isSM ? '2px' : '0px',
  },
  commentsContainer: {
    order: props.commentsOrder,
  },
  avatarsContainer: {
    display: 'flex',
    height: '100%',
  },
  iconSeparatorBreakthroughs: {
    marginTop: '0px',
    order: props.breakthoughsOrder,
  },
  iconSeparatorBreakthroughsXs: {
    marginTop: '-2px',
    order: props.breakthoughsOrder,
  },
  contributorsButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    backgroundColor: 'transparent',
  },
});

const PostCardBottom = (props) => {
  const {
    size,
    likes,
    answers,
    comments,
    breakthroughs,
    likesFilled,
    likesClick,
    likesDisabled,
    commentsFilled,
    commentsClick,
    commentsDisabled,
    answersClick,
    answersFilled,
    answersDisabled,
    breakthroughsClick,
    breakthroughsFilled,
    breakthroughsGold,
    breakthroughsBold,
    breakthroughsSize,
    breakthroughsDisabled,
    avatars,
    iconSeparation,
    contributorsDisabled,
    contributorsClick,
    isFirst,
    type,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(props, isSM);
  const { currentUserInfo } = useAccount();

  function renderBreakthroughsSize() {
    if (breakthroughsSize === 'md') {
      return 'md';
    }
    if (size === 'sm' || breakthroughsSize === 'sm') {
      return 'sm';
    }
    if (size === 'lg' || breakthroughsSize === 'lg') {
      return 'lg';
    }
    if (size === 'xs' || breakthroughsSize === 'xs') {
      return 'xs';
    }

    return null;
  }

  function renderContributorsAvatar() {
    if (avatars === null || avatars === undefined) {
      return null;
    }
    if (avatars.length === 0) {
      return null;
    }
    return (
      <button
        type="button"
        style={classes.contributorsButton}
        onClick={() => contributorsClick()}
        disabled={contributorsDisabled}
      >
        <AvatarsInvolved
          limit={5}
          avatars={avatars}
          avatarSize="md"
          textStyle={{
            fontFamily: 'Poppins',
            color: '#232b35',
            fontSize: '16px',
          }}
        />
      </button>
    );
  }

  function breakThroughsPositionSelector() {
    if (size === 'xs') {
      return classes.iconSeparatorBreakthroughsXs;
    }
    if (size === 'large') {
      return classes.iconSeparatorBreakthroughs;
    }
    return classes.iconSeparatorBreakthroughs;
  }

  function likesIconSeparation() {
    if (isSM) {
      return '8px';
    }
    if (iconSeparation) {
      return iconSeparation;
    }
    return '13px';
  }

  function iconSeparationSelection() {
    if (isSM) {
      return '8px';
    }
    if (iconSeparation) {
      return iconSeparation;
    }
    return '10px';
  }

  return (
    <div style={classes.groupContainer}>
      <div style={classes.iconContainer}>
        {likes || likes === 0 ? (
          <div
            style={{
              ...classes.likesContainer,
              marginRight: likesIconSeparation(),
            }}
          >
            <LikeButton
              iconSize={size}
              numLikes={likes}
              onClick={likesClick}
              filled={likesFilled}
              disabled={likesDisabled}
              isFirst={isFirst}
            />
          </div>
        ) : null}
        {answers || answers === 0 ? (
          <div
            style={{
              ...classes.answersContainer,
              marginRight: iconSeparationSelection(),
            }}
          >
            <AnswersButton
              iconSize={size}
              numAnswers={answers}
              filled={answersFilled}
              onClick={answersClick}
              disabled={answersDisabled}
            />
          </div>
        ) : null}
        {comments || comments === 0 ? (
          <div
            style={{
              ...classes.commentsContainer,
              marginRight: iconSeparationSelection(),
            }}
          >
            <CommentsButton
              iconSize={size}
              numComments={comments}
              filled={commentsFilled}
              onClick={commentsClick}
              disabled={commentsDisabled}
            />
          </div>
        ) : null}
        {breakthroughs || breakthroughs === 0 ? (
          <div
            style={{
              ...breakThroughsPositionSelector,
              marginRight: iconSeparationSelection(),
            }}
          >
            <BreakthroughsButton
              onClick={breakthroughsClick}
              filled={breakthroughsFilled}
              goldColor={breakthroughsGold}
              bold={breakthroughsBold}
              iconSize={renderBreakthroughsSize()}
              numBreakthroughs={breakthroughs}
              disabled={breakthroughsDisabled}
            />
          </div>
        ) : null}
      </div>
      {!type && renderContributorsAvatar()}
    </div>
  );
};

export default PostCardBottom;
